import { getAccessToken } from "@cp/auth";

import { MyAccountDocument, MyAccountQuery, MyAccountQueryVariables } from "src/generated/graphql";
import { apolloClient } from "src/graphql/client";

export async function getAuthedUser() {
  const accessToken = getAccessToken();

  if (accessToken) {
    // fetch account if we have an access token
    const response = await apolloClient
      .query<MyAccountQuery, MyAccountQueryVariables>({
        query: MyAccountDocument,
        fetchPolicy: "cache-first",
      })
      .catch(() => ({ data: undefined }));
    // set correct user and agency if we have a valid response
    if (response.data?.myAccount) {
      return {
        user: {
          id: response.data.myAccount.id,
          email: response.data.myAccount.email,
          firstName: response.data.myAccount.firstName,
          lastName: response.data.myAccount.lastName,
          roles: response.data.myAccount.roles,
        },
        agency: {
          id: response.data.myAccount.agency.id,
          name: response.data.myAccount.agency.name,
        },
      };
    }
  }

  return null;
}
