import * as React from "react";
import { useController } from "react-hook-form";

import { Checkbox as StyledCheckbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { cn } from "src/utils";
import { createControlledField } from "./create-controlled-field";
import { useFieldError } from "./use-field-error";

const ControlledCheckbox = createControlledField(StyledCheckbox);

export const Checkbox: React.FC<
  React.ComponentProps<typeof ControlledCheckbox> & { children: React.ReactNode; labelClassName?: string }
> = ({ children, name, className, labelClassName, ...props }) => {
  const error = useFieldError(name);
  const hasError = Boolean(error);

  const controller = useController({
    name,
    shouldUnregister: true,
  });

  const { value, onChange, ...fieldMinusValue } = controller.field;

  // Checkboxes can have multiple fields with the same name but different values.
  const id = ["checkbox", name].join("-");
  return (
    <div className="flex gap-2 items-center">
      <ControlledCheckbox
        id={props.id ?? id}
        {...props}
        className={cn(
          {
            "border-red-500": hasError,
          },
          className
        )}
        {...fieldMinusValue}
        checked={Boolean(value)}
        onCheckedChange={onChange}
      />
      <Label htmlFor={props.id ?? id} className={labelClassName}>
        {children}
      </Label>
    </div>
  );
};
