import { UUID } from "@cp/toolkit";
import * as Select from "@radix-ui/react-select";

import { HasInternalRole } from "@/components/has-role";
import { Avatar } from "@/components/ui/avatar";
import { SelectContent, SelectItem } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { useMyAccount } from "@/hooks/use-my-account";
import { useUpdateOpportunity } from "@/hooks/use-oopportunity";
import { useBrokerAccountsQuery, UserAccountFragment } from "src/generated/graphql";

export const SetBroker = ({ id, broker }: { id: string; broker: UserAccountFragment }) => {
  const { toast } = useToast();

  const { data: user } = useMyAccount();

  const { data: brokers } = useBrokerAccountsQuery({
    skip: !user?.internal,
    fetchPolicy: "cache-first",
  });

  const [updateOpportunity] = useUpdateOpportunity({
    onCompleted: () => {
      toast({ title: "Success", description: "Broker assignment updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  return (
    <HasInternalRole elseShow={<Avatar user={broker} />}>
      <Select.Root
        value={broker ? broker.id : "-"}
        onValueChange={(e) =>
          updateOpportunity({
            variables: {
              input: { id: id, brokerId: e === "-" ? null : (e as UUID) },
            },
          })
        }
      >
        <Select.Trigger>
          <Avatar user={broker} />
        </Select.Trigger>
        <SelectContent>
          <SelectItem value="-">Unassigned</SelectItem>
          {brokers?.userAccounts.map((u) => (
            <SelectItem key={u.id} value={u.id}>
              {u.firstName} {u.lastName}
            </SelectItem>
          ))}
        </SelectContent>
      </Select.Root>
    </HasInternalRole>
  );
};
