import { formatMoney } from "@cp/toolkit";
import React from "react";
import { useFormContext } from "react-hook-form";

import { createRegisteredField } from "./create-registered-field";
import { useFieldError } from "./use-field-error";

const Input = createRegisteredField<React.InputHTMLAttributes<HTMLInputElement>>("input");

const TextArea = createRegisteredField<React.TextareaHTMLAttributes<HTMLTextAreaElement>>("textarea");

const Date: React.FC<React.InputHTMLAttributes<HTMLInputElement> & { name: string }> = (props) => {
  return <Input {...props} placeholder="MM/DD/YYYY" pattern="^(0[1-9]|1[0-2])/(0[1-9]|[12]\d|3[01])/\d{4}$" />;
};

const NumberInput: React.FC<React.InputHTMLAttributes<HTMLInputElement> & { name: string }> = (props) => {
  return <Input {...props} pattern="^\d+$" />;
};

const Money: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
  }
> = (props) => {
  const { setValue } = useFormContext();

  const formatInputValue = (inputValue: string) => {
    const numericalValue = Number.parseFloat(inputValue.replaceAll(/[^\d.]+/g, ""));
    return Number.isNaN(numericalValue) ? "" : formatMoney(numericalValue);
  };

  return (
    <Input
      {...props}
      pattern="^\$?\d{1,3}(,\d{3})*(\.\d{0,2})?$"
      onChange={(event) => {
        setValue(event.target.name, formatInputValue(event.target.value), { shouldValidate: true });
        props.onChange?.(event);
      }}
    />
  );
};

const Label: React.FC<
  React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & { name: string }
> = ({ style, children, name, defaultValue, ...props }) => {
  const error = useFieldError(name);

  return (
    <label
      style={{
        display: "block",
        fontWeight: 400,
        lineHeight: "12pt",
        padding: "5pt 8pt",
        color: error ? "var(--destructive)" : undefined,
        ...style,
      }}
      {...props}
    >
      {children}
    </label>
  );
};

const RadioBoolean: React.FC<{ name: string; className?: string; onBlur?: () => void; defaultValue?: string }> = ({
  name,
  className,
  onBlur,
  defaultValue,
}) => {
  const { register } = useFormContext();

  return (
    <div className={className}>
      <label>
        <span className="radio-label">Yes</span>
        <input type="radio" value="yes" {...register(name, { onBlur })} defaultChecked={defaultValue === "yes"} />
      </label>
      <label style={{ marginLeft: "8pt" }}>
        <span className="radio-label">No</span>
        <input type="radio" value="no" {...register(name, { onBlur })} defaultChecked={defaultValue === "no"} />
      </label>
    </div>
  );
};

export const Unstyled = {
  Input,
  TextArea,
  Label,
  RadioBoolean,
  Date,
  Number: NumberInput,
  Money,
};
