import { Maps, UUID } from "@cp/toolkit";
import { SentryLogger } from "@qw/sentry";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ClientDataContext } from "@/client-data/client-data-context";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { SubmissionTemplate } from "@/supplementals/pdfs/submission-template";
import { useClientDataLazyQuery } from "src/generated/graphql";
import { SubmissionTemplateAttachments } from "./submission-template-attachments";

interface Props {
  open: boolean;
  onClose: () => void;
  clientId: string;
  opportunityId: string;
  handleCreateEmailDraft: (fileIds: string[]) => void;
}

export const SubmissionTemplateModal: React.FC<Props> = ({
  open,
  onClose,
  clientId,
  opportunityId,
  handleCreateEmailDraft,
}) => {
  const [changed, setChanged] = useState(false);
  const [, setBlurred] = useState(false);
  const [fileIds, setFileIds] = useState<string[]>([]);
  const { toast } = useToast();
  const [clientData] = useClientDataLazyQuery();

  const methods = useForm({
    defaultValues: async () => {
      if (!clientId) {
        console.warn("No clientId present. Data won't be saved.");
        return {};
      }
      const { data: supplementalResult, error } = await clientData({
        variables: { input: { insuredId: clientId as UUID } },
      });
      if (!supplementalResult) {
        SentryLogger.warn(`Supplemental result was undefined for Client(${clientId})`);
        return {};
      }
      if (error) {
        SentryLogger.exception(error);
        toast({
          title: "Error",
          description: "There was an error loading your supplemental data.",
          variant: "destructive",
        });
        return {};
      }

      const supplementalData = Maps.collectBy(
        supplementalResult.clientData,
        (val) => `${val.key}${val.index === null ? "" : `--${val.index}`}`,
        (val) => val.value
      );
      return Object.fromEntries(supplementalData.entries());
    },
  });

  const handleBlur = () => {
    setBlurred(true);
    if (changed) {
      const timeout = setTimeout(function () {
        setBlurred(false);
        setChanged(false);
      }, 1000);

      return function () {
        clearTimeout(timeout);
      };
    }
    setBlurred(false);
    return;
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent className="flex flex-col gap-0 h-dvh min-w-full p-0">
        <DialogHeader className="hidden">
          <DialogTitle>Submission Template</DialogTitle>
        </DialogHeader>
        <div className="flex flex-auto overflow-y-auto">
          <div className="p-8 w-1/2">
            <form
              id="pdf-input"
              onChange={() => setChanged(true)}
              onBlur={() => handleBlur()}
              onSubmit={(e) => e.preventDefault()}
            >
              <ClientDataContext.Provider value={{ insuredId: clientId }}>
                <FormProvider {...methods}>
                  <SubmissionTemplate />
                </FormProvider>
              </ClientDataContext.Provider>
            </form>
          </div>
          <div className="p-8 w-1/2">
            <SubmissionTemplateAttachments opportunityId={opportunityId} fileIds={fileIds} setFileIds={setFileIds} />
          </div>
        </div>
        <DialogFooter className="border-t flex-none m-0 px-8 py-3">
          <Button onClick={() => handleCreateEmailDraft(fileIds)}>Create Draft</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
