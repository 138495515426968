import { Link, Outlet, useParams } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { NavigateAgency } from "@/components/navigate/navigate-agency";
import { ScrollPane } from "@/components/scroll-pane";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarProvider,
  SidebarSection,
  SidebarTrigger,
} from "@/components/sidebar";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { useMyAccount } from "@/hooks/use-my-account";
import { PinnedInsureds } from "@/insureds/pinned-insureds";
import { CSSProperties } from "react";
import { useAgencyQuery } from "src/generated/graphql";
import { InsuredsRequests } from "./insureds-requests";

export const Insureds = () => {
  const { data: user } = useMyAccount();
  const { agencyId } = useParams();

  const { data: { agency } = {} } = useAgencyQuery({
    variables: {
      id: agencyId ?? "",
    },
    skip: !agencyId,
  });

  const id = agencyId ?? user?.agencyId;
  const agencyName = agency?.name ?? user?.agency.name;

  useDocumentTitle(`${agencyName}: QuoteWell`);

  if (!user || !user.agency || !id) {
    return null;
  }

  return (
    <SidebarProvider style={{ "--sidebar-width": "20rem" } as CSSProperties}>
      <ScrollPane>
        <Bar>
          <BarLinks>
            <BarLink to="." end>
              Active Marketing Plans
            </BarLink>
            <BarLink to="all">All Insureds</BarLink>
          </BarLinks>
          <SidebarTrigger />
        </Bar>
        <Outlet context={[id]} />
      </ScrollPane>
      <Sidebar side="right">
        <SidebarHeader>
          <HasInternalRole elseShow={user.agency.name}>
            <NavigateAgency path="/insureds" buttonProps={{ variant: "outline", size: "sm" }} />
            <Button variant="ghost" size="icon-sm" asChild>
              <Link to="/broker">
                <Icon icon="close" />
              </Link>
            </Button>
          </HasInternalRole>
        </SidebarHeader>
        <SidebarContent>
          <InsuredsRequests id={id} />
          <PinnedInsureds />
          <HasInternalRole>
            {agencyId && (
              <SidebarSection>
                <AgencyAffiliations id={agencyId} />
              </SidebarSection>
            )}
          </HasInternalRole>
        </SidebarContent>
      </Sidebar>
    </SidebarProvider>
  );
};
