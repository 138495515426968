import { useAtom } from "jotai";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { MarketsListMetricDisplayTypeAtom } from "@/atoms";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ScoredAppetiteProductFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { ProductSupportedLines } from "./lob-list";

export enum MarketsListMetricDisplayType {
  Score,
  PreviouslyMarketedCount,
  PreviouslyQuotedCount,
}

export const Product: React.FC<{
  scoredProduct: ScoredAppetiteProductFragment;
  selectedProductIds: string[] | undefined;
  requestedLines: string[];
  disabled?: boolean;
}> = ({
  scoredProduct: { score, product, previouslyMarketedCount, previouslyQuotedCount },
  selectedProductIds,
  requestedLines,
  disabled = false,
}) => {
  const [productMetricDisplayType, setProductMetricDisplayType] = useAtom(MarketsListMetricDisplayTypeAtom);
  const isChecked = selectedProductIds?.includes(product.id) ?? false;
  const [preventSelect, setPreventSelect] = useState(false);

  const iconClass =
    score > 0.79
      ? "bg-green-600 text-background "
      : score > 0.25
      ? "bg-amber-100 text-amber-900"
      : "bg-red-100 text-red-900";

  const { setValue, register } = useFormContext();

  let productMetric;
  switch (productMetricDisplayType) {
    case MarketsListMetricDisplayType.PreviouslyQuotedCount:
      productMetric = (
        <StatBadge
          text={`Quoted ${previouslyQuotedCount}/Yr`}
          tooltip="Number of times this carrier has quoted this product with QuoteWell over the past year for this Opportunty's CGL and state"
        />
      );
      break;
    case MarketsListMetricDisplayType.PreviouslyMarketedCount:
      productMetric = (
        <StatBadge
          text={`Marketed ${previouslyMarketedCount}/Yr`}
          tooltip="Number of times QuoteWell has marketed this product over the past year for Opportunties with the same CGL and state"
        />
      );
      break;
    case MarketsListMetricDisplayType.Score:
      productMetric = (
        <span
          className={cn(
            "font-semibold h-5 inline-flex items-center leading-none px-[0.75em] rounded-full text-2xs",
            iconClass
          )}
        >
          {Number(score).toLocaleString(undefined, { style: "percent", maximumSignificantDigits: 2 })}
        </span>
      );
      break;
    default:
      productMetric = null;
  }

  const toggleMetric = () => {
    const toggleOrder = {
      [MarketsListMetricDisplayType.PreviouslyQuotedCount]: MarketsListMetricDisplayType.PreviouslyMarketedCount,
      [MarketsListMetricDisplayType.PreviouslyMarketedCount]: MarketsListMetricDisplayType.Score,
      [MarketsListMetricDisplayType.Score]: MarketsListMetricDisplayType.PreviouslyQuotedCount,
    };
    void setProductMetricDisplayType(toggleOrder[productMetricDisplayType]);
  };

  return (
    <>
      <label className={"bg-background cursor-pointer flex gap-4 h-12 items-center justify-between px-4"}>
        <span className="flex-auto text-xs truncate">{product.carrierProductName}</span>
        <input
          className="hidden"
          type="checkbox"
          value={product.id}
          checked={selectedProductIds?.includes(product.id)}
          {...register("productIds")}
          onChange={(e) => {
            if (preventSelect) {
              setPreventSelect(false);
            } else {
              setValue(
                "productIds",
                e.target.checked
                  ? [...(selectedProductIds ?? []), e.target.value]
                  : selectedProductIds?.filter((id) => id !== e.target.value)
              );
            }
          }}
          disabled={disabled}
        />
        <div
          onClick={(e) => {
            e.preventDefault();
            setPreventSelect(true);
            toggleMetric();
          }}
        >
          {productMetric}
        </div>
        <ProductSupportedLines product={product} requestedLines={requestedLines} />
        <Icon
          icon={isChecked ? "check_circle" : "add_circle"}
          className={cn(
            "text-xl",
            isChecked ? "filled text-primary" : "text-muted-foreground",
            disabled && "opacity-0"
          )}
        />
      </label>
    </>
  );
};

export const StatBadge: React.FC<{ text: string; tooltip: string }> = ({ text, tooltip }) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <span
          className={cn("font-semibold h-5 inline-flex items-center leading-none px-[0.75em] rounded-full text-2xs")}
        >
          {text}
        </span>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
