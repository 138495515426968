import { useDocumentTitle } from "usehooks-ts";

import { BrokerFace } from "./components/ui/brokerface";

export const PageNotFound = () => {
  useDocumentTitle("Page Not Found: QuoteWell");
  return (
    <div className="p-4">
      <h1 className="text-3xl">We couldn&apos;t find that resource.</h1>
      <p className="my-4">However, we can still help!</p>
      <BrokerFace header="Click here to email us." className="inline-flex" />
    </div>
  );
};
