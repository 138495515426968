import { Icon } from "@/components/ui/icon";
import { BindHqLineOfBusiness } from "src/generated/graphql";
import { BindHqLineOfBusinessDisplayName } from "./bind-maps";

export const BindHQLinesOfBusiness = ({
  lines,
  onDelete,
}: {
  lines: BindHqLineOfBusiness[];
  onDelete(line: BindHqLineOfBusiness): void;
}) => {
  if (lines && lines.length > 0) {
    return (
      <ol className="flex flex-row flex-wrap m-1 gap-2">
        {lines?.map((line) => (
          <li
            key={line}
            className="bg-primary cursor-pointer flex flex-row gap-1 items-center text-background rounded-full p-1"
          >
            <span className="ml-1.5 leading-none text-xs">{BindHqLineOfBusinessDisplayName[line]}</span>
            <div className="flex h-min w-min m-0 p-0" onClick={() => onDelete(line)}>
              <Icon icon="cancel" className="filled" />
            </div>
          </li>
        ))}
      </ol>
    );
  }

  return null;
};
