import { multiMap } from "./maps";

export function partition<T>(values: T[], predicate: (item: T) => boolean): [T[], T[]] {
  const truthy: T[] = [];
  const falsy: T[] = [];

  for (const value of values) {
    if (predicate(value)) {
      truthy.push(value);
    } else {
      falsy.push(value);
    }
  }

  return [truthy, falsy];
}

/* It's not doing anything. It's a placeholder for future work. */
export function difference<T extends string | number>(a: T[], b: T[]): T[] {
  const set = new Set(b);
  return a.filter((item) => !set.has(item));
}

export const EMPTY: unknown[] = [];

export const empty = <T>(): T[] => EMPTY as T[];

export function hasDuplicates<T>(values: T[]): boolean {
  return new Set(values).size !== values.length;
}

export function insertAtPosition<T>(arr: T[], item: T, position: number): T[] {
  const clampedPosition = Math.max(0, Math.min(position, arr.length));

  return [
    ...arr.slice(0, clampedPosition),
    // insert new item
    item,
    ...arr.slice(clampedPosition),
  ];
}

/**
 * Like Array.prototype.find() but logs an error if there are more than one item found.
 */
export function findFirst<T>(values: T[], predicate: (item: T) => boolean): T | undefined {
  const found = values.filter(predicate);
  if (found.length > 1) {
    // eslint-disable-next-line no-console
    console.error("More than one item found", JSON.stringify(found));
  }
  return found[0];
}

/**
 * Like Array.prototype.sort(), but returns a new array instead of modifying
 */
export const sort = <T>(values: Iterable<T>, compareFn?: (t1: T, t2: T) => number): readonly T[] =>
  [...values].sort(compareFn);

/**
 * Like Array.prototype.reverse(), but returns a new array instead of modifying
 */
export const reverse = <T>(values: Iterable<T>): readonly T[] => [...values].reverse();

export const isNotEmpty = (values: unknown[]): boolean => values.length > 0;

/**
 * Merges items with the same key into a single item.
 */
export function mergeBy<T>(items: T[], toKey: (item: T) => string, merge: (items: T[]) => T): T[] {
  const groups = multiMap(items, toKey);
  const result: T[] = [];
  groups.forEach((group) => {
    result.push(merge(group));
  });
  return result;
}

export function sliceIntoN<T>(arr: T[], n: number): T[][] {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (v, i) => arr.slice(i * size, i * size + size));
}
