import { useMutation } from "react-query";
import { useAuthContext } from "../context/AuthContext";
import { PasswordResetDocument, PasswordResetMutationVariables } from "../generated/operations";
import { GraphQLAuthError, GraphQLErrorResponse } from "./authError";

export default function usePasswordReset() {
  const { fetcher } = useAuthContext();

  return useMutation<boolean, GraphQLErrorResponse<GraphQLAuthError> | Error, PasswordResetMutationVariables>(
    (args) => {
      return fetcher(PasswordResetDocument, args).then((res) => res.passwordReset);
    }
  );
}
