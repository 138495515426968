import { HasRole } from "@/components/has-role";
import { SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Switch } from "@/components/ui/switch";
import { Roles, useBrokerAccountsQuery, useUpdateUserAssignmentsEnabledMutation } from "src/generated/graphql";
import { AssignmentQueue } from "./assignment-queue";

export const TRIAGE_DATE_FORMAT = "yyyy-MM-dd";

export const KNOWN_BROKER_EMAILS = {
  QUOTEWELL_BROKER: "submissions@quotewell.com",
  ALBY_BROKER: "alby@quotewell.com",
  JAMES_BROKER: "jmonks+broker@quotewell.com",
};

export const BROKER_IGNORE_LIST = [
  KNOWN_BROKER_EMAILS.QUOTEWELL_BROKER,
  KNOWN_BROKER_EMAILS.ALBY_BROKER,
  KNOWN_BROKER_EMAILS.JAMES_BROKER,
];

export const Enablement = () => {
  const { data, loading } = useBrokerAccountsQuery();
  const [mutate] = useUpdateUserAssignmentsEnabledMutation();
  const brokers = data?.userAccounts ?? [];

  const filteredBrokers = brokers.filter((broker) => !BROKER_IGNORE_LIST.includes(broker.email));

  const handleToggle = (brokerId: string, enabled: boolean) => {
    void mutate({
      variables: {
        input: {
          brokerId,
          enabled,
        },
      },
    });
  };

  return (
    <HasRole roles={[Roles.Admin, Roles.Dj]}>
      <SectionHeader>
        <SectionTitle>
          <h1>Assignments Enabled For Brokers</h1>
          {loading && <Spinner />}
        </SectionTitle>
        <SectionDescription>Turn on/off to add/remove broker from the queues for their roles</SectionDescription>
      </SectionHeader>
      <SectionContent className="grid grid-cols-2">
        <div className="flex-col gap-3">
          {filteredBrokers.map(({ id, firstName, lastName, assignmentsEnabled, roles }) => {
            return (
              <div key={id} className="mx-2 my-6">
                <div className="flex items-center space-x-2">
                  <Switch
                    id="airplane-mode"
                    checked={assignmentsEnabled}
                    onCheckedChange={(val) => handleToggle(id, val)}
                  />
                  <Label htmlFor="airplane-mode">{`${firstName} ${lastName}`}</Label>
                </div>
                <div className="text-xs pl-10">{roles.join(", ")}</div>
              </div>
            );
          })}
        </div>
        <div>
          <AssignmentQueue role={Roles.BindingAuthority} />
          <AssignmentQueue role={Roles.Brokerage} />
          <AssignmentQueue role={Roles.PreferredBroker} />
        </div>
      </SectionContent>
    </HasRole>
  );
};
