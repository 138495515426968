import { KnownClientData } from "@qw/qw-common";

import { PdfInput, PdfMoney, PdfNumber, PdfTextarea, PdfYesNo } from "@/forms/pdf";
import { Address } from "./fieldsets/address";
import { GeneralInformation } from "./fieldsets/general-information";
import { LossHistory } from "./fieldsets/loss-history";
import { Paper } from "./fieldsets/paper";
import { PercentageOfPayroll } from "./fieldsets/percentage-of-payroll";
import { Signature } from "./fieldsets/signature";

export const ContractorsGeneralLiability = () => (
  <>
    <Paper>
      <section>
        <h1>Contractors General Liability</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Risk Details</h1>
        <table>
          <thead>
            <tr>
              <td colSpan={4} className="big">
                Exposure Details
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfTextarea
                colSpan={3}
                rowSpan={2}
                rows={6}
                label="Description of Operations"
                name={KnownClientData.DescriptionOfOperations}
              />
              <PdfNumber
                label="CGL Code"
                name={KnownClientData.BusinessClassification.CglCode}
                minLength={5}
                maxLength={6}
              />
            </tr>
            <tr>
              <PdfNumber label="Number of Field Employees" name="numberOfFieldEmployees" minLength={1} maxLength={5} />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Are there any other operations owned, operated, or managed by the applicant?"
                name="otherOperationsOwned"
              />
              <PdfTextarea colSpan={2} label="If yes, please describe:" name="otherOperationsOwnedDescription" />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Does the applicant have a formal safety program?"
                name="formalSafetyProgram"
              />
              <PdfTextarea colSpan={2} label="If yes, please describe:" name="formalSafetyProgramDescription" />
            </tr>
            <tr>
              <PdfNumber
                colSpan={2}
                label="What is the maximum number of stories the applicant performs exterior work?"
                name="maxStories"
                min={1}
                minLength={1}
                maxLength={3}
              />
              <PdfNumber
                colSpan={2}
                label="What is the maximum depth the insured will work at?"
                name="maxDepth"
                min={1}
                minLength={1}
                maxLength={3}
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Is the applicant performing any demolition work other than using hand tools?"
                name="demoWork"
              />
              <PdfYesNo
                colSpan={2}
                label="If yes, is this work subbed out to fully insured subs or done by the insured's employees?"
                name="demoWorkSubbed"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Is the applicant performing any work on retaining walls greater than 6 feet?"
                name="retainingWallsGreaterThanSixFeet"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Is the applicant performing any work in subsidence areas, or on slopes greater than 30 degrees?"
                name="subsidenceAreas"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={4}
                label="Is the applicant performing any foundation repair?"
                name="foundationRepair"
              />
            </tr>
            <tr>
              <PdfYesNo colSpan={4} label="Does the applicant perform any work in oilfields?" name="oilfieldWork" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <h1>Risk Details</h1>
        <h4>Please indicate the types of buildings the applicant performs new construction on:</h4>
        <table>
          <tbody>
            <tr>
              <PdfYesNo colSpan={2} label="Apartments" name="newConstructionApartments" />
            </tr>
            <tr>
              <PdfYesNo colSpan={2} label="Condominiums" name="newConstructionCondominiums" />
            </tr>
            <tr>
              <PdfYesNo colSpan={2} label="Townhomes" name="newConstructionTownhomes" />
            </tr>
            <tr>
              <PdfYesNo label="Custom Homes" name="newContructionCustomHomes" />
              <PdfNumber
                label="If yes, how many homes does the applicant expect to build the policy term?"
                name="newContructionCustomHomesQuantity"
              />
            </tr>
            <tr>
              <PdfYesNo rowSpan={2} label="Tract Homes" name="newContructionTractHomes" />
              <PdfInput
                label="If yes, what is the size of the largest development the contractor will work in?"
                name="newContructionTractHomesLargest"
              />
            </tr>
            <tr>
              <PdfNumber
                label="How many homes will the insured work on in that development?"
                name="newContructionTractHomesLargestQuantity"
              />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h4>Please indicate the % of the insured&apos;s operations per the below:</h4>
        <table className="dense">
          <thead>
            <tr>
              <th style={{ width: "50%" }}></th>
              <th>Residential %</th>
              <th>Commercial %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>New</td>
              <PdfNumber name="percentOperations:residential:new" />
              <PdfNumber name="percentOperations:commercial:new" />
            </tr>
            <tr>
              <td>Repair/Remodel</td>
              <PdfNumber name="percentOperations:residential:repairOrRemodel" />
              <PdfNumber name="percentOperations:commercial:repairOrRemodel" />
            </tr>
            <tr>
              <td>Other</td>
              <PdfNumber name="percentOperations:residential:other" />
              <PdfNumber name="percentOperations:commercial:other" />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h4>Please indicate estimated exposures and provide exposure history is applicable:</h4>
        <table className="dense">
          <thead>
            <tr>
              <th style={{ width: "50%" }}></th>
              <th>Revenue</th>
              <th>Payroll</th>
              <th>Subcosts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Next 12 Months</td>
              <PdfMoney name={KnownClientData.Revenue.Next12Months} />
              <PdfMoney name={KnownClientData.Payroll.Next12Months} />
              <PdfMoney name={KnownClientData.Subcosts.Next12Months} />
            </tr>
            <tr>
              <td>Current Year</td>
              <PdfMoney name={KnownClientData.Revenue.CurrentYear} />
              <PdfMoney name={KnownClientData.Payroll.CurrentYear} />
              <PdfMoney name={KnownClientData.Subcosts.CurrentYear} />
            </tr>
            <tr>
              <td>First Year Prior</td>
              <PdfMoney name={KnownClientData.Revenue.FirstYearPrior} />
              <PdfMoney name={KnownClientData.Payroll.FirstYearPrior} />
              <PdfMoney name={KnownClientData.Subcosts.FirstYearPrior} />
            </tr>
            <tr>
              <td>Second Year Prior</td>
              <PdfMoney name={KnownClientData.Revenue.SecondYearPrior} />
              <PdfMoney name={KnownClientData.Payroll.SecondYearPrior} />
              <PdfMoney name={KnownClientData.Subcosts.SecondYearPrior} />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <h4>
        Using the percentage of payroll (under direct) and percentage of contract costs (under subcontracted), indicate
        the percentage of construction work you will perform over the next twelve (12) months.
      </h4>
      <PercentageOfPayroll />
    </Paper>
    <Paper>
      <section>
        <h1>Subcontractor Details</h1>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                label="Does the applicant require a written contract from all subcontractors prior to being allowed on the job site?"
                name="subcontractorWrittenContract"
              />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h4>If written contracts are required, do the contracts include the following?</h4>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                label="Hold harmless and indemnification in favor of you and the owner?"
                name="subcontractorIndemnification"
              />
            </tr>
            <tr>
              <PdfYesNo label="Waiver of subrogation in favor of you?" name="subcontractorWaiveSubrogation" />
            </tr>
            <tr>
              <PdfYesNo
                label="You and owner (if applicable) named as additional insured by the subcontractor and any sub-subcontractors on subcontractor's GL policy?"
                name="subcontractorNamedInsured"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Coverage includes products/completed operations and full contractual?"
                name="subcontractorFullCoverage"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Limits of liability equal to or greater than your own?"
                name="subcontractorLimitLiability"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Do you require excess limits from subcontractors hired by you?"
                name="subcontractorExcessLimits"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Do you obtain current certificates of insurance from each subcontractor prior to them starting work?"
                name="subcontractorCertificates"
              />
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                label="Do you ever employ temporary or day laborers or 1099 employees?"
                name="subcontractorTempLabor"
              />
              <PdfTextarea label="If yes, please describe:" name="subcontractorTempLaborDescription" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <LossHistory rootPath="gl" />
    </Paper>
    <Paper>
      <section>
        <h1>Coverages Requested</h1>
        <h4>Does the insured need the below coverages to satisfy contractual requirements?</h4>
        <table>
          <tbody>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Blanket AI - Ongoing Operations (CG 20 10)"
                name="coverageBlanketOngoingOperations"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Blanket AI - Completed Operations (CG 20 37)"
                name="coverageBlanketCompletedOperations"
              />
            </tr>
            <tr>
              <PdfYesNo
                colSpan={2}
                label="Primary and Noncontributory wording"
                name="coveragePrimaryNoncontributingWoring"
              />
            </tr>
            <tr>
              <PdfYesNo colSpan={2} label="Blanket Waiver of Subrogation" name="coverageBlanketWaiverSubrogation" />
            </tr>
            <tr>
              <PdfYesNo label="Excess Liability" name="coverageExcess" />
              <PdfNumber label="If yes, what limit?" name="coverageExcessLimit" />
            </tr>
          </tbody>
        </table>
      </section>
      <Signature />
    </Paper>
  </>
);
