/* eslint-disable no-console */

import { isDefined } from "./predicates";

type NoInfer<T> = [T][T extends any ? 0 : never];

export function keyBy<V, K = string>(items: Iterable<V>, key: (item: NoInfer<V>) => K): Map<K, V> {
  const map = new Map<K, V>();
  const duplicateIds = new Set<K>();

  for (const item of items) {
    const k = key(item);
    if (map.has(k)) {
      duplicateIds.add(k);
    }
    map.set(k, item);
  }

  if (duplicateIds.size > 0) {
    // print part of the stack trace to help debugging
    const firstLines = new Error("Duplicate key").stack?.split("\n").slice(0, 3);
    console.warn(`Duplicate keys: count=${duplicateIds.size}, ${[...duplicateIds].join(", ")}. ${firstLines}`);
  }

  return map;
}

/**
 * Similar to `keyBy`, but throws an error if there are duplicate keys.
 */
export function strictKeyBy<V, K = string>(items: Iterable<V>, key: (item: NoInfer<V>) => K): Map<K, V> {
  const map = new Map<K, V>();
  for (const item of items) {
    const k = key(item);
    if (map.has(k)) {
      throw new Error(`Duplicate key: ${k}`);
    }
    map.set(k, item);
  }
  return map;
}

export const EMPTY = new Map();

export function collectBy<T, K extends string, V = T>(
  items: T[],
  toKey: (value: T) => K | undefined,
  toValue: (value: T) => V
): Map<K, V> {
  const result = new Map<K, V>();
  const duplicateIds = new Set<K>();

  items.forEach((item) => {
    const key = toKey(item);
    if (key !== undefined) {
      if (result.has(key)) {
        duplicateIds.add(key);
      }
      result.set(key, toValue(item));
    }
  });

  if (duplicateIds.size > 0) {
    // print part of the stack trace to help debugging
    const firstLines = new Error("Duplicate key").stack?.split("\n").slice(0, 3);
    console.warn(`Duplicate keys: count=${duplicateIds.size}, ${[...duplicateIds].join(", ")}. ${firstLines}`);
  }

  return result;
}

export function multiMap<T, K extends string>(items: T[], toKey: (value: NoInfer<T>) => K | undefined): Map<K, T[]>;
export function multiMap<T, K extends string, V>(
  items: T[],
  toKey: (value: T) => K | undefined,
  toValue: (value: T) => V
): Map<K, V[]>;
export function multiMap<T, K extends string, V>(
  items: T[],
  toKey: (value: T) => K | undefined,
  toValue: (value: T) => V = (x) => x as unknown as V
): Map<K, V[]> {
  const result = new Map<K, V[]>();
  items.forEach((item) => {
    const key = toKey(item);
    if (key !== undefined) {
      const values = result.get(key) || [];
      values.push(toValue(item));
      result.set(key, values);
    }
  });
  return result;
}

export function multiMapAdd<T, K extends string>(map: Map<K, T[]>, key: K, value: T): Map<K, T[]> {
  const values = map.get(key) ?? [];
  values.push(value);
  map.set(key, values);
  return map;
}

export function mapValues<K extends string, V, U>(
  map: ReadonlyMap<K, V>,
  transform: (value: V, key: K) => U
): Map<K, U> {
  const result = new Map<K, U>();
  map.forEach((value, key) => {
    result.set(key, transform(value, key));
  });
  return result;
}

export function mergeBy<K extends string, V>(
  maps: Array<ReadonlyMap<K, V>>,
  merge: (values: V[], key: K) => V
): Map<K, V> {
  const result = new Map<K, V>();
  const allKeys = new Set(maps.flatMap((map) => [...map.keys()]));
  allKeys.forEach((key) => {
    const values = maps.map((map) => map.get(key)).filter<V>(isDefined);
    result.set(key, merge(values, key));
  });
  return result;
}

export function partitionBy<K extends string, V>(
  map: ReadonlyMap<K, V>,
  partitionBy: (key: K, value: V) => boolean
): [Map<K, V>, Map<K, V>] {
  const partition1 = new Map<K, V>();
  const partition2 = new Map<K, V>();

  map.forEach((v, k) => {
    if (partitionBy(k, v)) {
      partition1.set(k, v);
    } else {
      partition2.set(k, v);
    }
  });

  return [partition1, partition2];
}

export function filter<K extends string, V>(
  map: ReadonlyMap<K, V>,
  filterBy: (key: K, value: V) => boolean
): Map<K, V> {
  const result = new Map<K, V>();

  map.forEach((v, k) => {
    if (filterBy(k, v)) {
      result.set(k, v);
    }
  });

  return result;
}

export function fromObject<V>(obj: { [key: string]: V }): Map<string, V> {
  const result = new Map<string, V>();
  Object.entries(obj).forEach(([key, value]) => {
    result.set(key, value);
  });
  return result;
}
