import { DevTool } from "@hookform/devtools";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent } from "react";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { Field, Input } from "@/forms/default";
import { GmailFilterFragment, useCreateGmailFilterMutation, useUpdateGmailFilterMutation } from "src/generated/graphql";

const FormSchema = z
  .object({
    to: z.string().optional(),
    from: z.string().optional(),
    subject: z.string().optional(),
    query: z.string().optional(),
    negatedQuery: z.string().optional(),
    hasAttachment: z.boolean().optional(),
  })
  .refine(
    (data) => {
      return Object.values(data).some((value) =>
        typeof value === "string" ? value !== "" : typeof value === "boolean" && value
      );
    },
    { message: "At least one field must be filled out" }
  );

interface Props {
  gmailFilter?: GmailFilterFragment | null;
  onCompleted: () => void;
}

export const GmailFilterForm: React.FC<Props> = ({ gmailFilter, onCompleted }) => {
  const { toast } = useToast();
  const [createGmailFilter] = useCreateGmailFilterMutation();
  const [updateGmailFilter] = useUpdateGmailFilterMutation();

  const methods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: gmailFilter
      ? {
          to: gmailFilter.to ?? undefined,
          from: gmailFilter.from ?? undefined,
          subject: gmailFilter.subject ?? undefined,
          query: gmailFilter.query ?? undefined,
          negatedQuery: gmailFilter.negatedQuery ?? undefined,
          hasAttachment: gmailFilter.hasAttachment ?? undefined,
        }
      : {
          to: undefined,
          from: undefined,
          subject: undefined,
          query: undefined,
          negatedQuery: undefined,
          hasAttachment: undefined,
        },
  });

  // get errors from react-hook-form
  const { errors } = methods.formState;

  const errorWithRootError = errors as FieldErrors & { "": { message: string } };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await methods.trigger();
    e.preventDefault();

    if (isValid) {
      const values = methods.getValues();

      // convert all empty strings to null
      const transformedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value === "" || value === false ? null : value])
      );

      await (gmailFilter
        ? updateGmailFilter({
            variables: {
              input: {
                id: gmailFilter.id,
                ...transformedValues,
              },
            },
            onCompleted: () => {
              toast({ title: "Gmail Filter Updated Successfully" });
              onCompleted();
            },
            refetchQueries: ["GmailFilters"],
          })
        : createGmailFilter({
            variables: {
              input: {
                ...transformedValues,
              },
            },
            onCompleted: () => {
              toast({ title: "Gmail Filter Created Successfully" });
              onCompleted();
            },
            refetchQueries: ["GmailFilters"],
          }));
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Field name="to" label="To">
            <Input name="to" />
          </Field>

          <Field name="from" label="From">
            <Input name="from" />
          </Field>

          <Field name="subject" label="Subject">
            <Input name="subject" />
          </Field>

          <Field name="query" label="Query">
            <Input name="query" />
          </Field>

          <Field name="negatedQuery" label="Negated Query">
            <Input name="negatedQuery" />
          </Field>

          <Field name="hasAttachment" label="Has Attachment">
            <Input name="hasAttachment" type="checkbox" />
          </Field>

          {errorWithRootError[""] && (
            <p className="text-destructive text-xs mt-1 mb-3">{errorWithRootError[""].message}</p>
          )}

          <div className="flex justify-between">
            <Button variant="destructive" onClick={onCompleted}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </form>
      </FormProvider>
      <DevTool control={methods.control} />
    </>
  );
};
