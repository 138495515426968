import React, { useEffect } from "react";

import {
  OnBrokerAssignmentQueueUpdatedDocument,
  OnBrokerAssignmentQueueUpdatedSubscription,
  OnBrokerAssignmentQueueUpdatedSubscriptionVariables,
  Roles,
  useAssignemntQueueQuery,
} from "src/generated/graphql";

interface AssignmentQueueProps {
  role?: Roles;
}

export const AssignmentQueue: React.FC<AssignmentQueueProps> = ({ role = Roles.Broker }) => {
  const { data, subscribeToMore } = useAssignemntQueueQuery();

  const assignmentQueue = data?.brokerAssignmentQueue.brokers ?? [];

  const filteredQueue = assignmentQueue.filter((broker) => broker.roles.includes(role));

  useEffect(() => {
    return subscribeToMore<
      OnBrokerAssignmentQueueUpdatedSubscription,
      OnBrokerAssignmentQueueUpdatedSubscriptionVariables
    >({
      document: OnBrokerAssignmentQueueUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const newQueue = subscriptionData.data.brokerAssignmentQueueUpdated;

        const newRet = {
          brokerAssignmentQueue: newQueue,
        };

        return newRet;
      },
    });
  }, []);

  return (
    <div className="p-4">
      <h2>{role} Queue</h2>
      <div>
        {filteredQueue?.map((broker) => {
          return (
            <div key={broker.id} className="flex items-center space-x-2 mx-2 my-3">
              <p>{`${broker.firstName} ${broker.lastName}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
