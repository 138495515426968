import { useDocumentTitle } from "usehooks-ts";

import { ErrorMessage } from "./components/error-message";

export const ErrorPage = () => {
  useDocumentTitle("Error: QuoteWell");
  return (
    <div className="p-4">
      <ErrorMessage />
    </div>
  );
};
