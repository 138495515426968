import { useParams } from "react-router";

import { SidebarNavLink, SidebarSection } from "@/components/sidebar";
import { Spinner } from "@/components/ui/loading";
import { ExpiredPolicyState, StateIndicator } from "@/metadata";
import { PolicyState, usePoliciesQuery } from "src/generated/graphql";

const now = Date.now();

export const SidebarPolicies = () => {
  const { insuredId } = useParams();

  const { data: { policies } = { policies: [] }, loading } = usePoliciesQuery({
    variables: {
      input: {
        insuredId: insuredId,
        active: true,
      },
    },
    skip: !insuredId,
  });

  if (loading) {
    return <Spinner />;
  }

  const activePolicies = policies.filter((policy) => policy.state !== PolicyState.Canceled);

  if (!activePolicies[0]) {
    return null;
  }

  return (
    <SidebarSection>
      <h6 className="text-muted-foreground">Active Policies</h6>
      <div className="space-y-4">
        {activePolicies.map((policy) => {
          const { id: policyId, expirationDate, state, policyNumber, appetiteProduct } = policy;
          const { carrierName, carrierProductName } = appetiteProduct;
          const expired = Date.parse(expirationDate) < now;
          return (
            <SidebarNavLink key={policyId} to={`policies/${policyId}`} className="block space-y-1">
              <div className="flex gap-4 items-center justify-between">
                <span className="truncate">
                  {carrierName}: {carrierProductName}
                </span>
                <StateIndicator
                  state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state}
                  displayLabel={false}
                />
              </div>
              <div className="text-2xs text-muted-foreground">#{policyNumber}</div>
            </SidebarNavLink>
          );
        })}
      </div>
    </SidebarSection>
  );
};
