import React from "react";

import {
  ActionApprovedEventFragment,
  ActionRejectedEventFragment,
  ActionRequestedEventFragment,
  ApplicationSubmittedEventFragment,
  AssignmentChangeEventFragment,
  AutomatedFollowUpEventFragment,
  BusinessClassCodesChangedEventFragment,
  CoveragesChangedEventFragment,
  CreatedEventFragment,
  DesiredEffectiveDateChangeEventFragment,
  FileUploadEventFragment,
  MarketingPlanStateTransitionFragment,
  OpportunityEventFragment,
  OutcomeChangeEventFragment,
  PolicyStateTransitionFragment,
  ProductsAddedEventFragment,
  ProductsRemovedEventFragment,
  QuoteStateTransitionFragment,
  RequirementAddedEventFragment,
  SlimAppetiteProductFragmentFragment,
  StatusChangeEventFragment,
  SubmissionStateTransitionFragment,
  SubmissionStatusChangeEventFragment,
  TriageAssignmentEventFragment,
  UserAccountFragment,
} from "src/generated/graphql";
import { BotComment } from "./bot-comment";
import { EmailEvent } from "./email-event";
import { ExclusionComment } from "./exclusion-comment";
import { GmailMessageEvent } from "./gmail-message-event";
import { GmailRelayEvent } from "./gmail-relay-event";
import { OpportunityEvent } from "./opportunity-event";
import { StateTransitionEvent } from "./state-transition-event";
import { UserComment } from "./user-comment";

interface BaseStateTransition {
  __typename?: string;
}

type MarketingPlanStateTransition = BaseStateTransition & MarketingPlanStateTransitionFragment;

type SubmissionStateTransition = BaseStateTransition &
  SubmissionStateTransitionFragment & {
    appetiteProduct: SlimAppetiteProductFragmentFragment;
  };

type QuoteStateTransition = BaseStateTransition &
  QuoteStateTransitionFragment & {
    appetiteProduct: SlimAppetiteProductFragmentFragment;
  };

type PolicyStateTransition = BaseStateTransition &
  PolicyStateTransitionFragment & {
    appetiteProduct: SlimAppetiteProductFragmentFragment;
  };

export type StateTransition =
  | MarketingPlanStateTransition
  | SubmissionStateTransition
  | QuoteStateTransition
  | PolicyStateTransition;

interface EventRendererProps {
  user: UserAccountFragment;
  event: OpportunityEventFragment | StateTransition;
}

const isStateTransition = (event: OpportunityEventFragment | StateTransition): event is StateTransition => {
  return [
    "MarketingPlanStateTransition",
    "SubmissionStateTransition",
    "QuoteStateTransition",
    "PolicyStateTransition",
  ].includes(event.__typename!);
};

export const EventRenderer: React.FC<EventRendererProps> = ({ event, user }) => {
  if (isStateTransition(event)) {
    switch (event.__typename) {
      case "MarketingPlanStateTransition":
        return <MarketingPlanTransition {...event} />;
      case "SubmissionStateTransition":
        return <SubmissionTransition {...event} />;
      case "QuoteStateTransition":
        return <QuoteTransition {...event} />;
      case "PolicyStateTransition":
        return <PolicyTransition {...event} />;
      default:
        return null;
    }
  }

  // Handle other event types
  switch (event.__typename) {
    case "UserComment":
      return <UserComment {...event} user={user} />;
    case "ExclusionComment":
      return <ExclusionComment {...event} />;
    case "StatusChangeEvent":
      return <StatusChangeEvent {...event} />;
    case "SubmissionStatusChangeEvent":
      return <SubmissionStatusChangeEvent {...event} />;
    case "AssignmentChangeEvent":
      return <AssignmentChangeEvent {...event} />;
    case "TriageAssignmentEvent":
      return <TriageAssignmentEvent {...event} />;
    case "FileUploadEvent":
      return <FileUploadEvent {...event} />;
    case "EmailEvent":
      return <EmailEvent {...event} />;
    case "GmailMessageEvent":
      return <GmailMessageEvent {...event} />;
    case "GmailRelayEvent":
      return <GmailRelayEvent {...event} />;
    case "CreatedEvent":
      return <CreatedEvent {...event} />;
    case "DesiredEffectiveDateChangeEvent":
      return <DesiredEffectiveDateChangeEvent {...event} />;
    case "OutcomeChangeEvent":
      return <OutcomeChangeEvent {...event} />;
    case "ApplicationSubmittedEvent":
      return <ApplicationSubmittedEvent {...event} />;
    case "ProductsAddedEvent":
      return <ProductsAddedEvent {...event} />;
    case "ProductsRemovedEvent":
      return <ProductsRemovedEvent {...event} />;
    case "BotCommentEvent":
      return <BotComment {...event} />;
    case "AutomatedFollowUpEvent":
      return <AutomatedFollowUpEvent {...event} />;
    case "ActionRequestedEvent":
      return <ActionRequestedEvent {...event} />;
    case "ActionApprovedEvent":
      return <ActionApprovedEvent {...event} />;
    case "ActionRejectedEvent":
      return <ActionRejectedEvent {...event} />;
    case "CoveragesChangedEvent":
      return <CoveragesChangedEvent {...event} />;
    case "BusinessClassCodesChangedEvent":
      return <BusinessClassCodesChangedEvent {...event} />;
    case "RequirementAddedEvent":
      return <RequirementAddedEvent {...event} />;
    default:
      return null;
  }
};

const StatusChangeEvent: React.FC<StatusChangeEventFragment> = (event) => {
  return <OpportunityEvent icon="build_circle" {...event} />;
};

const SubmissionStatusChangeEvent: React.FC<SubmissionStatusChangeEventFragment> = (event) => {
  return <OpportunityEvent icon="adjust" {...event} />;
};

const AssignmentChangeEvent: React.FC<AssignmentChangeEventFragment> = (event) => {
  return <OpportunityEvent icon="face" {...event} />;
};

const AutomatedFollowUpEvent: React.FC<AutomatedFollowUpEventFragment> = (event) => {
  return <OpportunityEvent icon="smart_toy" {...event} />;
};
const ActionRequestedEvent: React.FC<ActionRequestedEventFragment> = (event) => {
  return <OpportunityEvent icon="mode_heat" {...event} />;
};
const ActionApprovedEvent: React.FC<ActionApprovedEventFragment> = (event) => {
  return <OpportunityEvent icon="mode_heat" {...event} />;
};
const ActionRejectedEvent: React.FC<ActionRejectedEventFragment> = (event) => {
  return <OpportunityEvent icon="mode_heat" {...event} />;
};

const TriageAssignmentEvent: React.FC<TriageAssignmentEventFragment> = (event) => {
  return <OpportunityEvent icon="face" {...event} />;
};

const FileUploadEvent: React.FC<FileUploadEventFragment> = (event) => {
  return <OpportunityEvent icon="cloud_upload" {...event} />;
};

const CreatedEvent: React.FC<CreatedEventFragment> = (event) => {
  return <OpportunityEvent icon="rocket_launch" {...event} />;
};

const DesiredEffectiveDateChangeEvent: React.FC<DesiredEffectiveDateChangeEventFragment> = (event) => {
  return <OpportunityEvent icon="event" {...event} />;
};

const OutcomeChangeEvent: React.FC<OutcomeChangeEventFragment> = (event) => {
  return <OpportunityEvent icon="build_circle" {...event} />;
};

const ApplicationSubmittedEvent: React.FC<ApplicationSubmittedEventFragment> = (event) => {
  return <OpportunityEvent icon="assignment_returned" {...event} />;
};

const ProductsAddedEvent: React.FC<ProductsAddedEventFragment> = (event) => {
  return <OpportunityEvent icon="add_circle" {...event} />;
};
const CoveragesChangedEvent: React.FC<CoveragesChangedEventFragment> = (event) => {
  return <OpportunityEvent icon="add_circle" {...event} />;
};
const BusinessClassCodesChangedEvent: React.FC<BusinessClassCodesChangedEventFragment> = (event) => {
  return <OpportunityEvent icon="add_circle" {...event} />;
};
const RequirementAddedEvent: React.FC<RequirementAddedEventFragment> = (event) => {
  return <OpportunityEvent icon="add_circle" {...event} />;
};

const ProductsRemovedEvent: React.FC<ProductsRemovedEventFragment> = (event) => {
  return <OpportunityEvent icon="cancel" {...event} />;
};

const MarketingPlanTransition: React.FC<MarketingPlanStateTransition> = (event) => {
  return <StateTransitionEvent icon="add_circle" transitionType="Marketing plan" {...event} />;
};

const SubmissionTransition: React.FC<SubmissionStateTransition> = (event) => {
  return <StateTransitionEvent icon="add_circle" transitionType="Submission" {...event} />;
};

const QuoteTransition: React.FC<QuoteStateTransition> = (event) => {
  return <StateTransitionEvent icon="add_circle" transitionType="Quote" {...event} />;
};

const PolicyTransition: React.FC<PolicyStateTransition> = (event) => {
  return <StateTransitionEvent icon="add_circle" transitionType="Policy" {...event} />;
};
