import { FC, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { ErrorMessage } from "@/components/error-message";
import { HasInternalRole } from "@/components/has-role";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/forms/default";
import { Reform } from "@/forms/reform";
import {
  FileUploadFragment,
  File_Audience,
  useDeleteFileUploadMutation,
  useFindFileUploadQuery,
  useUpdateFileUploadAudienceMutation,
  useUpdateFileUploadFilenameMutation,
} from "src/generated/graphql";
import { downloadFile } from "src/utils/file";
import { PDFViewer } from "./file-upload-viewer";
import TagSelector from "./tag-selector";

export const FILE_MENU_OPTION_KEYS = [
  "editTags",
  "editPermissions",
  "renameFile",
  "deleteFile",
  "download",
  "viewInsured",
] as const;

const defaultInclude = FILE_MENU_OPTION_KEYS.filter((key) => key !== "viewInsured");

export type FileMenuOptionKeys = (typeof FILE_MENU_OPTION_KEYS)[number];

export interface FileMenuProps {
  file: FileUploadFragment & { labels: string[]; insuredId?: string | null };
  include?: FileMenuOptionKeys[];
}

export const FileMenu: FC<FileMenuProps> = ({ file, include = defaultInclude }) => {
  const [selectedItem, setSelectedItem] = useState<MenuItemKey | null>(null);

  const handleMenuItemClick = (item: MenuItemKey) => setSelectedItem(item);

  type MenuItemKey = keyof typeof MENU_MAP;

  const MENU_MAP = {
    editTags: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem onClick={() => handleMenuItemClick("editTags")}>
            <Icon icon="style" />
            Edit Tags
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <EditTagsDialogContent file={file} />,
    },
    editPermissions: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem onClick={() => handleMenuItemClick("editPermissions")}>
            <Icon icon="lock" />
            Edit Permissions
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <EditFilePermissions file={file} />,
    },
    renameFile: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem onClick={() => handleMenuItemClick("renameFile")}>
            <Icon icon="edit" />
            Edit Filename
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <RenameFile fileId={file.id} />,
    },
    deleteFile: {
      menuItem: (
        <HasInternalRole>
          <DropdownMenuItem onClick={() => handleMenuItemClick("deleteFile")}>
            <Icon icon="delete" />
            Delete File
          </DropdownMenuItem>
        </HasInternalRole>
      ),
      content: <DeleteFile file={file} />,
    },
  };

  const activeMenuItemKeys = useMemo(
    () => Object.keys(MENU_MAP).filter((itemKey) => include.includes(itemKey as MenuItemKey)) as MenuItemKey[],
    [include]
  );

  return (
    <Dialog>
      <MoreMenu>
        {activeMenuItemKeys.length > 0 && (
          <DialogTrigger>
            {activeMenuItemKeys.map((itemKey, i) => (
              <div key={i}>{MENU_MAP[itemKey].menuItem}</div>
            ))}
          </DialogTrigger>
        )}
        {include.includes("viewInsured") && (
          <DropdownMenuItem asChild>
            <Link to={`/insured/${file.insuredId}`}>
              <Icon icon="person" />
              View Insured
            </Link>
          </DropdownMenuItem>
        )}
        {include.includes("download") && (
          <DropdownMenuItem>
            <a
              href={`/api/files/${file.id}`}
              download={file.filename}
              media={file.mimeType}
              className="flex gap-3 items-center"
              onClick={async (e) => {
                e.preventDefault();
                await downloadFile(file);
              }}
            >
              <Icon icon="download" />
              Download
            </a>
          </DropdownMenuItem>
        )}
      </MoreMenu>
      {selectedItem && MENU_MAP[selectedItem] ? MENU_MAP[selectedItem].content : null}
    </Dialog>
  );
};

export function EditTagsDialogContent({ file }: { file: FileUploadFragment & { labels: string[] } }) {
  return (
    <DialogContent className="sm:max-w-[550px] max-h-[90vh] overflow-scroll">
      <DialogTitle>Edit Tags</DialogTitle>
      <DialogDescription className="text-wrap">
        Add or remove tags for this file.
        <div>{file.filename}</div>
      </DialogDescription>
      <div>
        <TagSelector fileId={file.id} />
      </div>
    </DialogContent>
  );
}
export function EditFilePermissions({ file }: { file: FileUploadFragment & { labels: string[] } }) {
  const { toast } = useToast();
  const [update] = useUpdateFileUploadAudienceMutation();
  const [audience, setAudience] = useState<File_Audience | undefined>((file?.audience as File_Audience) ?? undefined);

  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>Edit Permissions</DialogTitle>
      <DialogDescription className="text-wrap">
        Select who should have access to this file
        <div>{file.filename}</div>
      </DialogDescription>
      <div>
        <Select
          value={audience ?? undefined}
          onValueChange={(value) => {
            void update({
              variables: {
                input: {
                  id: file.id,
                  audience: value as File_Audience,
                },
              },
              onError: (e) => {
                toast({ title: e.message });
              },
              onCompleted: () => {
                toast({ title: `Permission Updated` });
                setAudience(value as File_Audience);
              },
              refetchQueries: ["FindFilesByLabel", "SubmissionPacket"],
            });
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Audience" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={File_Audience.Internal}>Internal Users Only</SelectItem>
            <SelectItem value={File_Audience.External}>All Users</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </DialogContent>
  );
}

export function DeleteFile({ file }: { file: FileUploadFragment & { labels: string[]; deletedAt?: Date | null } }) {
  const { toast } = useToast();
  const [deleteFile] = useDeleteFileUploadMutation();
  const restoreFile = file.deletedAt ? false : true;
  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>{restoreFile ? "Delete File" : "Restore File"}</DialogTitle>
      <DialogDescription className="text-wrap">
        {restoreFile ? "Delete File" : "Restore File"}: {file.filename}
        <div>This will {restoreFile ? "Delete The File" : "Restore the File"}.</div>
      </DialogDescription>
      <Button
        className="w-full"
        onClick={() => {
          void deleteFile({
            variables: {
              input: {
                id: file.id,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `File ${restoreFile ? "Deleted" : "Restored"}` });
            },
            refetchQueries: ["FindFilesByLabel", "SubmissionPacket"],
          });
        }}
      >
        {restoreFile ? "Delete File" : "Restore File"}
      </Button>
    </DialogContent>
  );
}
export function RenameFile({ fileId }: { fileId: string }) {
  const { data, loading, error } = useFindFileUploadQuery({
    variables: {
      input: {
        id: fileId,
      },
    },
  });

  const file = data?.findFile;

  if (loading) {
    return <Loading />;
  }
  if (!file || error) {
    return <ErrorMessage />;
  }
  return (
    <DialogContent className="sm:max-w-[550px]">
      <DialogTitle>Rename file</DialogTitle>
      <DialogDescription className="text-wrap">
        Rename the selected file: {file.filename}
        <div>A file extension (.pdf, .png, etc) is not necessary</div>
      </DialogDescription>
      <RenameFileContent fileId={file.id} />
    </DialogContent>
  );
}

export const RenameFileContent = ({ fileId }: { fileId: string }) => {
  const [update] = useUpdateFileUploadFilenameMutation();
  const { toast } = useToast();
  const { data, loading } = useFindFileUploadQuery({
    variables: {
      input: {
        id: fileId,
      },
    },
  });
  const FormSchema = z.object({
    filename: z.string().min(1, { message: "Invalid name" }),
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Reform
        className="py-4 space-y-4"
        schema={FormSchema}
        onSubmit={(_, values) => {
          void update({
            variables: {
              input: {
                id: fileId,
                ...values,
              },
            },
            onError: (e) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Filename updated` });
            },
            refetchQueries: ["FilesByLabel"],
          });
        }}
        defaultValues={{
          filename: data?.findFile.filename ?? "",
        }}
      >
        <Input name="filename" placeholder="New File Name" />
        <Button type="submit" className="w-full">
          {loading ? <Loading /> : "Save File Name"}
        </Button>
      </Reform>
    </div>
  );
};
export function ViewPDF({
  file,
  files,
  setSelectedFile,
}: {
  file: FileUploadFragment;
  files: Array<{
    __typename?: "FileUpload" | undefined;
    deletedAt?: any;
    createdAt: any;
    labels: string[];
    id: string;
    filename: string;
    mimeType: string;
    insuredId?: string | null;
    uploader?:
      | {
          __typename?: "UserAccount" | undefined;
          firstName: string;
          lastName: string;
        }
      | null
      | undefined;
  }>;
  setSelectedFile: (file: any) => void;
}) {
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    const index = files.map((f) => f.id).indexOf(file.id);
    setIndex(index);
  }, [file]);

  const getNextFile = () => {
    if (!index && index !== 0) {
      return;
    }
    setSelectedFile(files[index + 1]);
  };
  const getPreviousFile = () => {
    if (!index) {
      return;
    }
    setSelectedFile(files[index - 1]);
  };

  return (
    <DialogContent
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex justify-center items-center"
      style={{
        minWidth: "80vw",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={getPreviousFile} disabled={index === 0}>
        <Icon icon="chevron_left" />
      </Button>
      <PDFViewer file={file} />
      <Button onClick={getNextFile} disabled={index === files.length - 1}>
        <Icon icon="chevron_right" />
      </Button>
    </DialogContent>
  );
}
