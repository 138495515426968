import { UUID } from "@cp/toolkit";
import { useNavigate, useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { MoreMenu } from "@/components/more-menu";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { PolicyState, useCreateRenewalMutation } from "src/generated/graphql";

export const PolicyDropdown = ({ id, state }: { id: UUID; state: PolicyState }) => {
  const { insuredId } = useParams();
  const navigate = useNavigate();
  const [createRenewal] = useCreateRenewalMutation();

  const allowRenewal = state === PolicyState.Issued;

  const handleCreateRenewal = async () => {
    void createRenewal({
      variables: { input: { policyId: id } },
      onCompleted: (data) => navigate(`/insured/${insuredId}/plans/${data.createRenewal.id}`),
    });
  };

  return (
    <HasInternalRole>
      <MoreMenu>
        <DropdownMenuItem disabled={!allowRenewal} onClick={handleCreateRenewal}>
          <Icon icon="cycle" /> Create Renewal
        </DropdownMenuItem>
      </MoreMenu>
    </HasInternalRole>
  );
};
