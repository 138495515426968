import type { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { DatePicker } from "@/components/ui/data-picker-v2";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export type FieldDatePickerProps = FieldBaseProps & {
  placeholder?: string;
  dateFormat?: string;
  inputProps?: ComponentProps<typeof DatePicker>;
};

export const FieldDatePicker: FC<FieldDatePickerProps> = ({ inputProps, ...props }) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  return (
    <FieldBase {...props}>
      <DatePicker {...field} {...inputProps} className={cn({ "border-destructive": !!error }, inputProps?.className)} />
    </FieldBase>
  );
};
