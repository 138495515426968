import { GraphQLErrorResponse } from "@cp/auth";
import { GraphQLError } from "graphql";

export function isGraphQLErrorResponse<TError extends GraphQLError = GraphQLError>(
  err: unknown
): err is GraphQLErrorResponse<TError> {
  return !!err && typeof err === "object" && "response" in err;
}

export function humanReadableStatus(status: number): string {
  switch (status) {
    case StatusCodes.BadRequest:
      return "Bad Request";
    case StatusCodes.Unauthorized:
      return "Unauthorized";
    case StatusCodes.Forbidden:
      return "Forbidden";
    case StatusCodes.NotFound:
      return "Not Found";
    default:
      if (StatusCodes.isServer(status)) {
        return "Internal Server Error";
      }
      return "Unknown";
  }
}

export const StatusCodes = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
  isServer: (status: number) => status >= 500 && status < 600,
  isClient: (status: number) => status >= 400 && status < 500,
};
