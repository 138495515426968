import { useState } from "react";

import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/forms/default";

export default function SelectWithMoneyElement({
  clientDataKey,
  label,
  selectClientDataKey,
  items,
}: {
  clientDataKey: string;
  label: string;
  selectClientDataKey: string;
  items: Array<{ value: string; label?: string }>;
}) {
  const [selected, setSelected] = useState<string>();
  return (
    <div className="col-span-1 space-y-1">
      <Label className="text-sm">{label}</Label>
      <div className="flex gap">
        <Select name={selectClientDataKey} value={selected} onValueChange={(v) => setSelected(v)} required>
          <SelectTrigger>
            <SelectValue placeholder="Select One" />
          </SelectTrigger>
          <SelectContent>
            {items?.map((item: any) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label ?? item.value}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Input type="text" name={clientDataKey} required />
      </div>
    </div>
  );
}
