import { useDocumentTitle } from "usehooks-ts";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Spinner } from "@/components/ui/loading";
import { useMyAccount } from "@/hooks/use-my-account";
import { MarketingPlanState, useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { BrokerRow } from "./broker-row";

export const BrokerActive = () => {
  const { data: user } = useMyAccount();

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans } } = {
      groupedMarketingPlans: { groupedMarketingPlans: [] },
    },
    loading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        brokerId: user?.id,
        states: [MarketingPlanState.InformationReview, MarketingPlanState.Marketing],
      },
    },
    skip: !user,
    fetchPolicy: "cache-and-network",
  });

  useDocumentTitle("Active Marketing Plans: QuoteWell");

  if (!user) {
    return null;
  }

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Active Marketing Plans</h1>
          {loading && <Spinner />}
        </SectionTitle>
      </SectionHeader>
      {groupedMarketingPlans[0] ? (
        <Grid className="grid-cols-[1.5rem_1fr_1fr_1fr_1fr]">
          <GridRowHeader>
            <GridCell />
            <GridCell>Insured</GridCell>
            <GridCell>Lines</GridCell>
            <GridCell>Status</GridCell>
            <GridCell>Eff. Date</GridCell>
          </GridRowHeader>
          {groupedMarketingPlans.map((group) => (
            <BrokerRow key={group.insuredId} group={group} />
          ))}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No active marketing plans found.</h2>} />
      )}
    </>
  );
};
