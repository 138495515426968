import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { FileUploadFragment } from "src/generated/graphql";
import { downloadFile } from "src/utils/file";

export const FileButton = ({ file, badge }: { file: FileUploadFragment; badge?: string }) => (
  <Button variant="outline" size="sm" display="flex" asChild>
    <a
      key={file?.id}
      href={`/api/files/${file?.id}`}
      download={file?.filename}
      media={file?.mimeType}
      className="justify-between text-2xs truncate"
      onClick={async (e) => {
        e.preventDefault();
        await downloadFile(file);
      }}
    >
      <span className="truncate">{file.filename}</span>
      {badge && <Badge>{badge}</Badge>}
    </a>
  </Button>
);
