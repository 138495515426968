import { FilesFolders } from "@/files/files-folders";
import { ExternalDropzone } from "./external-dropzone";
import { FilesList } from "./files-list";

export const Files = () => (
  <ExternalDropzone className="flex-auto @3xl:grid grid-cols-4">
    <FilesFolders />
    <FilesList />
  </ExternalDropzone>
);
