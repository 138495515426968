import { ChevronDown } from "lucide-react";
import { useState } from "react";

import { Button, ButtonProps } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export const Combobox = ({
  value,
  placeholder,
  empty,
  children,
  buttonProps = { variant: "outline" },
}: {
  value?: string;
  placeholder: string;
  empty: string;
  children: React.ReactNode;
  buttonProps?: ButtonProps;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} {...buttonProps}>
          {value}
          <ChevronDown className="h-4 ml-auto w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <Command onClick={() => setOpen(false)}>
          <CommandInput placeholder={placeholder} />
          <CommandEmpty>{empty}</CommandEmpty>
          <CommandGroup>{children}</CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
