import { Grid, GridCell, GridRowHeader } from "@/components/grid";
import { useMyAccount } from "@/hooks/use-my-account";
import { MarketingPlan } from "@/marketing-plans/marketing-plan-details";
import { SubmissionState } from "src/generated/graphql";
import { Submission } from "./submission";

const SUBMISSION_ORDER = [
  SubmissionState.Quoted,
  SubmissionState.Submitted,
  SubmissionState.Referred,
  SubmissionState.Pending,
  SubmissionState.RuledOut,
  SubmissionState.MarketBlocked,
  SubmissionState.Declined,
];

export function Submissions({ opportunity, refetch }: { opportunity: MarketingPlan; refetch: () => void }) {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const visibleSubmissions = opportunity.submissions.filter(
    (submission) => submission.state !== SubmissionState.Pending || user.internal
  );

  if (visibleSubmissions.length === 0) {
    return <div className="italic p-6 pt-0 text-muted-foreground text-sm">Marketing Plan pending.</div>;
  }

  return (
    <Grid className="grid-cols-6">
      <GridRowHeader>
        <GridCell className="col-span-2">Carrier / Product</GridCell>
        <GridCell>Status / Updated</GridCell>
        <GridCell className="col-span-3" />
      </GridRowHeader>
      {visibleSubmissions
        .sort((a, b) => {
          const aIndex = SUBMISSION_ORDER.indexOf(a.state);
          const bIndex = SUBMISSION_ORDER.indexOf(b.state);
          return aIndex - bIndex;
        })
        .map((submission) => (
          <Submission key={submission.id} submission={submission} refetch={refetch} />
        ))}
    </Grid>
  );
}
