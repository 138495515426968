import { ApolloLink } from "@apollo/client";
import { Kind, OperationDefinitionNode, OperationTypeNode } from "graphql";

// track mutation operations and invalidate cache based on the operation name
export const invalidationLink = new ApolloLink((operation, forward) => {
  // get all mutation operations
  const mutations = operation.query.definitions.filter<OperationDefinitionNode>(
    (d): d is OperationDefinitionNode =>
      d.kind === Kind.OPERATION_DEFINITION && d.operation === OperationTypeNode.MUTATION
  );
  // get all the names of the mutation operations
  const mutationNames = mutations.flatMap((m) =>
    m.selectionSet.selections.flatMap((s) => (s.kind === Kind.FIELD ? [s.name.value] : []))
  );

  if (mutationNames.length === 0) {
    return forward(operation);
  }

  return forward(operation).map((data) => {
    // Example invalidation:
    // if (mutationNames.includes("submitSlice") || mutationNames.includes("addCarrierForApplication")) {
    //   void apolloClient.refetchQueries({
    //     updateCache(cache) {
    //       cache.evict({ fieldName: "categoryHints" });
    //       cache.evict({ fieldName: "carrierHints" });
    //     },
    //   });
    // }
    return data;
  });
});
