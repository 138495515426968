import { Link, LinkProps } from "react-router-dom";

import { InsuredPin } from "@/components/insured-pin";
import { Icon } from "@/components/ui/icon";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { cn } from "src/utils";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["insured"];

export const BankanCard = ({ children, insured }: { children: React.ReactNode; insured?: Insured }) => (
  <div className="truncate">
    {insured && <BankanCardHeader insured={insured} />}
    <div className="bg-background rounded-lg z-1">{children}</div>
  </div>
);

const BankanCardHeader = ({ insured }: { insured: Insured }) => (
  <header className="bg-accent -mb-1.5 px-4 pt-3 pb-4 space-y-1 rounded-t-lg shadow-inner z-0">
    <div className="flex gap-3">
      <span className="font-semibold mr-auto text-xs truncate">{insured.name}</span>
      <InsuredPin id={insured.id} className="-my-1 -mr-1.5" />
    </div>
    <div className="text-2xs text-muted-foreground truncate">
      {insured.agent.firstName} {insured.agent.lastName}, {insured.agent.agency.name}
    </div>
  </header>
);

export const BankanLink = ({ children, className, to, ...rest }: LinkProps & { cardClassName?: string }) => (
  <Link
    to={to}
    className={cn(
      "group bg-background border border-muted-foreground flex flex-col gap-2 -mt-px group-first:mt-0 px-4 py-3 relative group-first:rounded-t-lg group-last:rounded-b-lg",
      className
    )}
    {...rest}
  >
    {children}
  </Link>
);

export const BankanLinkText = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("text-xs truncate", className)} {...rest}>
    {children}
  </div>
);

export const BankanLinkFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("flex gap-3 items-center justify-between text-2xs", className)} {...rest}>
    {children}
  </footer>
);

const now = Date.now();

export const DaysSince = ({ date }: { date: string }) => (
  <div className="filled flex font-semibold gap-1 items-center text-2xs tracking-tight">
    <Icon icon="event" />
    {daysDelta(date)}
  </div>
);

export const daysDelta = (date: string) => Math.round((now - Date.parse(date)) / 86_400_400);
