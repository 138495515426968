import { Check } from "lucide-react";
import { useNavigate, useParams } from "react-router";

import { Combobox } from "@/components/ui/combobox";
import { CommandItem } from "@/components/ui/command";
import { useAgenciesQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { ButtonProps } from "../ui/button";

export const NavigateAgency = ({ path, buttonProps }: { path: string; buttonProps?: ButtonProps }) => {
  const { agencyId } = useParams();
  const navigate = useNavigate();

  const { data: agenciesData } = useAgenciesQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <Combobox
      value={agencyId ? agenciesData?.agencies.find((agency) => agency.id === agencyId)?.name : "Select Agency"}
      placeholder="Search Agencies..."
      empty="No agencies found."
      buttonProps={buttonProps}
    >
      {agenciesData?.agencies.map((agency) => (
        <CommandItem
          key={agency.id}
          value={agency.name.toLowerCase().trim()}
          onSelect={() => navigate(`${path}/${agency.id}`)}
        >
          <Check className={cn("mr-2 h-4 w-4", agencyId === agency.id ? "opacity-100" : "opacity-0")} />
          <span className="truncate">{agency.name}</span>
        </CommandItem>
      ))}
    </Combobox>
  );
};
