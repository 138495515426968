import { BindHqInsuredType, BindHqLineOfBusiness } from "src/generated/graphql";

export const BindHqLineOfBusinessDisplayName: Record<BindHqLineOfBusiness, string> = {
  [BindHqLineOfBusiness.Acct]: "Accountants Professional",
  [BindHqLineOfBusiness.Ache]: "Accident and Health",
  [BindHqLineOfBusiness.ActiveShooter]: "Active Shooter",
  [BindHqLineOfBusiness.Agop]: "Agricultural Output Program (Used primarily for statistical reporting)",
  [BindHqLineOfBusiness.Aglia]: "Agriculture Liability",
  [BindHqLineOfBusiness.Apkge]: "Agriculture Package",
  [BindHqLineOfBusiness.Agpr]: "Agriculture Property",
  [BindHqLineOfBusiness.Agpp]: "Agriculture Scheduled and Unscheduled Personal Property",
  [BindHqLineOfBusiness.Airc]: "Aircraft",
  [BindHqLineOfBusiness.Aprod]: "Aircraft Products Liability",
  [BindHqLineOfBusiness.Airpfb]: "Airport & Fixed Base Operator",
  [BindHqLineOfBusiness.Arch]: "Architects Professional",
  [BindHqLineOfBusiness.Art]: "Artisans",
  [BindHqLineOfBusiness.Auto]: "Automobile",
  [BindHqLineOfBusiness.Autob]: "Automobile - Business",
  [BindHqLineOfBusiness.Autop]: "Automobile - Personal",
  [BindHqLineOfBusiness.Avpkg]: "Aviation Package",
  [BindHqLineOfBusiness.Bail]: "Bailees",
  [BindHqLineOfBusiness.Bandm]: "Boiler and Machinery (Equipment Breakdown)",
  [BindHqLineOfBusiness.BuildersRisk]: "Builders Risk",
  [BindHqLineOfBusiness.Bop]: "Business Owners Policy",
  [BindHqLineOfBusiness.Bopgl]: "Business Owners Policy Liability",
  [BindHqLineOfBusiness.Boppr]: "Business Owners Policy Property",
  [BindHqLineOfBusiness.Bpp]: "Business Personal Property",
  [BindHqLineOfBusiness.Cavn]: "Commercial Aviation",
  [BindHqLineOfBusiness.Cyber]: "Commercial Cyber and Privacy Liability",
  [BindHqLineOfBusiness.Cfire]: "Commercial Fire",
  [BindHqLineOfBusiness.Cpmp]: "Commercial Multi Peril",
  [BindHqLineOfBusiness.Cop]: "Commercial Output Program (Used primarily for statistical reporting)",
  [BindHqLineOfBusiness.Cpkge]: "Commercial Package",
  [BindHqLineOfBusiness.Cp]: "Commercial Property",
  [BindHqLineOfBusiness.ContingentAuto]: "Contingent Auto",
  [BindHqLineOfBusiness.ContingentCargo]: "Contingent Cargo",
  [BindHqLineOfBusiness.Contr]: "Contract",
  [BindHqLineOfBusiness.ContractorsEquipment]: "Contractors Equipment",
  [BindHqLineOfBusiness.Cpl]: "Contractors Pollution Liability",
  [BindHqLineOfBusiness.Crim]: "Crime",
  [BindHqLineOfBusiness.Crime]: "Crime (includes Burglary)",
  [BindHqLineOfBusiness.Do]: "Directors And Officers",
  [BindHqLineOfBusiness.Disab]: "Disability",
  [BindHqLineOfBusiness.Dfire]: "Dwelling Fire",
  [BindHqLineOfBusiness.Eq]: "Earthquake",
  [BindHqLineOfBusiness.El]: "Employers Liability",
  [BindHqLineOfBusiness.Epli]: "Employment Practices Liability Insurance",
  [BindHqLineOfBusiness.Epkg]: "Environmental Package",
  [BindHqLineOfBusiness.Eil]: "Environmental Impairment Liability/Site Specific Liability",
  [BindHqLineOfBusiness.Eqlia]: "Equine Liability",
  [BindHqLineOfBusiness.Eqfltr]: "Equipment Floater",
  [BindHqLineOfBusiness.Eo]: "Errors and Omissions",
  [BindHqLineOfBusiness.Expl]: "Excess Environmental",
  [BindHqLineOfBusiness.Exlia]: "Excess Liability",
  [BindHqLineOfBusiness.Cfrm]: "Farm Owners",
  [BindHqLineOfBusiness.Fidty]: "Fidelity",
  [BindHqLineOfBusiness.Fiduc]: "Fiduciary",
  [BindHqLineOfBusiness.Flood]: "Flood",
  [BindHqLineOfBusiness.Garag]: "Garage and Dealers",
  [BindHqLineOfBusiness.Gl]: "General Liability",
  [BindHqLineOfBusiness.Hlth]: "Health",
  [BindHqLineOfBusiness.Home]: "Homeowners",
  [BindHqLineOfBusiness.Hbb]: "Home Based Business",
  [BindHqLineOfBusiness.Inmar]: "Inland Marine",
  [BindHqLineOfBusiness.Inmrc]: "Inland Marine (commercial)",
  [BindHqLineOfBusiness.Inmrp]: "Inland Marine (personal lines)",
  [BindHqLineOfBusiness.InstallationFloater]: "Installation Floater",
  [BindHqLineOfBusiness.Agent]: "Insurance Agents",
  [BindHqLineOfBusiness.Il]: "Interline",
  [BindHqLineOfBusiness.Judcl]: "Judicial",
  [BindHqLineOfBusiness.Kidra]: "Kidnap and Ransom",
  [BindHqLineOfBusiness.Law]: "Lawyers Professional",
  [BindHqLineOfBusiness.Licpt]: "License and Permit",
  [BindHqLineOfBusiness.Ll]: "Liquor Liability",
  [BindHqLineOfBusiness.Lvstk]: "Livestock",
  [BindHqLineOfBusiness.Lmort]: "Livestock Mortality",
  [BindHqLineOfBusiness.Logpckg]: "Logistics Package",
  [BindHqLineOfBusiness.Lstin]: "Lost Instrument",
  [BindHqLineOfBusiness.Media]: "Media Professional",
  [BindHqLineOfBusiness.Mmal]: "Medical Malpractice",
  [BindHqLineOfBusiness.Mpl]: "Medical Professional Liability",
  [BindHqLineOfBusiness.Bmisc]: "Miscellaneous Bond",
  [BindHqLineOfBusiness.Plmsc]: "Miscellaneous Professional Liability",
  [BindHqLineOfBusiness.Mhome]: "Mobile Homeowners",
  [BindHqLineOfBusiness.Motorcycle]: "Motorcycle",
  [BindHqLineOfBusiness.Mtrcr]: "Motor Carrier",
  [BindHqLineOfBusiness.Mtc]: "Motor Truck Cargo",
  [BindHqLineOfBusiness.Nwfgr]: "New Financial Guarantee",
  [BindHqLineOfBusiness.Blank]: "Not Applicable (Blank)",
  [BindHqLineOfBusiness.Comr]: "Ocean Marine",
  [BindHqLineOfBusiness.Olib]: "Other Liability",
  [BindHqLineOfBusiness.Ocp]: "Owners & Contractors Protective",
  [BindHqLineOfBusiness.Pkg]: "Package",
  [BindHqLineOfBusiness.Ppl]: "Personal and Premises Liability",
  [BindHqLineOfBusiness.Prsl]: "Personal Liability",
  [BindHqLineOfBusiness.Ppkge]: "Personal Package",
  [BindHqLineOfBusiness.Phys]: "Physicians and Surgeons",
  [BindHqLineOfBusiness.Hang]: "Private Hangar",
  [BindHqLineOfBusiness.ProductRecall]: "Product Recall",
  [BindHqLineOfBusiness.Plib]: "Products Liability",
  [BindHqLineOfBusiness.Mpp]: "Products Pollution",
  [BindHqLineOfBusiness.Pl]: "Professional Liability",
  [BindHqLineOfBusiness.Pubof]: "Public Official",
  [BindHqLineOfBusiness.Rrprl]: "Railroad Protective Liability",
  [BindHqLineOfBusiness.Recv]: "Recreational Vehicles",
  [BindHqLineOfBusiness.Shipintr]: "Shippers Interest",
  [BindHqLineOfBusiness.Scap]: "Small Commercial Package",
  [BindHqLineOfBusiness.Sfrnc]: "Small Farm/Ranch",
  [BindHqLineOfBusiness.Smp]: "Special Multi-Peril",
  [BindHqLineOfBusiness.Stock]: "Stock",
  [BindHqLineOfBusiness.Stkth]: "Stock Throughput",
  [BindHqLineOfBusiness.Stpl]: "Storage Tank Liability",
  [BindHqLineOfBusiness.Sure]: "Surety",
  [BindHqLineOfBusiness.Tech]: "Technology",
  [BindHqLineOfBusiness.TripTransit]: "Trip Transit",
  [BindHqLineOfBusiness.Truck]: "Truckers",
  [BindHqLineOfBusiness.Umbrl]: "Umbrella",
  [BindHqLineOfBusiness.Umbrc]: "Umbrella - Commercial",
  [BindHqLineOfBusiness.Umbrp]: "Umbrella - Personal (excess indemnity)",
  [BindHqLineOfBusiness.Un]: "Unknown",
  [BindHqLineOfBusiness.Warel]: "Warehouse Legal",
  [BindHqLineOfBusiness.Boat]: "Watercraft (small boat)",
  [BindHqLineOfBusiness.Wind]: "Wind Policy",
  [BindHqLineOfBusiness.Work]: "Workers Compensation",
  [BindHqLineOfBusiness.Workp]: "Workers Compensation Participating",
  [BindHqLineOfBusiness.Wcma]: "Workers Comp Marine",
  [BindHqLineOfBusiness.Workv]: "Workplace Violence",
  [BindHqLineOfBusiness.Yacht]: "Yacht",
};

// This map is necessary because the type of Client.legalEntityType in graphql type is a string
// This is because some Client legalEntityType values are not valid BindHQ legalEntityType values
// So we need to map the string values to the BindHQ legalEntityType values until we can specify
// the graphql type to be type BindHqInsuredType once all DB values are updated
export const BindHqLegalEntityMap: Record<string, BindHqInsuredType> = {
  corporation: BindHqInsuredType.Corporation,
  individual: BindHqInsuredType.Individual,
  individuals: BindHqInsuredType.Individuals,
  joint_venture: BindHqInsuredType.JointVenture,
  limited_liability_company: BindHqInsuredType.LimitedLiabilityCompany,
  Limited_corporation: BindHqInsuredType.LimitedCorporation,
  limited_partnership: BindHqInsuredType.LimitedPartnership,
  non_profit: BindHqInsuredType.NonProfit,
  partnership: BindHqInsuredType.Partnership,
  proprietorship: BindHqInsuredType.Proprietorship,
  sole_proprietor: BindHqInsuredType.SoleProprietor,
  subchapter_s_corporation: BindHqInsuredType.SubchapterSCorporation,
  trust: BindHqInsuredType.Trust,
  other: BindHqInsuredType.Other,
};

export const QwLobToBindHqLob: Record<string, BindHqLineOfBusiness | undefined> = {
  "Stock Throughput": BindHqLineOfBusiness.Stkth,
  "Builder's Risk": BindHqLineOfBusiness.BuildersRisk,
  "Motor Truck Cargo": BindHqLineOfBusiness.Mtc,
  Flood: BindHqLineOfBusiness.Flood,
  "Tech E&O": BindHqLineOfBusiness.Eo,
  Property: BindHqLineOfBusiness.Cp,
  "Product Recall": BindHqLineOfBusiness.ProductRecall,
  "Contractors Pollution": BindHqLineOfBusiness.Cpl,
  "Non-Dealers Garage Liability": BindHqLineOfBusiness.Garag,
  Fiduciary: BindHqLineOfBusiness.Fiduc,
  "Excess Liability/Umbrella": BindHqLineOfBusiness.Exlia,
  "Workers Compensation": BindHqLineOfBusiness.Work,
  "General Liability": BindHqLineOfBusiness.Gl,
  "D&O": BindHqLineOfBusiness.Do,
  "Liquor Liability": BindHqLineOfBusiness.Ll,
  "Active Shooter": BindHqLineOfBusiness.ActiveShooter,
  Bailees: BindHqLineOfBusiness.Bail,
  "Storage Tank Liability": BindHqLineOfBusiness.Stpl,
  "Ocean Marine": BindHqLineOfBusiness.Comr,
  Cyber: BindHqLineOfBusiness.Cyber,
  "Inland Marine": BindHqLineOfBusiness.Inmar,
  "Contractors Equipment": BindHqLineOfBusiness.ContractorsEquipment,
  Crime: BindHqLineOfBusiness.Crime,
  "Equipment Breakdown": BindHqLineOfBusiness.Eqfltr,
  "Contingent Cargo": BindHqLineOfBusiness.ContingentCargo,
  "Garage Liability": BindHqLineOfBusiness.Garag,
  "Products Pollution": BindHqLineOfBusiness.Mpp,
  "Products Liability": BindHqLineOfBusiness.Plib,
  "E&O/Professional Liability": BindHqLineOfBusiness.Eo,
  "Installation Floaters": BindHqLineOfBusiness.InstallationFloater,
  "Hangarkeepers Liability": BindHqLineOfBusiness.Hang,
  "Commercial Auto": BindHqLineOfBusiness.Auto,
};
