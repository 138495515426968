import { uniqBy } from "lodash";
import { FormProvider, useForm } from "react-hook-form";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { Checkbox, Input } from "@/forms/default";
import { OpportunityDetailsFragment, useClientDataQuery, useCreateClientDataMutation } from "src/generated/graphql";
import { useOpportunity } from "../hooks/use-oopportunity";

interface Rule {
  type: "client-data" | "state" | "minimum-premium" | "cgl";
  valueType: "text" | "boolean" | "number";
  key: string;
}

export default function RulesForm() {
  const { openModal } = useModal();
  const { opportunity } = useOpportunity();

  if (!opportunity || !opportunity.verticalMarketingPlanTemplate) {
    return null;
  }

  const { insuredId, verticalMarketingPlanTemplate } = opportunity;

  return (
    <Button
      variant="ghost"
      display="flex"
      onClick={() => openModal(() => <Form insuredId={insuredId} template={verticalMarketingPlanTemplate} />)}
    >
      Complete carrier eligibility form to help refine market selection.
    </Button>
  );
}

function Form({
  insuredId,
  template,
}: {
  insuredId: string;
  template: NonNullable<OpportunityDetailsFragment["verticalMarketingPlanTemplate"]>;
}) {
  const { closeModal } = useModal();
  const formMethods = useForm();
  const { toast } = useToast();
  const [createClientData] = useCreateClientDataMutation({
    onCompleted: () => {
      toast({ title: "Saved" });
      closeModal();
    },
    onError: () => toast({ title: "Unable to save" }),
  });

  const rules = uniqBy(
    template.products
      .flatMap((p) => JSON.parse(p.rules) as Rule[])
      .flat()
      .filter((rule) => rule.type === "client-data"),
    (rule) => rule.key
  );

  useClientDataQuery({
    variables: {
      input: {
        insuredId,
        keys: rules.map((r) => r.key),
      },
    },
    onCompleted({ clientData }) {
      formMethods.reset(Object.fromEntries(clientData.map((cd) => [cd.key, cd.value])));
    },
  });

  const handleSubmit = async (fd: Record<string, string | number | boolean>) => {
    await createClientData({
      variables: {
        input: {
          insuredId,
          source: "CARRIER_ELIGIBILITY_FORM",
          clientDataInputs: Object.entries(fd).map(([key, value]) => {
            return {
              key,
              value,
            };
          }),
        },
      },
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <div className="space-y-4">
            <div className="space-y-2">
              <CardTitle>Carrier Eligibility</CardTitle>
              <CardDescription>Complete this form to refine market selection</CardDescription>
            </div>
            {rules.map((rule) => (
              <RuleInput key={rule.key} rule={rule} />
            ))}
            <Button className="mt-2">Submit</Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

function RuleInput({ rule }: { rule: Rule }) {
  switch (rule.valueType) {
    case "text":
      return (
        <div className="space-y-2">
          <Label className="text-sm">{rule.key}</Label>
          <Input name={rule.key} />
        </div>
      );
    case "number":
      return (
        <div className="space-y-2">
          <Label className="text-sm">{rule.key}</Label>
          <Input type="number" name={rule.key} />
        </div>
      );
    case "boolean":
      return (
        <Label className="flex gap-2 items-center text-xs">
          <Checkbox name={rule.key} />
          {rule.key}
        </Label>
      );
  }
}
