import { formatDistanceToNow, parseISO } from "date-fns";
import { z } from "zod";

import { ActionExecutionOperation, ActionsQuery } from "src/generated/graphql";

const cancelledReasonSchema = z.object({
  cancelledReason: z.string(),
});

export const ExecutedFollowUp = ({ action }: { action: ActionsQuery["actions"][number] }) => {
  const cancelledReason = cancelledReasonSchema.safeParse(JSON.parse(action.metadata || ""));

  const getExecutedDisplayText =
    action.executedOperation === ActionExecutionOperation.Approved
      ? cancelledReason.success
        ? `Cancelled (${cancelledReason.data.cancelledReason})`
        : "Sent"
      : "Cancelled";

  return (
    <div>
      <span className="font-medium">{getExecutedDisplayText}</span>{" "}
      <span className="text-muted-foreground">
        {formatDistanceToNow(parseISO(action.executedAt), { addSuffix: true })}
      </span>
    </div>
  );
};
