import { useParams } from "react-router";

import { ContainerHeaderCell } from "@/components/container";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { useInsuredQuery } from "src/generated/graphql";
import { Classification } from "./classification";
import { SelectAgent } from "./select-agent";
import { Summary } from "./summary";

export const InsuredDetails = () => {
  const { insuredId } = useParams();

  const { data: { insured } = {} } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  if (!insured) {
    return null;
  }

  const { agent } = insured;

  return (
    <>
      <ScrollPaneColumns className="grid-cols-2">
        <Summary insured={insured} />
        <div className="space-y-6">
          <Classification insured={insured} />
          <SelectAgent insured={insured}>
            <ContainerHeaderCell title={`${agent.firstName} ${agent.lastName}`} subtitle={agent.agency.name} />
          </SelectAgent>
        </div>
      </ScrollPaneColumns>
    </>
  );
};
