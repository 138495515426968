import { FileButton } from "@/components/file-button";
import { HasInternalRole } from "@/components/has-role";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { QuoteFragment } from "src/generated/graphql";

export const QuoteFiles = ({ quote }: { quote: QuoteFragment }) => {
  const { redactedQuote, processedQuote } = quote;
  return (
    <Card>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <HasInternalRole>{redactedQuote && <FileButton file={redactedQuote} badge="Redacted" />}</HasInternalRole>
        {processedQuote && <FileButton file={processedQuote} badge="Processed" />}
      </CardContent>
    </Card>
  );
};
