import { addDays } from "date-fns";
import { useState } from "react";

import { SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { ActionsQuery, useCreateActionMutation } from "src/generated/graphql";
import { cn } from "src/utils";
import { ExecutedFollowUp } from "./executed-follow-up";
import { ScheduledFollowUp } from "./scheduled-follow-up";

interface FollowUpsTableProps {
  actions: ActionsQuery["actions"];
  opportunityId: string;
}

export const FollowUpsTable = ({ actions, opportunityId }: FollowUpsTableProps) => {
  const [help, setHelp] = useState(false);

  const [createAction] = useCreateActionMutation({
    variables: {
      input: {
        opportunityId,
        actionType: actions[0].actionType,
        title: actions[0].title,
        description: actions[0].description,
        // Get the greater of [tomorrow, or the Action with the latest autoExecuteAt]
        autoExecuteAt: new Date(
          Math.max(
            addDays(Date.now(), 1).getTime(),
            addDays(new Date(actions[actions.length - 1].autoExecuteAt), 1).getTime()
          )
        ),
      },
    },
    refetchQueries: ["Actions"],
  });

  return (
    <Card>
      <SectionHeader className="pb-0">
        <SectionTitle>
          <h3 className="truncate">{actions[0].title}</h3>
          <div className="flex gap-2 sitems-center">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setHelp((help) => !help)}
              className={cn(help && "filled")}
            >
              <Icon icon="help" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => createAction({})}>
              <Icon icon="add" />
            </Button>
          </div>
        </SectionTitle>
        {help && <aside className="text-muted-foreground text-2xs">{actions[0].description}</aside>}
      </SectionHeader>
      <SectionContent className="space-y-4 text-xs">
        {actions.length === 0 && <h4>No actions yet to approve</h4>}
        {actions.map((action) =>
          action.executedAt ? (
            <ExecutedFollowUp key={action.id} action={action} />
          ) : (
            <ScheduledFollowUp key={action.id} action={action} />
          )
        )}
      </SectionContent>
    </Card>
  );
};
