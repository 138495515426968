import { useState } from "react";
import { useDocumentTitle } from "usehooks-ts";

import { SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useToast } from "@/components/ui/use-toast";
import { GmailFilterFragment, useDeleteGmailFilterMutation, useGmailFiltersQuery } from "src/generated/graphql";
import { GmailFilterForm } from "./gmail-filter-form";

export function GmailFilters() {
  const { data, loading } = useGmailFiltersQuery();
  const [addMode, setAddMode] = useState(false);

  const filters = data?.gmailFilters ?? [];

  useDocumentTitle("Admin: Gmail Filters");

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Gmail Filters</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button variant="outline" size="sm" onClick={() => setAddMode(true)}>
              Add Filter
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      <SectionContent className="grid grid-cols-4 gap-4">
        {filters.map((filter) => (
          <GmailFilterCard key={filter.id} filter={filter} />
        ))}
        {addMode && (
          <div className="border rounded p-2">
            <GmailFilterForm onCompleted={() => setAddMode(false)} />
          </div>
        )}
      </SectionContent>
    </>
  );
}

interface Props {
  filter: GmailFilterFragment;
}

const GmailFilterCard: React.FC<Props> = ({ filter }) => {
  const { id, to, from, subject, query, negatedQuery, hasAttachment } = filter;
  const { toast } = useToast();

  const [editMode, setEditMode] = useState(false);

  const [deleteGmailFilter] = useDeleteGmailFilterMutation();

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const handleDelete = () => {
    void deleteGmailFilter({
      variables: {
        id,
      },
      onCompleted: () => {
        toast({ title: "Success" });
      },
      refetchQueries: ["GmailFilters"],
    });
  };

  return (
    <div className="bg-background border p-2 rounded text-sm">
      {editMode ? (
        <GmailFilterForm gmailFilter={filter} onCompleted={() => setEditMode(false)} />
      ) : (
        <div className="flex m-2">
          <div className="flex-1">
            <p>{`To:  ${to ?? ""}`}</p>
            <p>{`From: ${from ?? ""}`}</p>
            <p>{`Subject: ${subject ?? ""}`}</p>
            <p>{`Query: ${query ?? ""}`}</p>
            <p>{`Negated Query: ${negatedQuery ?? ""}`}</p>
            <p>{`Has Attachment: ${hasAttachment ?? ""}`}</p>
          </div>
          <div className="flex flex-col gap-3">
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="icon" onClick={toggleEdit}>
                  <Icon icon="edit" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Edit Filter</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="icon" onClick={handleDelete}>
                  <Icon icon="delete" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Delete Filter</TooltipContent>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
