import { HasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { SubmissionFragment } from "src/generated/graphql";
import { SubmissionQuotes } from "./submission-quotes";
import { UploadQuote } from "./upload-quote";

export const Quoted = ({ submission, refetch }: { submission: SubmissionFragment; refetch: () => void }) => (
  <>
    <SubmissionQuotes submission={submission} />
    <HasInternalRole>
      <UploadQuote submission={submission} refetch={refetch} />
      <Button variant="ghost" size="icon" disabled>
        <Icon icon="close" />
      </Button>
    </HasInternalRole>
  </>
);
