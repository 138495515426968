import { useParams } from "react-router-dom";

import { EmptyState } from "@/components/empty-state";
import { Section, SectionContent, SectionHeader } from "@/components/section";
import { CollapsibleList, CollapsibleListContent, CollapsibleListTrigger } from "@/components/ui/collapsible-list";
import { Loading } from "@/components/ui/loading";
import { useGmailThreadsQuery } from "src/generated/graphql";

export const InsuredEmail = () => {
  const { insuredId } = useParams();

  const { data: { gmailThreads = [] } = {}, loading } = useGmailThreadsQuery({
    variables: {
      input: {
        insuredId,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !insuredId,
  });

  if (loading) {
    return <Loading className="m-6" />;
  }

  return (
    <>
      {gmailThreads[0] ? (
        gmailThreads.map((thread) => (
          <CollapsibleList key={thread.id} className="bg-muted">
            <CollapsibleListTrigger>{thread.gmailMessages[0].subject}</CollapsibleListTrigger>
            <CollapsibleListContent className="bg-muted p-2 space-y-2">
              {thread.gmailMessages.map((message) => (
                <Section key={message.id} className="bg-background rounded-sm">
                  <SectionHeader className="p-4 space-y-1.5 text-xs">
                    <div className="flex gap-4">
                      <span className="flex-none font-semibold w-14">From</span>
                      <span>{message.from}</span>
                    </div>
                    <div className="flex gap-4">
                      <span className="flex-none font-semibold w-14">To</span>
                      <span>{message.to}</span>
                    </div>
                    <div className="flex gap-4">
                      <span className="flex-none font-semibold w-14">Subject</span>
                      <span className="truncate">{message.subject}</span>
                    </div>
                  </SectionHeader>
                  <SectionContent dangerouslySetInnerHTML={{ __html: message.html }} className="border-t p-4 text-sm" />
                </Section>
              ))}
            </CollapsibleListContent>
          </CollapsibleList>
        ))
      ) : (
        <EmptyState loading={loading} title={<h2>No email threads found.</h2>} />
      )}
    </>
  );
};
