import { coerceBoolean } from "@cp/toolkit";
import {
  MarketingPlanState,
  OpportunitiesFilterInput,
  OpportunitiesSortBy,
  OpportunityGroupBy,
} from "src/generated/graphql";

export type ValidSearchKey = keyof Omit<OpportunitiesFilterInput, "ids"> | "page" | "groupBy";

export const adaptSearchParams = (
  params: URLSearchParams
): OpportunitiesFilterInput & { page: number; groupBy?: OpportunityGroupBy } => {
  const getParam = (key: ValidSearchKey) => params.get(key) ?? undefined;

  return {
    states: getParam("states")
      ? (getParam("states")!.split(",") as MarketingPlanState[])
      : getParam("term") // Update this
      ? undefined
      : [MarketingPlanState.Marketing],
    agencyId: getParam("agencyId"),
    agentId: getParam("agentId"),
    term: getParam("term"),
    isTest: coerceBoolean(getParam("isTest")),
    hoursSinceLastActivity: getParam("hoursSinceLastActivity")
      ? Number.parseInt(getParam("hoursSinceLastActivity")!)
      : undefined,
    effectiveDate: getParam("effectiveDate") ? Number.parseInt(getParam("effectiveDate")!) : undefined,
    dateRange: getParam("dateRange") ? Number.parseInt(getParam("dateRange")!) : undefined,
    sortBy: getParam("sortBy") as OpportunitiesSortBy,
    brokerId: getParam("brokerId") === "null" ? null : getParam("brokerId") || undefined,
    submissionCoreLines: getParam("submissionCoreLines") ? getParam("submissionCoreLines")!.split(",") : undefined,
    page: Number(getParam("page")) || 1,
    groupBy: (getParam("groupBy") as OpportunityGroupBy) ?? undefined,
  };
};
