import { useParams } from "react-router-dom";

import { HasRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Roles, useTestAlbyMutation } from "src/generated/graphql";

export const TestAlby = () => {
  const { insuredId, opportunityId } = useParams();
  const { openModal } = useModal();

  const [testAlby, { loading }] = useTestAlbyMutation({
    variables: {
      input: {
        insuredId: insuredId!,
        opportunityId: opportunityId!,
      },
    },
  });

  return (
    <HasRole roles={[Roles.Admin]}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon-sm"
            className="border-0 gradient-fuchs rounded-full shadow-contrast text-xs"
            onClick={async () => {
              const res = await testAlby();
              const messages = JSON.parse(res.data?.testAlby ?? "{}").messages ?? [];
              void openModal(() => (
                <div>
                  {messages.map((msg: any) => (
                    <>
                      <pre className="whitespace-pre-wrap w-full">{msg.kwargs.content}</pre>
                      <Badge>{msg.kwargs.tool_calls?.[0]?.name ?? "no tools call"}</Badge>
                    </>
                  ))}
                </div>
              ));
            }}
            disabled={loading}
          >
            {loading ? <Spinner /> : <Icon icon="smart_toy" />}
          </Button>
        </TooltipTrigger>
        <TooltipContent>Test Alby</TooltipContent>
      </Tooltip>
    </HasRole>
  );
};
