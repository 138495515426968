import { SnoozeButton } from "@/components/snooze-button";
import { STATE_METADATA } from "@/metadata";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";
import { BankanScore } from "./bankan-score";

type Group = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({ group }: { group: Group }) => {
  const { insuredId, insured, marketingPlans } = group;
  return (
    <BankanCard insured={insured}>
      {marketingPlans.map((plan) => {
        const { id, desiredEffectiveDate, selectedLinesOfBusiness, state, lastTransitionAt, score, snoozeUntil } = plan;
        const { cardClassName } = STATE_METADATA[state];
        return (
          <div key={id} className="group relative">
            <SnoozeButton opportunityId={id} desiredEffectiveDate={desiredEffectiveDate} snoozeUntil={snoozeUntil} />
            <BankanLink to={`/insured/${insuredId}/plans/${id}`} className={cardClassName}>
              <BankanLinkText className="font-semibold flex justify-between items-center">
                <div className="space-y-1">
                  {selectedLinesOfBusiness[0]
                    ? selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                    : "No Coverage Selected"}
                </div>
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="mr-auto">{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</div>
                <BankanScore bankanScore={score} lastTransitionAt={lastTransitionAt} />
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
