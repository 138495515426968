import { useNavigate, useParams } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Section, SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { Switch } from "@/components/ui/switch";
import {
  AppetiteNoteFragment,
  ClassCodeFragment,
  useAppetiteNotesQuery,
  useUpdateVerticalMutation,
  useVerticalQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";
import { EditVerticalMarketingPlan } from "./edit-vertical-marketing-plan";
import { DeleteVertical } from "./verticals";

export function Vertical() {
  useDocumentTitle("Admin: Vertical");
  const { verticalId } = useParams<{ verticalId: string | undefined }>();
  const navigate = useNavigate();
  const {
    data: { vertical } = {},
    refetch,
    loading,
  } = useVerticalQuery({
    variables: { id: verticalId! },
    skip: !verticalId,
  });
  const { data: { appetiteNotes = [] } = {} } = useAppetiteNotesQuery({
    variables: { input: { isoCglCodes: vertical?.isoCglCodes.map((cgl) => cgl.classCode) } },
    skip: !vertical || vertical.isoCglCodes.length === 0,
  });
  const [update] = useUpdateVerticalMutation({
    onCompleted() {
      void refetch();
    },
  });

  const displayCgl = (cgl: ClassCodeFragment) => {
    return `${cgl.classCode} ${cgl.description}`;
  };

  if (loading) {
    return <Loading />;
  }

  if (!vertical) {
    return null;
  }

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionTitle>
            <h1>{vertical.name}</h1>
            <div className="flex flex-row gap items-center">
              <Button variant="ghost" size="icon" onClick={() => navigate("edit")}>
                <Icon icon="edit" />
              </Button>
              <DeleteVertical id={vertical.id} />
              <Switch
                checked={vertical.enabled}
                onCheckedChange={(enabled) =>
                  update({ variables: { input: { id: verticalId!, name: vertical.name, enabled } } })
                }
              />
            </div>
          </SectionTitle>
          <SectionDescription>{vertical.description}</SectionDescription>
          <Pills items={vertical.isoCglCodes.map((cgl) => displayCgl(cgl))} />
        </SectionHeader>
        <EditVerticalMarketingPlan vertical={vertical} />
        <AppetiteNotesTable appetiteNotes={appetiteNotes} verticalCgls={vertical.isoCglCodes.map((c) => c.classCode)} />
      </Section>
    </>
  );
}

const AppetiteNotesTable: React.FC<{ appetiteNotes: AppetiteNoteFragment[]; verticalCgls: string[] }> = ({
  appetiteNotes,
  verticalCgls,
}) => {
  return (
    <>
      <SectionContent>
        <SectionTitle>
          <h3>Appetite Notes</h3>
        </SectionTitle>
      </SectionContent>
      <Grid className="grid-cols-4">
        <GridRowHeader>
          <GridCell>Note</GridCell>
          <GridCell>CGL(s)</GridCell>
          <GridCell>State(s)</GridCell>
          <GridCell>Line(s) Of Business</GridCell>
        </GridRowHeader>
        {appetiteNotes.map((note) => (
          <GridRow key={note.id}>
            <GridCell>{note.note}</GridCell>
            <Pills items={note.isoCglCodes} matches={verticalCgls} />
            <Pills items={note.states} />
            <Pills items={note.lobs} />
          </GridRow>
        ))}
      </Grid>
    </>
  );
};

export const Pills: React.FC<{ items: string[]; matches?: string[] }> = ({ items, matches }) => {
  const matched = new Set(matches);

  return (
    <div className="flex flex-row flex-wrap gap-1">
      {items.map((item) => (
        <Badge
          variant="outline"
          className={cn(matched.size > 0 && matched.has(item) ? "bg-slate-500 text-white" : "bg-slate-300")}
          key={item}
        >
          {item}
        </Badge>
      ))}
    </div>
  );
};
