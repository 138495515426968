import { Button } from "@/components/ui/button";
import { MarketingPlanStateInformationReviewActions, useTransitionMarketingPlanMutation } from "src/generated/graphql";
import { MarketingPlan } from "../marketing-plan-details";

export const InformationReview = ({ marketingPlan }: { marketingPlan: MarketingPlan }) => {
  const [transition] = useTransitionMarketingPlanMutation();

  if (!marketingPlan) {
    return null;
  }

  const handleTransition = async (action: MarketingPlanStateInformationReviewActions) => {
    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <Button
      onClick={() => handleTransition(MarketingPlanStateInformationReviewActions.StartMarketing)}
      variant="outline"
      size="sm"
    >
      Start Marketing
    </Button>
  );
};
