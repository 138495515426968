import { ReactFlowProvider } from "@xyflow/react";
import { Outlet } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { HasRole } from "@/components/has-role";
import { ScrollPane } from "@/components/scroll-pane";
import { Roles } from "src/generated/graphql";
import { FileProcessingPipelineGraphContainer } from "./file-processing-pipeline-graph/file-processing-pipeline-graph";

export const FileProcessingPipeline = () => {
  useDocumentTitle("File Processing Pipeline: QuoteWell");
  return (
    <HasRole roles={[Roles.Admin]}>
      <ScrollPane className="flex-row">
        <ReactFlowProvider>
          <div className="flex flex-auto items-center justify-center h-full w-full">
            <FileProcessingPipelineGraphContainer />
          </div>
          <Outlet />
        </ReactFlowProvider>
      </ScrollPane>
    </HasRole>
  );
};
