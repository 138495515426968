import { useDocumentTitle } from "usehooks-ts";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { useAgenciesWithOwnerQuery, useUpdateAgencyOwnerMutation } from "src/generated/graphql";
import { parseError } from "src/utils";
import { SelectBroker } from "./select-broker";

export function AgencyOwnership() {
  const { data: { agencies } = { agencies: [] }, loading, refetch } = useAgenciesWithOwnerQuery();

  const [updateAgencyOwner] = useUpdateAgencyOwnerMutation({
    onCompleted: async (update) => {
      await refetch();
      toast({ title: `Updated ${update.updateAgency.name} Owner` });
    },
    onError: (error) => {
      toast({ title: "Error", description: parseError(error), variant: "destructive" });
    },
  });
  useDocumentTitle("Admin: Agency Ownership");

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Agency Ownership</h1>
          {loading && <Spinner />}
        </SectionTitle>
      </SectionHeader>
      <Grid className="grid-cols-2">
        <GridRowHeader>
          <GridCell>Agency</GridCell>
          <GridCell>Owner</GridCell>
        </GridRowHeader>
        {agencies[0] ? (
          agencies.map((agency) => (
            <GridRow key={agency.id}>
              <GridCell className="flex gap-2">
                {agency.name}
                {agency.isTest && <Badge>Test</Badge>}
              </GridCell>
              <SelectBroker
                brokerId={agency.owner?.id}
                onSelect={(ownerId) => {
                  void updateAgencyOwner({
                    variables: { input: { agencyId: agency.id, ownerId: ownerId == "-" ? null : ownerId } },
                  });
                }}
              />
            </GridRow>
          ))
        ) : (
          <EmptyState loading={loading} title={<h2>No agencies found.</h2>} />
        )}
      </Grid>
    </>
  );
}
