import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "src/utils";

const badgeVariants = cva(
  "inline-flex items-center text-center rounded-full h-[1.875em] px-[0.875em] text-2xs font-semibold text-nowrap transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-foreground text-background",
        secondary: "bg-secondary text-secondary-foreground",
        destructive: "bg-destructive text-destructive-foreground",
        outline: "border text-foreground",
        green: "bg-green-500 text-primary-foreground",
        pink: "bg-pink-500 text-primary-foreground",
        violet: "bg-violet-500 text-primary-foreground",
        amber: "bg-amber-500 text-primary-foreground",
        orange: "bg-orange-500 text-primary-foreground",
        lime: "bg-lime-500 text-primary-foreground",
        teal: "bg-teal-500 text-primary-foreground",
        cyan: "bg-cyan-500 text-primary-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
