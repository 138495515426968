import { createBrowserRouter, Link, Navigate, Outlet, redirect, RouteObject } from "react-router-dom";

import { Roles } from "src/generated/graphql";
import { getAuthedUser } from "src/utils/get-authed-user";
import { Admin } from "./admin/admin";
import { AgencyOwnership } from "./admin/agency-ownership/agency-ownership";
import { AppetiteNote } from "./admin/appetite-notes/appetite-note";
import { AppetiteNotes } from "./admin/appetite-notes/appetite-notes";
import { Appetite } from "./admin/appetite/appetite";
import { Enablement } from "./admin/enablement/enablement";
import { GmailFilters } from "./admin/gmail-filters/gmail-filters";
import { ImpersonateUser } from "./admin/impersonate-user/impersonate-user";
import { Tags } from "./admin/tags";
import { EditVertical } from "./admin/verticals/edit-vertical";
import { Vertical } from "./admin/verticals/vertical";
import { Verticals } from "./admin/verticals/verticals";
import { ActivateAccount, activateAccountLoader } from "./auth/activate-account";
import { AuthGuard } from "./auth/auth-guard";
import { ForgotPassword } from "./auth/forgot-password";
import { Login } from "./auth/login";
import { Bankan } from "./bankan/bankan";
import { Broker } from "./broker/broker";
import { BrokerActive } from "./broker/broker-active";
import { BrokerPinned } from "./broker/broker-pinned";
import { HasInternalRole } from "./components/has-role";
import { Centered, Page } from "./components/layout";
import { useTheme } from "./components/theme-provider";
import { Icon } from "./components/ui/icon";
import { ErrorPage } from "./error";
import { DocumentLabelDetailsSidePanel } from "./file-processing-pipeline/document-label-details/document-label-details";
import { FileProcessingPipeline } from "./file-processing-pipeline/file-processing-pipeline";
import { FileProcessorDetailsSidePanel } from "./file-processing-pipeline/file-processor-details/file-processor-details";
import { Files } from "./files/files";
import { InsuredDetails } from "./insured/details/details";
import { InsuredDetailsMenu } from "./insured/details/details-menu";
import { Insured } from "./insured/insured";
import { InsuredEmail } from "./insured/insured-email";
import { InsuredRedirect } from "./insured/insured-redirect";
import { insuredLoader, InsuredRoot } from "./insured/insured-root";
import { Insureds } from "./insureds/insureds";
import { InsuredsActive } from "./insureds/insureds-active";
import { InsuredsAll } from "./insureds/insureds-all";
import { Coverage } from "./marketing-plans/coverage";
import { EscapeHatch } from "./marketing-plans/escape-hatch";
import { History } from "./marketing-plans/history";
import { MarketingPlan } from "./marketing-plans/marketing-plan";
import { MarketingPlanDetails } from "./marketing-plans/marketing-plan-details";
import { MarketingPlanMenu } from "./marketing-plans/marketing-plan-menu";
import { MarketingPlans } from "./marketing-plans/marketing-plans";
import { SelectMarkets } from "./marketing-plans/select-markets/select-markets";
import { New } from "./new/new";
import { PageNotFound } from "./page-not-found";
import { Policies } from "./policies/policies";
import { Policy } from "./policies/policy";
import { PolicyMenu } from "./policies/policy-menu";
import { FirstSubmissionPage } from "./public/first-submission-page";
import { Bind } from "./quotes/bind/bind";
import { Quote } from "./quotes/quote";
import { QuoteMenu } from "./quotes/quote-menu";
import { Quotes } from "./quotes/quotes";
import { RequirementForm } from "./requirements/requirement-form";
import { Supplementals } from "./supplementals/supplementals";

const Beef = () => {
  const { setTheme } = useTheme();
  setTheme("beef");
  return <Navigate to="/" />;
};

export const roleHomepageLoader = async () => {
  const { user } = (await getAuthedUser()) || {};
  let path = "/login";

  if (user) {
    if (user && user.roles.includes(Roles.Broker)) {
      path = `/bankan/${user.id}`;
    } else if (user.roles.includes(Roles.Admin)) {
      path = "/bankan";
    } else {
      path = "/insureds";
    }
  }

  return redirect(path);
};

const internalRoutes: RouteObject[] = [
  {
    element: (
      <HasInternalRole elseShow={<PageNotFound />}>
        <Outlet />
      </HasInternalRole>
    ),
    children: [
      {
        path: "admin",
        element: <Admin />,
        children: [
          {
            path: "appetite",
            element: <Appetite />,
          },
          {
            path: "appetite-notes",
            children: [
              {
                index: true,
                element: <AppetiteNotes />,
              },
              {
                path: "create",
                element: <AppetiteNote mode="create" />,
              },
              {
                path: ":appetiteNoteId/edit",
                element: <AppetiteNote mode="edit" />,
              },
            ],
          },
          {
            path: "agency-ownership",
            element: <AgencyOwnership />,
          },
          {
            path: "enablement",
            element: <Enablement />,
          },
          {
            path: "gmail-filters",
            element: <GmailFilters />,
          },
          {
            path: "verticals",
            children: [
              {
                index: true,
                element: <Verticals />,
              },
              {
                path: "create",
                element: <EditVertical />,
              },
              {
                path: ":verticalId",
                element: <Vertical />,
              },
              {
                path: ":verticalId/edit",
                element: <EditVertical />,
              },
            ],
          },
          {
            path: "tags",
            element: <Tags />,
          },
          {
            path: "impersonate-user",
            element: <ImpersonateUser />,
          },
        ],
      },
      {
        path: "file-processing-pipeline",
        element: <FileProcessingPipeline />,
        children: [
          {
            path: "file-processor/:fileProcessorId",
            element: <FileProcessorDetailsSidePanel />,
          },
          {
            path: "document-label/:documentLabelId",
            element: <DocumentLabelDetailsSidePanel />,
          },
        ],
      },
      {
        path: "bankan/:brokerId?",
        element: <Bankan />,
      },
      {
        path: "broker",
        element: <Broker />,
        children: [
          {
            index: true,
            element: <BrokerPinned />,
          },
          {
            path: "active",
            element: <BrokerActive />,
          },
        ],
      },
    ],
  },
];

const HomeLink = () => (
  <Link to="/" className="filled flex items-center text-xs">
    <Icon icon="home" />
  </Link>
);

const authProtectedRoutes: RouteObject[] = [
  {
    path: "insureds/:agencyId?",
    element: <Insureds />,
    children: [
      {
        index: true,
        element: <InsuredsActive />,
      },
      {
        path: "all",
        element: <InsuredsAll />,
      },
    ],
  },
  {
    path: "insured/new",
    element: <New />,
  },
  {
    path: "insured",
    handle: {
      crumb: () => <HomeLink />,
    },
    children: [
      {
        id: "insuredRoot",
        path: ":insuredId",
        element: <InsuredRoot />,
        loader: insuredLoader,
        handle: {
          crumb: (insured: Awaited<ReturnType<typeof insuredLoader>>) => (
            <Link to={`/insured/${insured?.id}`}>{insured?.name}</Link>
          ),
        },
        children: [
          {
            element: <Insured />,
            children: [
              {
                index: true,
                element: <InsuredDetails />,
                handle: {
                  menu: () => <InsuredDetailsMenu />,
                },
              },
              {
                path: "email",
                element: <InsuredEmail />,
                handle: {
                  crumb: () => <span>Email Threads</span>,
                },
              },
            ],
          },
          {
            path: "files",
            element: <Files />,
            handle: {
              crumb: () => <span>Files</span>,
            },
          },
          {
            path: ":opportunityId/*",
            loader: async ({ params }) =>
              redirect(`/insured/${params.insuredId}/plans/${params.opportunityId}/${params["*"]}`),
          },
          {
            path: "plans",
            handle: {
              crumb: () => <Link to="plans">Marketing Plans</Link>,
            },
            children: [
              { index: true, element: <MarketingPlans /> },
              {
                path: ":opportunityId",
                element: <MarketingPlan />,
                handle: {
                  // TODO: marketing plan crumb
                  menu: () => <MarketingPlanMenu />,
                },
                children: [
                  {
                    index: true,
                    element: <MarketingPlanDetails />,
                  },
                  {
                    path: "coverage",
                    element: <Coverage />,
                  },
                  {
                    path: "escape-hatch",
                    element: <EscapeHatch />,
                  },
                  {
                    path: "files",
                    element: <Files />,
                  },
                  {
                    path: "history",
                    element: <History />,
                  },
                  {
                    path: "markets",
                    element: <SelectMarkets />,
                  },
                  {
                    path: "requirements/:requirementId",
                    element: <RequirementForm />,
                  },
                ],
              },
            ],
          },
          {
            path: "quotes",
            handle: {
              crumb: () => <Link to="quotes">Quotes</Link>,
            },
            children: [
              {
                index: true,
                element: <Quotes />,
              },
              {
                path: ":quoteId",
                element: <Quote />,
                handle: {
                  // TODO: quote crumb
                  menu: () => <QuoteMenu />,
                },
              },
              {
                path: ":quoteId/bind",
                element: <Bind />,
              },
            ],
          },
          {
            path: "policies",
            handle: {
              crumb: () => <Link to="policies">Policies</Link>,
            },
            children: [
              {
                index: true,
                element: <Policies />,
              },
              {
                path: ":policyId",
                element: <Policy />,
                handle: {
                  // TODO: policy crumb
                  menu: () => <PolicyMenu />,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // Note: without a clientId, the form won't save
    path: "supplementals/:clientId?",
    element: <Supplementals />,
  },
  { index: true, loader: roleHomepageLoader, element: <div /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Page>
        <Outlet />
      </Page>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        element: (
          <AuthGuard>
            <Outlet />
          </AuthGuard>
        ),
        children: [...authProtectedRoutes, ...internalRoutes],
      },
    ],
  },
  {
    path: "first-submission",
    element: <FirstSubmissionPage />,
  },
  {
    element: <Centered />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
  {
    path: "agency",
    loader: () => redirect("/insureds"),
  },
  {
    path: "appetite/*",
    loader: () => redirect("/new"),
  },
  {
    path: "opportunity/:opportunityId",
    element: <InsuredRedirect />,
  },
  {
    path: "beef",
    element: <Beef />,
  },
  {
    path: "*",
    element: (
      <Page>
        <PageNotFound />
      </Page>
    ),
  },
]);
