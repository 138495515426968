import { FileButton } from "@/components/file-button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PolicyFragment, PolicyState } from "src/generated/graphql";

export const PolicyFiles = ({ policy }: { policy: PolicyFragment }) => {
  const { policyFile, binderFile, state } = policy;

  if (state !== PolicyState.Issued) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Files</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        {policyFile && <FileButton file={policyFile} />}
        {binderFile && <FileButton file={binderFile} />}
        {!binderFile && !policyFile && <span className="italic text-muted-foreground text-sm">No files found.</span>}
      </CardContent>
    </Card>
  );
};
