import { Link } from "react-router-dom";

import { hasInternalRole } from "@/components/has-role";
import { Button } from "@/components/ui/button";
import { useMyAccount } from "@/hooks/use-my-account";
import { sentToAgent } from "@/insured/sidebar/sidebar-quotes";
import { StateIndicator } from "@/metadata";
import { QuoteState, SubmissionFragment } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";

export const SubmissionQuotes = ({ submission }: { submission: SubmissionFragment }) => {
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const { quotes } = submission;

  const validQuotes = hasInternalRole(user)
    ? quotes.filter((quote) => quote.state !== QuoteState.Invalid)
    : quotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      {validQuotes.map((quote) => (
        <div key={quote.id}>
          <Button variant="outline" size="sm" className="block h-auto items-center px-2.5 py-1.5 space-y-0.5" asChild>
            <Link to={`/insured/${quote.insuredId}/quotes/${quote.id}`}>
              <div className="font-semibold text-[11px]">
                {quote.premium ? formatDollars(quote.premium) : "Unprocessed"}
              </div>
              <StateIndicator state={quote.state} className="text-3xs" />
            </Link>
          </Button>
        </div>
      ))}
    </>
  );
};
