import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";

import { Icon, type IconString } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { STATE_METADATA } from "@/metadata";
import { SlimAppetiteProductFragmentFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { StateTransition } from "./event-renderer";

interface StateTransitionEventProps extends Omit<StateTransition, "__typename"> {
  icon: IconString;
  transitionType: string;
  appetiteProduct?: SlimAppetiteProductFragmentFragment;
}

export const StateTransitionEvent: React.FC<StateTransitionEventProps> = ({
  createdAt,
  icon,
  previousState,
  state,
  transitionType,
  appetiteProduct,
}) => {
  const carrierInfo = appetiteProduct
    ? `**${appetiteProduct.carrierName} - ${appetiteProduct.carrierProductName}** `
    : "";
  const text = `${carrierInfo}${transitionType} state changed from **${STATE_METADATA[previousState].label}** to **${STATE_METADATA[state].label}**`;

  return (
    <div className="bg-accent flex gap-4 items-start p-4 text-sm w-full">
      <Icon icon={icon} className="outlined bg-accent h-7 -my-1.5 text-muted-foreground w-6" />
      <div className="flex-auto [&>p]:inline text-xs">
        <ReactMarkdown
          remarkPlugins={[remarkBreaks, remarkGfm]}
          components={{
            ul: (props) => {
              const { className, children } = props;
              return <ul className={cn("list-disc list-inside", className)}>{children}</ul>;
            },
          }}
        >
          {text}
        </ReactMarkdown>
        <RelativeDate date={createdAt} className="ml-1 text-muted-foreground" />
      </div>
    </div>
  );
};
