import { useAtom } from "jotai";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { authDataAtom, authTokenValidatedAtom } from "../atoms/atoms";
import { useAuthContext } from "../context/AuthContext";
import { ImpersonateUserDocument, ImpersonateUserMutation } from "../generated/operations";
import { userAccountQueryKeys } from "../utils/queryKeys";
import { GraphQLAuthError, GraphQLErrorResponse } from "./authError";

interface ImpersonateUserArgs {
  userIdToImpersonate: string;
}

type TData = ImpersonateUserMutation["impersonateUser"];

export default function useImpersonateUser(
  options: UseMutationOptions<TData, GraphQLErrorResponse<GraphQLAuthError>, ImpersonateUserArgs> = {}
) {
  const queryClient = useQueryClient();
  const [authData, setAuthData] = useAtom(authDataAtom);
  const [, setTokenValidated] = useAtom(authTokenValidatedAtom);
  const { authClientId, fetcher } = useAuthContext();

  return useMutation<TData, GraphQLErrorResponse<GraphQLAuthError>, ImpersonateUserArgs>(
    (args: ImpersonateUserArgs) => {
      return fetcher(
        ImpersonateUserDocument,
        {
          authClientId,
          ...args,
        },
        { Authorization: `Bearer ${authData?.token.accessToken}` }
      ).then((data) => data.impersonateUser);
    },
    {
      onSuccess: (data, vars, context) => {
        void options.onSuccess?.(data, vars, context);

        setAuthData({
          token: { accessToken: data.accessToken },
          shouldRemember: true,
        });
        setTokenValidated(true);

        // Invalidate queries to force refetch with new impersonated user context
        void queryClient.invalidateQueries(userAccountQueryKeys.detail("myAccount"));
        queryClient.setQueryData(userAccountQueryKeys.detail("myAccount"), data.user);
      },
    }
  );
}
