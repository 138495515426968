import React from "react";
import ReactMarkdown from "react-markdown";

import { CollapsibleCard } from "@/components/ui/collapsible-card";
import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { ExclusionCommentFragment } from "src/generated/graphql";
import { cn } from "src/utils";

export const ExclusionComment: React.FC<ExclusionCommentFragment> = ({ text, createdAt, appetiteProduct }) => {
  return (
    <CollapsibleCard>
      <header className="flex gap-4 items-center p-4 text-xs">
        <Icon icon="smart_toy" className="bg-accent filled rounded-full text-muted-foreground w-6" />
        <div className="flex-auto">
          <strong>QuoteWell AI</strong>
          {" commented "}
          <RelativeDate date={createdAt} className="text-muted-foreground" />
        </div>
      </header>
      <div className="ml-10 p-4 pt-0 space-y-2 text-xs">
        {appetiteProduct && (
          <div>
            The following Exclusions apply to: <strong>{appetiteProduct.carrierName}</strong> -{" "}
            <strong>{appetiteProduct.carrierProductName}</strong>. If any of these apply, comment below to alert your
            broker.
          </div>
        )}
        <ReactMarkdown
          components={{
            ul: (props) => {
              const { className, children } = props;

              return <ul className={cn("list-disc list-inside", className)}>{children}</ul>;
            },
          }}
        >
          {text}
        </ReactMarkdown>
      </div>
    </CollapsibleCard>
  );
};
