import { useMutation } from "react-query";
import { useAuthContext } from "../context/AuthContext";
import { ForgotPasswordDocument, ForgotPasswordMutationVariables } from "../generated/operations";
import { GraphQLAuthError, GraphQLErrorResponse } from "./authError";

type ForgotPasswordArgs = Omit<ForgotPasswordMutationVariables, "authClientId">;

export default function useForgotPassword() {
  const { authClientId, fetcher } = useAuthContext();

  return useMutation<boolean, GraphQLErrorResponse<GraphQLAuthError>, ForgotPasswordArgs>((args) => {
    return fetcher(ForgotPasswordDocument, {
      authClientId,
      ...args,
    }).then((res) => res.forgotPassword);
  });
}
