export type PossibleTypesResultData = {
  possibleTypes: {
    OpportunityEvent: [
      "ActionApprovedEvent",
      "ActionDismissedEvent",
      "ActionRejectedEvent",
      "ActionRequestedEvent",
      "ApplicationSubmittedEvent",
      "AssignmentChangeEvent",
      "AutomatedFollowUpEvent",
      "BotCommentEvent",
      "BusinessClassCodesChangedEvent",
      "CoveragesChangedEvent",
      "CreatedEvent",
      "DesiredEffectiveDateChangeEvent",
      "EmailEvent",
      "ExclusionComment",
      "FileUploadEvent",
      "GmailMessageEvent",
      "GmailRelayEvent",
      "OutcomeChangeEvent",
      "ProductsAddedEvent",
      "ProductsRemovedEvent",
      "RequirementAddedEvent",
      "StatusChangeEvent",
      "SubmissionStatusChangeEvent",
      "TriageAssignmentEvent",
      "UserComment"
    ];
    OpportunityEventBase: [
      "ActionApprovedEvent",
      "ActionDismissedEvent",
      "ActionRejectedEvent",
      "ActionRequestedEvent",
      "ApplicationSubmittedEvent",
      "AssignmentChangeEvent",
      "AutomatedFollowUpEvent",
      "BotCommentEvent",
      "BusinessClassCodesChangedEvent",
      "CoveragesChangedEvent",
      "CreatedEvent",
      "DesiredEffectiveDateChangeEvent",
      "EmailEvent",
      "ExclusionComment",
      "FileUploadEvent",
      "GmailMessageEvent",
      "GmailRelayEvent",
      "OutcomeChangeEvent",
      "ProductsAddedEvent",
      "ProductsRemovedEvent",
      "RequirementAddedEvent",
      "StatusChangeEvent",
      "SubmissionStatusChangeEvent",
      "TriageAssignmentEvent",
      "UserComment"
    ];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    OpportunityEvent: [
      "ActionApprovedEvent",
      "ActionDismissedEvent",
      "ActionRejectedEvent",
      "ActionRequestedEvent",
      "ApplicationSubmittedEvent",
      "AssignmentChangeEvent",
      "AutomatedFollowUpEvent",
      "BotCommentEvent",
      "BusinessClassCodesChangedEvent",
      "CoveragesChangedEvent",
      "CreatedEvent",
      "DesiredEffectiveDateChangeEvent",
      "EmailEvent",
      "ExclusionComment",
      "FileUploadEvent",
      "GmailMessageEvent",
      "GmailRelayEvent",
      "OutcomeChangeEvent",
      "ProductsAddedEvent",
      "ProductsRemovedEvent",
      "RequirementAddedEvent",
      "StatusChangeEvent",
      "SubmissionStatusChangeEvent",
      "TriageAssignmentEvent",
      "UserComment",
    ],
    OpportunityEventBase: [
      "ActionApprovedEvent",
      "ActionDismissedEvent",
      "ActionRejectedEvent",
      "ActionRequestedEvent",
      "ApplicationSubmittedEvent",
      "AssignmentChangeEvent",
      "AutomatedFollowUpEvent",
      "BotCommentEvent",
      "BusinessClassCodesChangedEvent",
      "CoveragesChangedEvent",
      "CreatedEvent",
      "DesiredEffectiveDateChangeEvent",
      "EmailEvent",
      "ExclusionComment",
      "FileUploadEvent",
      "GmailMessageEvent",
      "GmailRelayEvent",
      "OutcomeChangeEvent",
      "ProductsAddedEvent",
      "ProductsRemovedEvent",
      "RequirementAddedEvent",
      "StatusChangeEvent",
      "SubmissionStatusChangeEvent",
      "TriageAssignmentEvent",
      "UserComment",
    ],
  },
};
export default result;
