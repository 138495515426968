import { MarketingPlanState } from "src/generated/graphql";
import { RequirementsContextData } from "../requirements";
import { InformationGathering } from "./information-gathering";

export const RequirementsActions = ({ opportunity, refetch }: RequirementsContextData) => {
  if (!opportunity) {
    return null;
  }

  const renderMarketingPlanState = () => {
    switch (opportunity.state) {
      case MarketingPlanState.InformationGathering:
        return <InformationGathering opportunity={opportunity} refetch={refetch} />;
      default:
        return null;
    }
  };

  return renderMarketingPlanState();
};
