import * as Sentry from "@sentry/react";

/**
 * This is a simple logger that can be used to log errors and warnings to Sentry.
 * We don't log info messages to Sentry because it's expensive and we don't have use for them.
 */
export const SentryLogger = {
  warn: (message: string, extra?: Record<string, unknown>) => {
    Sentry.captureMessage(message, { level: "warning", extra });
  },
  error: (message: string, extra?: Record<string, unknown>) => {
    Sentry.captureMessage(message, { level: "error", extra });
  },
  exception: (error: Error) => {
    Sentry.captureException(error);
  },
};
