import { PdfInput, PdfMoney, PdfNumber, PdfTextarea, PdfYesNo } from "@/forms/pdf";
import { Address } from "./fieldsets/address";
import { GeneralInformation } from "./fieldsets/general-information";
import { LossHistory } from "./fieldsets/loss-history";
import { Paper } from "./fieldsets/paper";
import { Signature } from "./fieldsets/signature";

export const BuildersRisk = () => (
  <>
    <Paper>
      <section>
        <h1>Builders Risk</h1>
        <GeneralInformation />
      </section>
      <section>
        <Address header="Mailing Address" rootPath="mailing" />
      </section>
      <section>
        <Address header="Physical Address" rootPath="physical" />
      </section>
    </Paper>
    <Paper>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Limits</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfMoney label="Project deductible" name="projectDeductible" />
            </tr>
            <tr>
              <PdfMoney label="Flood limit" name="floodLimit" />
            </tr>
            <tr>
              <PdfMoney label="Transit limit" name="transit" />
            </tr>
            <tr>
              <PdfInput label="Temporary locations" name="temporaryLocations" />
            </tr>
            <tr>
              <PdfMoney label="Earthquake limit" name="earthquakeLimit" />
            </tr>
            <tr>
              <PdfMoney label="Flood deductible" name="floodDeductible" />
            </tr>
            <tr>
              <PdfMoney label="Earthquake deductible" name="earthquakeDeductible" />
            </tr>
            <tr>
              <PdfInput
                label="What is your occupancy? (for example, offices, restaurant, etc.)?"
                name="occupancyType"
              />
            </tr>
            <tr>
              <PdfYesNo label="Equipment Breakdown" name="equipmentBreakdownCoverage" />
            </tr>
            <tr>
              <PdfYesNo label="Time Element Coverage" name="timeElementCoverage" />
            </tr>
            <tr>
              <PdfMoney label="Soft Costs" name="softCosts" />
            </tr>
            <tr>
              <PdfMoney label="Rental Value" name="rentalValue" />
            </tr>
            <tr>
              <PdfInput label="Limits" name="limits" />
            </tr>
            <tr>
              <PdfMoney label="Completed project value/jobsite limit" name="completedProjectValueLimit" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <section>
        <table>
          <thead>
            <tr>
              <td className="big">Applicant Details</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <PdfInput label="Inspection Contact" name="inspectionContact" />
            </tr>
            <tr>
              <PdfInput label="Phone number" name="phoneNumber" />
            </tr>
            <tr>
              <PdfInput label="Email" name="email" />
            </tr>
            <tr>
              <PdfYesNo
                label="Has the customer filed any bankruptcies in the last five years?"
                name="bankruptciesLastFiveYears"
              />
            </tr>
            <tr>
              <PdfYesNo
                label="Has the insured or contractor had coverage cancelled or non-renewed in the past three years?"
                name="cancelledOrNonRenewedCoverage"
              />
            </tr>
            <tr>
              <PdfYesNo label="Is the contractor a licensed contractor?" name="isLicensedContractor" />
            </tr>
            <tr>
              <PdfInput label="Project Site Address" name="projectSiteAddress" type="text" />
            </tr>

            <tr>
              <PdfYesNo label="Has the project begun?" name="hasProjectBegun" />
            </tr>
            <tr>
              <PdfYesNo
                label="Is the project a renovation/addition to an existing structure?"
                name="isProjectRenovationOrAddition"
              />
            </tr>
            <tr>
              <PdfTextarea label="Project Description" name="projectDescription" />
            </tr>
            <tr>
              <PdfInput label="Type of Construction" name="typeOfConstruction" />
            </tr>
            <tr>
              <PdfNumber label="Number of Stories" name="numberOfStories" />
            </tr>
          </tbody>
        </table>
      </section>
    </Paper>
    <Paper>
      <LossHistory rootPath="buildersRisk" />
      <Signature />
    </Paper>
  </>
);
