import React from "react";

import { CollapsibleCard } from "@/components/ui/collapsible-card";
import { Icon } from "@/components/ui/icon";
import { RelativeDate } from "@/components/ui/relative-date";
import { EmailEventFragment } from "src/generated/graphql";

export const EmailEvent: React.FC<EmailEventFragment> = ({
  email: { from, dateReceived, extractedBody, linkToEmailInGoogleGroups },
}) => {
  const emailBodySegments = extractedBody.split("\n");

  return (
    <CollapsibleCard>
      <a href={`${linkToEmailInGoogleGroups}`} target="_blank" rel="noreferrer">
        <header className="flex gap-4 items-center p-4 text-xs">
          <Icon icon="mail" className="bg-accent filled rounded-full text-muted-foreground w-6" />
          <div className="flex-auto">
            <strong className="flex-auto">{from}</strong>
            {" sent an email "}
            <RelativeDate date={dateReceived} className="text-muted-foreground" />
          </div>
        </header>
        {extractedBody && (
          <div className="ml-10 p-4 pt-0 text-xs" style={{ overflowWrap: "anywhere" }}>
            {emailBodySegments.map((segment, i) => (
              <div key={i}>{segment}</div>
            ))}
          </div>
        )}
      </a>
    </CollapsibleCard>
  );
};
