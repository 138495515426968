import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { getAccessToken } from "@cp/auth";
import { createClient } from "graphql-ws";

import cacheConfig from "src/generated/cacheConfig";
import { BACKEND_API_PATH, WS_API_PATH } from "src/utils/constants";
import { invalidationLink } from "./invalidationLink";

loadDevMessages();
loadErrorMessages();

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_API_PATH,
    connectionParams: () => {
      const authToken = getAccessToken();

      if (!authToken) {
        return {};
      }

      return {
        authorization: `Bearer ${authToken}`,
      };
    },
  })
);

const apiHttpLink = createHttpLink({
  uri: BACKEND_API_PATH,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) => (key === "__typename" ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename) as Record<string, unknown>;
  }
  return forward(operation);
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  cleanTypeName.concat(invalidationLink.concat(authLink.concat(apiHttpLink)))
);

export const apolloClient = new ApolloClient({
  // eslint-disable-next-line unicorn/prefer-spread
  link: splitLink,
  queryDeduplication: true,
  cache: new InMemoryCache({
    ...cacheConfig,
  }),
  defaultOptions: {
    query: {
      // Default to not using the cache.
      // We can slowly enable this once we have a good setup for invalidating the cache.
      fetchPolicy: "network-only",
    },
    watchQuery: {
      // Apollo Client executes the full query against both the cache and your GraphQL server. The query automatically updates if the result of the server-side query modifies cached fields.
      // Provides a fast response while also helping to keep cached data consistent with server data.
      fetchPolicy: "cache-and-network",
    },
  },
});
