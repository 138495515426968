import { AnalyticsBrowser, Callback, EventProperties, Plugin } from "@segment/analytics-next";

const LOG_EVENTS = process.env.REACT_APP_LOG_EVENTS === "true" || process.env.NODE_ENV === "development";

const lowercase: Plugin = {
  name: "Lowercase events",
  type: "before",
  version: "1.0.0",

  isLoaded: () => true,
  load: () => Promise.resolve(),

  track: (ctx) => {
    ctx.event.event = ctx?.event?.event?.toLowerCase();
    return ctx;
  },
  identify: (ctx) => ctx,
  page: (ctx) => ctx,
  alias: (ctx) => ctx,
  group: (ctx) => ctx,
  screen: (ctx) => ctx,
};

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_ID || "thiswillfailtoload",
  plugins: [lowercase],
});

interface Tracker {
  page: typeof analytics.page;
  identify: typeof analytics.identify;
  track: (event: EventNames, params?: EventProperties | Callback | undefined) => void;
}

export enum EventNames {
  // Client
  CREATED_CLIENT = "created_client",
  CREATED_CONTACT = "created_contact",
  // Application
  CREATED_MCA = "created_mca",
  SUBMITTED_APPLICATION = "submitted_application",
  ISSUE_REPORTED = "issue_reported",
  // Business codes
  STARTED_BUSINESS_CODE_BY_CODE_LOOKUP = "started_business_code_by_code_lookup",
  STARTED_BUSINESS_CODE_BY_SMART_LOOKUP = "started_business_code_by_smart_lookup",
  SUBMITTED_BUSINESS_CODE_BY_CODE_LOOKUP = "submitted_business_code_by_code_lookup",
  SUBMITTED_BUSINESS_CODE_BY_SMART_LOOKUP = "submitted_business_code_by_smart_lookup",
  NO_RESULTS_BUSINESS_CODE_BY_SMART_LOOKUP = "no_results_business_code_by_smart_lookup",

  // Credentials
  CREDENTIAL_DIALOG_TRIGGERED_ON_SUBMIT = "credential_dialog_triggered_on_submit",
}

export enum EventSources {
  DEFAULT = "default",
  SELECT = "select",
}

// simple wrapper for devving, want to see these events for awhile
const logWrapper = <T extends unknown[], U>(name: string, fn: (...args: T) => U) => {
  if (LOG_EVENTS) {
    return (...args: T): U => {
      console.log(name, args);
      return fn(...args);
    };
  }
  return (...args: T): U => fn(...args);
};

export function useTracking(): Tracker {
  return {
    page: logWrapper("Page", analytics.page),
    identify: logWrapper("Identify", analytics.identify),
    /**
     * Param values should be snake_case
     */
    track: logWrapper("Track", analytics.track),
  };
}
