import { GraphQLErrorResponse } from "@cp/auth";

import { AuthError } from "@/components/ui/auth-error";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

interface Props {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  error?: Error | GraphQLErrorResponse | null;
  children?: React.ReactNode;
}

export const AuthCard = ({ header, subHeader, children, error }: Props) => (
  <>
    {error && <AuthError error={error} />}
    <Card className="max-w-xs p-1 space-y-2 w-full">
      <CardHeader>
        <CardTitle className="text-2xl">{header}</CardTitle>
        {subHeader && <CardDescription>{subHeader}</CardDescription>}
      </CardHeader>
      {children}
    </Card>
  </>
);
