import { X } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  File_Audience,
  PolicyFragment,
  PolicyStateReceivedFromCarrierActions,
  useIssuePolicyMutation,
  useTransitionPolicyMutation,
} from "src/generated/graphql";
import { uploadFiles } from "src/utils/file";

export const ReceivedFromCarrier = ({ policy }: { policy: PolicyFragment }) => {
  const [transition, { loading: transitionLoading }] = useTransitionPolicyMutation();
  const { toast } = useToast();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [sendPolicy, { loading }] = useIssuePolicyMutation();

  const handleEmailClick = () => {
    setIsEmailModalOpen(true);
  };

  const handleSendEmail = (emailComment: string, fileIds: string[]) => {
    void sendPolicy({
      variables: {
        input: {
          policyId: policy.id,
          emailComment: emailComment,
          fileIds,
        },
      },
      onCompleted: () => {
        toast({ title: "Email sent" });
      },
      onError: () => {
        toast({ title: "Error sending email" });
      },
    });
  };

  const handleTransition = async (action: PolicyStateReceivedFromCarrierActions) => {
    await transition({
      variables: {
        input: {
          policyId: policy.id,
          action,
        },
      },
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Button variant="outline" size="sm" onClick={handleEmailClick} display="flex">
          Issue Policy to agent
        </Button>
      )}
      <Button
        onClick={() => handleTransition(PolicyStateReceivedFromCarrierActions.Issue)}
        variant="outline"
        size="sm"
        display="flex"
      >
        Mark as issued outside of app {transitionLoading && <Spinner />}
      </Button>
      <EmailModal
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSend={handleSendEmail}
        initialBody={""}
        policy={policy}
      />
    </>
  );
};

interface FileInfo {
  id: string;
  name: string;
}

const EmailModal = ({
  isOpen,
  onClose,
  onSend,
  initialBody,
  policy,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSend: (emailComment: string, files: string[]) => void;
  initialBody: string;
  policy: PolicyFragment;
}) => {
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [emailComment, setEmailComment] = useState(initialBody);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.type !== "application/pdf") {
        toast({ title: "We only accept PDF files" });
        return;
      }
      if (!insuredId) {
        toast({ title: "Error" });
        return;
      }
      try {
        setUploadingFile(true);
        const fileId = await uploadFiles([file], insuredId, File_Audience.External, "MANUAL_UPLOAD");
        if (fileId) {
          setFiles([...files, { id: fileId, name: file.name }]);
          setUploadingFile(false);
        }
      } catch {
        toast({ title: "Error uploading file", description: "Please try again" });
      }
    }
  };

  const handleRemoveFile = (idToRemove: string) => {
    setFiles(files.filter((file) => file.id !== idToRemove));
  };

  const handleSend = () => {
    onSend(
      emailComment,
      files.map((file) => file.id)
    );
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Issue Policy To Agent</DialogTitle>
        </DialogHeader>
        <div className="gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email-body" className="col-span-4">
              Email Body
            </Label>
            <Textarea
              id="email-body"
              value={emailComment}
              onChange={(e) => setEmailComment(e.target.value)}
              className="col-span-4"
              rows={10}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            {uploadingFile ? (
              <Spinner />
            ) : (
              <>
                <div className="col-span-4">Attach Files (PDF only)</div>
                <Input id="" type="file" onChange={handleFileChange} className="col-span-4" accept=".pdf" />{" "}
              </>
            )}
          </div>
          <div className="col-span-4">
            <Label>Attached Files:</Label>
            <ul className="mt-2 space-y-2">
              <li className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span>{policy?.policyFile?.filename}</span>
              </li>
              {files.map((file) => (
                <li key={file.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                  <span>{file.name}</span>
                  <Button variant="ghost" size="sm" onClick={() => handleRemoveFile(file.id)}>
                    <X className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSend}>Send Email</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
