import { Link, LinkProps } from "react-router-dom";

import { cn } from "src/utils";
import { Icon } from "./ui/icon";

export const Grid = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("gap-x-4 grid items-center px-6 text-xs", className)} {...rest}>
    {children}
  </div>
);

const gridRowClassName = [
  "col-span-full grid grid-cols-subgrid items-center -mx-6 px-6 relative",
  "bg-background border-t min-h-12 last:shadow-border-b [.bg-card_&]:last:shadow-none",
];

export const GridRow = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn(gridRowClassName, className)} {...rest}>
    {children}
  </div>
);

export const GridRowHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <header className={cn(gridRowClassName[0], "font-semibold pb-2 text-2xs text-muted-foreground", className)} {...rest}>
    {children}
  </header>
);

export const GridRowLink = ({ children, className, ...rest }: LinkProps) => (
  <Link className={cn(gridRowClassName, "group", className)} {...rest}>
    {children}
    <Icon
      icon="arrow_forward"
      className={cn(
        "flex items-center justify-center pointer-events-none rounded-full ",
        "opacity-25 group-hover:opacity-100 transition-all",
        "absolute top bottom right-4 group-hover:right-3 m-auto h-4 w-4"
      )}
    />
  </Link>
);

export const GridCell = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("truncate", className)} {...rest}>
    {children}
  </div>
);
