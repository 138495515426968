import { formatDistanceToNow, parseISO } from "date-fns";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";

import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Loading } from "@/components/ui/loading";
import { useFileJobsQuery } from "src/generated/graphql";

const FileJobs = ({ fileId }: { fileId: string | undefined }) => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const { loading, error, data } = useFileJobsQuery({
    variables: {
      input: {
        fileId: fileId!,
      },
    },
    pollInterval: 5000,
  });

  if (loading) {
    return (
      <SectionHeader className="relative">
        <SectionTitle>
          <h3>
            File Processing: <Loading label="Loading" />
          </h3>
        </SectionTitle>
      </SectionHeader>
    );
  }
  if (error) {
    return (
      <SectionHeader className="relative">
        <SectionTitle>
          <h3>File Processing: An error has occurred</h3>
        </SectionTitle>
      </SectionHeader>
    );
  }

  const isAnyJobRunning = data?.fileJobs.some((job) => job.status === "running");

  return (
    <>
      <SectionHeader className="relative">
        <SectionTitle>
          <h3>File Processing</h3>
          <Button variant="ghost" size="icon-sm" onClick={() => setIsTableVisible(!isTableVisible)}>
            {isTableVisible ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </Button>
        </SectionTitle>
        <SectionDescription>
          {data?.fileJobs.filter((j) => j.status === "completed").length} completed,{" "}
          {data?.fileJobs.filter((j) => j.status === "error").length} failed,{" "}
          {data?.fileJobs.filter((j) => j.status === "running").length} running
        </SectionDescription>
      </SectionHeader>
      {isTableVisible && (
        <Grid className="grid-cols-[1fr_1fr_3fr]">
          <GridRowHeader>
            <GridCell>Status</GridCell>
            <GridCell>Time </GridCell>
            <GridCell>Pipeline / ID</GridCell>
          </GridRowHeader>
          {data?.fileJobs.map((job) => (
            <GridRow key={job.id}>
              <GridCell>{job.status}</GridCell>
              <GridCell>{formatDistanceToNow(parseISO(job.createdAt), { addSuffix: true })}</GridCell>
              <GridCell>
                {job.pipeline}
                <br />
                {job.id}
              </GridCell>
            </GridRow>
          ))}
        </Grid>
      )}
      {isAnyJobRunning && (
        <SectionContent className="space-y-3">
          <Loading label="Some jobs are still running" />
          <p className="italic text-muted-foreground text-xs">
            If any processing jobs are pending you can wait a few minutes for them to complete or ignore and complete
            the form manually.
          </p>
        </SectionContent>
      )}
    </>
  );
};

export default FileJobs;
