import { Arrays } from "@cp/toolkit";

import { ClientDataField } from "@/client-data/create-client-data-field";

interface Field {
  label: string;
  name: string;
}

const fields: Field[] = [
  { label: "Alarm Installation", name: "alarmInstall" },
  { label: "Carpentry", name: "capentry" },
  { label: "Cell Tower", name: "cellTower" },
  { label: "Concrete", name: "concrete" },
  { label: "Debris Removal", name: "debrisRemoval" },
  { label: "Demolition", name: "demolition" },
  { label: "Door/Window", name: "doorWindow" },
  { label: "Drilling/Boring", name: "drillingBoring" },
  { label: "Dry Utility/Cabling", name: "dryUtilingCabling" },
  { label: "Drywall", name: "drywall" },
  { label: "EIFS", name: "eifs" },
  { label: "Electrical", name: "electrical" },
  { label: "Excavation", name: "excavation" },
  { label: "Fence", name: "fence" },
  { label: "Fireproofing", name: "fireproofing" },
  { label: "Flooring", name: "flooring" },
  { label: "Framing", name: "framing" },
  { label: "Glass/Glazing", name: "glassGlazing" },
  { label: "Grading", name: "grading" },
  { label: "HVAC", name: "hvac" },
  { label: "Insulation", name: "insulation" },
  { label: "Landscape", name: "landscape" },
  { label: "Maintenance/Handyman", name: "maintainence" },
  { label: "Masonry", name: "masonry" },
  { label: "Mechanical", name: "mechanical" },
  { label: "Painting", name: "painting" },
  { label: "Paving (Street/Roads)", name: "pavingStreetRoads" },
  { label: "Paving (Private)", name: "pavingPrivate" },
  { label: "Pile Driving", name: "pileDriving" },
  { label: "Plastering/Stucco", name: "plasteringStucco" },
  { label: "Plumbing", name: "plumbing" },
  { label: "Retaining Walls", name: "retainingWalls" },
  { label: "Roofing", name: "roofing" },
  { label: "Septic Tank/I/S/R", name: "septicTankISR" },
  { label: "Seismic/Retrofitting", name: "seismicRetrofitting" },
  { label: "Sewer Mains", name: "sewerMains" },
  { label: "Sheet Metal", name: "sheetMetal" },
  { label: "Siding", name: "siding" },
  { label: "Solar", name: "solar" },
  { label: "Steel/Ornamental", name: "steelOrnamental" },
  { label: "Steel/Structural", name: "steelStructural" },
  { label: "Supervisory Only", name: "supervisorOnly" },
  { label: "Swimming Pool", name: "swimmingPool" },
  { label: "Tile/Marble", name: "tileMarble" },
  { label: "Traffic Control", name: "trafficControl" },
  { label: "Traffic Signals", name: "trafficSignals" },
  { label: "Tree Removal", name: "treeRemoval" },
  { label: "Water/Gas Mains", name: "waterGasMains" },
  { label: "Waterproofing", name: "waterproofing" },
  { label: "Welding", name: "welding" },
];

const columns = Arrays.sliceIntoN(fields, 2);

export const PercentageOfPayroll = () => (
  <table className="dense">
    <thead>
      <tr>
        <th style={{ width: "25%" }}>Type</th>
        <th style={{ width: "12.5%" }}>Direct %</th>
        <th style={{ width: "12.5%" }}>Subbed %</th>
        <th style={{ width: "25%" }}>Type</th>
        <th style={{ width: "12.5%" }}>Direct %</th>
        <th style={{ width: "12.5%" }}>Subbed %</th>
      </tr>
    </thead>
    <tbody>
      {columns[0].map((col, key) => (
        <tr key={key}>
          <td>{col.label}</td>
          <td>
            <ClientDataField.Input type="text" name={`percentPayroll:${col.name}:direct`} />
          </td>
          <td>
            <ClientDataField.Input type="text" name={`percentPayroll:${col.name}:sub`} />
          </td>
          {columns[1][key] ? (
            <>
              <td>{columns[1][key].label}</td>
              <td>
                <ClientDataField.Input type="text" name={`percentPayroll:${columns[1][key].name}:direct`} />
              </td>
              <td>
                <ClientDataField.Input type="text" name={`percentPayroll:${columns[1][key].name}:sub`} />
              </td>
            </>
          ) : (
            <td colSpan={3} />
          )}
        </tr>
      ))}
      <tr>
        <td colSpan={4}>
          <ClientDataField.Input type="text" name={`percentPayroll:other:text`} placeholder="Other (please explain)" />
        </td>
        <td>
          <ClientDataField.Input type="text" name={`percentPayroll:other:direct`} />
        </td>
        <td>
          <ClientDataField.Input type="text" name={`percentPayroll:other:sub`} />
        </td>
      </tr>
    </tbody>
  </table>
);
