import { useSetAtom } from "jotai";
import { useMutation } from "react-query";
import { authDataAtom, authTokenValidatedAtom } from "../atoms/atoms";
import { useAuthContext } from "../context/AuthContext";
import { RefreshTokenDocument, RefreshTokenMutationVariables } from "../generated/operations";

export default function useRefreshToken() {
  const { fetcher } = useAuthContext();
  const setAuthData = useSetAtom(authDataAtom);
  const setTokenValidated = useSetAtom(authTokenValidatedAtom);

  return useMutation(
    (args: RefreshTokenMutationVariables) => {
      return fetcher(RefreshTokenDocument, args).then((response) => response.refreshToken);
    },
    {
      onSuccess: (token) => {
        setAuthData((data) => ({ token: token, shouldRemember: data?.shouldRemember ?? false }));
        setTokenValidated(true);
      },
      onError: () => {
        setAuthData(null);
      },
    }
  );
}
