import { NodeToolbar as ReactFlowNodeToolbar, Position } from "@xyflow/react";
import { FC, MouseEvent } from "react";

import { Button } from "@/components/ui/button";
import { Icon, IconString } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

export enum NodeActionType {
  Item = "item",
  Separator = "separator",
}

export interface NodeAction {
  id?: string;
  type?: NodeActionType;
  icon?: IconString;
  label?: string;
  onClick?: (event: any) => void;
  isDefault?: boolean;
  isHidden?: boolean;
}

export interface NodeToolbarProps {
  isVisible?: boolean;
  actions?: NodeAction[];
}

export const NodeToolbar: FC<NodeToolbarProps> = ({ isVisible, actions }) => {
  const handleActionDoubleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <ReactFlowNodeToolbar position={Position.Bottom} isVisible={isVisible}>
      <div className="flex items-center h-10 rounded bg-background shadow-sm px-0.5">
        {actions.map((action, i) => {
          if (action.isHidden) {
            return null;
          }

          if (action.type === NodeActionType.Separator) {
            return (
              <div key={i} className="flex items-center h-full py-2 mx-0.5 g">
                <Separator key={i} orientation="vertical" />
              </div>
            );
          }

          if (!action.icon) {
            return null;
          }

          return (
            <div key={action.id} className="p-0.5">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon-lg"
                    onDoubleClickCapture={handleActionDoubleClick}
                    onClick={action.onClick}
                  >
                    <Icon icon={action.icon} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="!bg-foreground !text-background" side="bottom">
                  {action.label}
                </TooltipContent>
              </Tooltip>
            </div>
          );
        })}

        {/* NOTE: Keeping this here for now as an example of how we might add "more" actions in the future. */}
        {/* <Tooltip>
          <TooltipTrigger>
            <Button variant="ghost" size="icon-lg" onDoubleClickCapture={handleActionDoubleClick}>
              <Icon icon="more_horiz" className="font-bold" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="!bg-foreground !text-background" side="bottom">
            More
          </TooltipContent>
        </Tooltip> */}
      </div>
    </ReactFlowNodeToolbar>
  );
};
