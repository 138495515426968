import React, { FocusEventHandler } from "react";

import { Unstyled } from "@/forms/unstyled";
import { useClientDataHandlers } from "./use-client-data-handlers";

interface BaseInputProps {
  name: string;
  index?: number;
  onBlur?(e: any): void;
  onFocus?: FocusEventHandler<unknown> | undefined;
  defaultValue?: string | number | readonly string[];
}

type SupplementalProps<TProps extends BaseInputProps> = Omit<TProps, "onBlur" | "onFocus" | "defaultValue">;

function createClientDataField<TProps extends BaseInputProps>(component: React.FC<TProps>) {
  // Typescript is confused by the generics
  const Component = component as React.FC<BaseInputProps>;
  const NewComponent: React.FC<SupplementalProps<TProps>> = ({ name, index, ...props }) => (
    <Component {...props} name={name} {...useClientDataHandlers(name, index)} />
  );

  NewComponent.displayName = `Supplemental${Component.displayName}`;
  return NewComponent;
}

const Input = createClientDataField(Unstyled.Input);
const TextArea = createClientDataField(Unstyled.TextArea);
const RadioBoolean = createClientDataField(Unstyled.RadioBoolean);
const Date = createClientDataField(Unstyled.Date);
const Number = createClientDataField(Unstyled.Number);
const Money = createClientDataField(Unstyled.Money);

// Primarily used for forms that will also generate PDFs
export const ClientDataField = {
  Input,
  TextArea,
  Label: Unstyled.Label,
  RadioBoolean,
  Date,
  Number,
  Money,
};
