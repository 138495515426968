import { groupBy, orderBy } from "lodash";
import { useParams } from "react-router";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { hasInternalRole } from "@/components/has-role";
import { SectionHeader, SectionTitle } from "@/components/section";
import { useMyAccount } from "@/hooks/use-my-account";
import { StateIndicator } from "@/metadata";
import { QuoteState, useQuotesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";

export const Quotes = () => {
  const { insuredId } = useParams();
  const { data: user } = useMyAccount();

  if (!user) {
    return null;
  }

  const { data: { quotes } = { quotes: [] }, loading } = useQuotesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const filteredQuotes = hasInternalRole(user)
    ? quotes
    : quotes.filter(
        (quote) =>
          quote.state === QuoteState.Delivered ||
          quote.state === QuoteState.BindRequestReceived ||
          quote.state === QuoteState.NotTaken ||
          quote.state === QuoteState.Bound
      );

  const sortedQuotes = orderBy(filteredQuotes, (quote) => Date.parse(quote.effectiveDate));
  const groupedQuotes = groupBy(sortedQuotes, (quote) => formatTimezoneDateString(quote.effectiveDate, "yyyy"));

  return (
    <>
      {quotes[0] ? (
        Object.keys(groupedQuotes).map((date) => (
          <div key={date}>
            <SectionHeader>
              <SectionTitle>
                <h2>{date}</h2>
              </SectionTitle>
            </SectionHeader>
            <Grid className="grid-cols-5">
              <GridRowHeader>
                <GridCell>Carrier</GridCell>
                <GridCell>Product</GridCell>
                <GridCell>Premium</GridCell>
                <GridCell>Status</GridCell>
                <GridCell>Eff. Date</GridCell>
              </GridRowHeader>
              {groupedQuotes[date].map((quote) => {
                const {
                  id,
                  effectiveDate,
                  premium,
                  state,
                  submission: { appetiteProduct },
                } = quote;
                return (
                  <GridRowLink key={id} to={id}>
                    <GridCell>{appetiteProduct.carrierName}</GridCell>
                    <GridCell>{appetiteProduct.carrierProductName}</GridCell>
                    <GridCell>{formatDollars(premium ?? 0)}</GridCell>
                    <StateIndicator state={state} />
                    <GridCell>{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</GridCell>
                  </GridRowLink>
                );
              })}
            </Grid>
          </div>
        ))
      ) : (
        <EmptyState loading={loading} title={<h2>No quotes found.</h2>} />
      )}
    </>
  );
};
