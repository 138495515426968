import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { MarketingPlanStateCompleteActions, useTransitionMarketingPlanMutation } from "src/generated/graphql";
import { MarketingPlan } from "../marketing-plan-details";

export const Complete = ({ marketingPlan }: { marketingPlan: MarketingPlan }) => {
  const [transition] = useTransitionMarketingPlanMutation();
  const { openConfirmation } = useModal();

  const handleTransition = async (action: MarketingPlanStateCompleteActions) => {
    const isConfirmed = await openConfirmation({
      title: "Confirm reopen marketing",
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          marketingPlanId: marketingPlan.id,
          action,
        },
      },
    });
  };

  return (
    <Button onClick={() => handleTransition(MarketingPlanStateCompleteActions.Reopen)} variant="outline" size="sm">
      Re-open Marketing
    </Button>
  );
};
