import assertNeverInternal from "assert-never";

export const coerceBoolean = (val: string | boolean | null | undefined): boolean => {
  if (typeof val === "boolean") {
    return val;
  } else if (typeof val === "string") {
    const valLower = val.toLowerCase();
    if (
      valLower === "yes" ||
      valLower === "y" ||
      valLower === "on" ||
      valLower === "true" ||
      valLower === "t" ||
      valLower === "checked"
    ) {
      return true;
    }
  }

  return false;
};

const nonNumericalCharacters = /[$,]/g;
export const coerceNumber = (val: string | number | null | undefined, parser = Number.parseFloat): number | null => {
  let result: number | null = null;
  if (typeof val === "number") {
    result = val;
  } else if (typeof val === "string") {
    result = parser(val.replaceAll(nonNumericalCharacters, ""));
  }

  // This is so easy to forget. Just force a null check.
  if (Number.isNaN(result)) {
    return null;
  }
  return result;
};

export const assertNever = assertNeverInternal;

/**
 * Like assert never, but won't throw an error.
 */
export const logNever = (val: never): void => {
  assertNever(val, true);
  return;
};
