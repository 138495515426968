import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router";
import { z } from "zod";

import { useToast } from "@/components/ui/use-toast";
import { Roles, useCreateInsuredAndAgentMutation, useCreateInsuredMutation } from "src/generated/graphql";

const InsuredFormSchema = z.object({
  clientName: z.string().min(1, { message: "Business name is required" }),
  state: z.string().length(2, { message: "State is required" }),
  revenue: z.number().optional(),
  description: z.string().min(1, { message: "Business description is required" }),
});

export const AgentFormSchema = InsuredFormSchema.extend({
  firstName: z.string(),
  lastName: z.string(),
  agentEmail: z.string().email(),
  agencyId: z.string(),
});

const useCreateInsured = () => {
  const [mutation] = useCreateInsuredMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof InsuredFormSchema>) => {
    return await mutation({
      variables: {
        input: {
          name: data.clientName,
          primaryState: data.state,
          revenue: data.revenue,
          description: data.description,
        },
      },
      onCompleted: (data) => {
        navigate(`/insured/${data.createInsured.id}`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/opportunity/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

const useCreateInsuredAndAgent = () => {
  const [mutation] = useCreateInsuredAndAgentMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  return async (data: z.infer<typeof AgentFormSchema>) => {
    return await mutation({
      variables: {
        input: {
          name: data.clientName,
          primaryState: data.state,
          revenue: data.revenue,
          description: data.description,
          agentInput: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.agentEmail,
            agencyId: data.agencyId,
          },
        },
      },
      onCompleted: (data) => {
        navigate(`/insured/${data.createInsuredAndAgent.id}`);
      },
      onError: (error) => {
        if (error?.message) {
          const errorDetails = JSON.parse(error.message);
          if (errorDetails.id) {
            navigate(`/opportunity/${errorDetails.id}`);
            toast({ title: "Error", description: errorDetails.message, variant: "default" });
          }
        } else {
          toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
        }
      },
    });
  };
};

export const useCreateInsured2 = (user: { roles: Roles[]; internal: boolean } | undefined) => {
  return {
    zodResolver: zodResolver(user?.internal ? AgentFormSchema : InsuredFormSchema),
    createInsured: user?.internal ? useCreateInsuredAndAgent() : useCreateInsured(),
  };
};
