import { useNodes } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import type { FC } from "react";
import { useNavigate } from "react-router";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
} from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface RemoveProcessorFormProps {
  processorId: string;
}

export const RemoveProcessorForm: FC<RemoveProcessorFormProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const [createPipelineVersion] = useCreateFilePipelineVersionMutation();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });

  const handleConfirm = async () => {
    if (!pipelineData || !fileProcessorData) {
      return;
    }

    const input = getRemoveProcessorInput(props.processorId, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor"],
    });

    navigate(`/file-processing-pipeline`, { replace: true });
    toast({ title: `${fileProcessorData.fileProcessor?.name} processor removed` });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-6 max-w-full">
      <DialogHeader>
        <DialogTitle>Are you sure you want to remove the processor?</DialogTitle>
        <DialogDescription>
          There is currently no way to undo this action. However, you may re-add the processor later.
        </DialogDescription>
      </DialogHeader>

      <div className="flex justify-end gap-2">
        <Button type="button" onClick={closeModal} variant="outline">
          Cancel
        </Button>
        <Button variant="destructive" onClick={handleConfirm}>
          Remove processor
        </Button>
      </div>
    </div>
  );
};

export interface UseRemoveProcessorFormModalOptions {
  processorId?: string;
}

export const useRemoveProcessorFormModal = ({ processorId }: UseRemoveProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openRemoveProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <RemoveProcessorForm processorId={processorId} />);
    },
  };
};

function getRemoveProcessorInput(
  processorId: string,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): CreatePipelineVersionInput | null {
  const pipelineDataCopy = cloneDeep(pipelineData);
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineDataCopy);

  const currentNode = findNodeById(processorId, nodes);

  if (!currentNode) {
    return null;
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const existingLabel = pipelineDataCopy.pipeline.transitions.find(
    (transition) => transition.label === parentNode.data.name && transition.sourceNodeName === parentNode.data.category
  );

  if (!existingLabel) {
    return null;
  }

  existingLabel.destinationNodes = existingLabel.destinationNodes.filter((node) => node.id !== processorId);

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineDataCopy.pipeline.initial,
      transitions: pipelineDataCopy.pipeline.transitions,
    },
  };
}
