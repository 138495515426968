import { createContext, FC, PropsWithChildren, useContext } from "react";
import { TypedGraphQLFetcher } from "./types";

export interface AuthContextValue {
  authClientId: string;
  fetcher: TypedGraphQLFetcher;
}

const AuthContext = createContext<AuthContextValue>({
  authClientId: "",
  fetcher: () => Promise.reject(new Error("AuthContext.fetcher is not set")),
});

export const AuthContextProvider: FC<PropsWithChildren<AuthContextValue>> = ({ children, ...rest }) => {
  return <AuthContext.Provider value={rest}>{children}</AuthContext.Provider>;
};

export function useAuthContext() {
  return useContext(AuthContext);
}
