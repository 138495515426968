import { useDocumentTitle } from "usehooks-ts";

import { pinnedInsuredsAtom } from "@/atoms";
import { daysDelta } from "@/bankan/cards/bankan-cards";
import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { InsuredPin } from "@/components/insured-pin";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Spinner } from "@/components/ui/loading";
import { StateIndicator } from "@/metadata";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { usePaginatedInsuredsDeepQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const BrokerPinned = () => {
  const [pinnedInsuredIds] = useAtom(pinnedInsuredsAtom);

  const { data: { paginatedInsureds: { insureds } } = { paginatedInsureds: { insureds: [] } }, loading } =
    usePaginatedInsuredsDeepQuery({
      variables: {
        args: {
          ids: Object.keys(pinnedInsuredIds),
        },
        pagination: {},
      },
      fetchPolicy: "cache-and-network",
    });

  useDocumentTitle("Active Marketing Plans: QuoteWell");

  if (!insureds) {
    return null;
  }

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Pinned Insureds</h1>
          {loading && <Spinner />}
        </SectionTitle>
      </SectionHeader>
      {insureds[0] ? (
        <Grid className="grid-cols-[1.5rem_1fr_1fr_1fr_1fr]">
          <GridRowHeader>
            <GridCell />
            <GridCell>Insured / Ageny</GridCell>
            <GridCell>Lines</GridCell>
            <GridCell>State</GridCell>
            <GridCell>Eff. Date</GridCell>
          </GridRowHeader>
          {insureds.map((insured) => {
            const { id, name, agent, opportunities } = insured;
            const recentOpps = opportunities
              .filter((opp) => daysDelta(opp.desiredEffectiveDate) < 180 && !opp.deletedAt)
              .sort((a, b) => Date.parse(a.desiredEffectiveDate) - Date.parse(b.desiredEffectiveDate));

            return (
              <GridRow key={id} className="items-start py-4">
                <GridCell>
                  <InsuredPin id={id} className="h-4" />
                </GridCell>
                <GridCell className="space-y-1">
                  <Link to={`/insured/${id}`} className="block font-semibold">
                    {name}
                  </Link>
                  <div className="text-2xs text-muted-foreground truncate">
                    {agent.firstName} {agent.lastName}, {agent.agency.name}
                  </div>
                </GridCell>
                <div className="col-span-3 gap-y-4 grid grid-cols-subgrid">
                  {recentOpps.map((opp) => (
                    <Link
                      key={opp.id}
                      to={`/insured/${id}/plans/${opp.id}`}
                      className="col-span-full grid grid-cols-subgrid"
                    >
                      <GridCell className="space-y-1">
                        {opp.selectedLinesOfBusiness[0] ? (
                          opp.selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                        ) : (
                          <div>No lines of business</div>
                        )}
                      </GridCell>
                      <GridCell>
                        <StateIndicator state={opp.state} />
                      </GridCell>
                      <GridCell>{formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, YYY")} </GridCell>
                    </Link>
                  ))}
                </div>
              </GridRow>
            );
          })}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No pinned insureds found.</h2>} />
      )}
    </>
  );
};
