import { join } from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router";

import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Checkbox } from "@/components/ui/checkbox";
import { Loading } from "@/components/ui/loading";
import { useFilesByLabelQuery } from "src/generated/graphql";

interface Props {
  opportunityId: string;
  fileIds: string[];
  setFileIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const SubmissionTemplateAttachments: React.FC<Props> = ({ setFileIds, fileIds }) => {
  const { insuredId } = useParams<"insuredId">();
  const { data: { filesByLabel: linkedFiles = [] } = {}, loading } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: false,
        includeCorrespondence: false,
        labels: [],
        deletedAt: false,
      },
    },
  });

  // Concurrently load all files (incl. email attachments), because this can take a while
  const { data: { filesByLabel: allFiles = [] } = {} } = useFilesByLabelQuery({
    variables: {
      input: {
        insuredId: insuredId || "",
        includeGmailAttachments: true,
        includeCorrespondence: false,
        labels: [],
        deletedAt: false,
      },
    },
  });

  const files = allFiles.length > 0 ? allFiles : linkedFiles;

  useEffect(() => {
    if (fileIds) {
      setFileIds(fileIds);
    }
  }, [fileIds]);

  if (loading) {
    return <Loading label="Loading..... Retrieving related files" />;
  }

  if (files.length === 0) {
    return (
      <>
        <h3>No files found for this opportunity</h3>
      </>
    );
  }

  return (
    <>
      <h3 className="mb-4">Select files to add as attachments</h3>
      <Grid className="grid-cols-[1.5rem_1fr_1fr]">
        <GridRowHeader>
          <GridCell>
            <Checkbox
              checked={fileIds.length === files.length}
              onCheckedChange={(checked) => {
                checked ? setFileIds(files.map(({ id }) => id)) : setFileIds([]);
              }}
            />
          </GridCell>
          <GridCell>File Name</GridCell>
          <GridCell>Labels</GridCell>
        </GridRowHeader>
        {files.map(({ id, labels, filename }) => (
          <GridRow key={id}>
            <GridCell>
              <Checkbox
                checked={fileIds.includes(id ?? "")}
                onCheckedChange={(checked) => {
                  checked
                    ? setFileIds((prev: string[]) => [...prev, id ?? ""])
                    : setFileIds((prev: string[]) => prev.filter((fileId) => fileId !== id));
                }}
              />
            </GridCell>
            <GridCell>{filename}</GridCell>
            <GridCell>{join(labels, ", ")}</GridCell>
          </GridRow>
        ))}
      </Grid>
    </>
  );
};
