import { FC, HTMLAttributes } from "react";

import { cn } from "src/utils";

export type FormGroupProps = HTMLAttributes<HTMLDivElement> & {
  orientation?: "horizontal" | "vertical";
};

export const FormGroup: FC<FormGroupProps> = ({ className, orientation = "vertical", ...props }) => {
  return (
    <div
      className={cn("flex flex-col gap-4 flex-1", { "flex-row": orientation === "horizontal" }, className)}
      {...props}
    />
  );
};
