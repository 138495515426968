import * as React from "react";

import { cn } from "src/utils";
import { Icon } from "./icon";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 read-only:bg-accent read-only:text-muted-foreground",
        className
      )}
      ref={ref}
      {...props}
      onWheel={(e) => (e.target as HTMLElement).blur()}
    />
  );
});
Input.displayName = "Input";

const SearchInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <div className="relative">
      <Icon icon="search" className="absolute bottom-0 left-3 m-auto text-muted-foreground text-xs top-0" />
      <input
        type="search"
        className={cn(
          "rounded-3xl bg-foreground/5 border-0 font-normal h-8 pl-8 pr-4 py-2 text-xs focus-visible:outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
SearchInput.displayName = "SearchInput";

export { Input, SearchInput };
