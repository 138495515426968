import { AuthData, authDataAtom, authTokenValidatedAtom, jwtDataAtom, useRefreshToken } from "@cp/auth";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { useAtom, useAtomValue } from "jotai";
import * as React from "react";
import { Navigate, useLocation } from "react-router";

import { useMyAccount } from "@/hooks/use-my-account";

const REFRESH_INTERVAL = 1000 * 60 * 30; // 30 min

export function AuthGuard(props: React.PropsWithChildren) {
  const location = useLocation();
  const [authTokenValidated] = useAtom(authTokenValidatedAtom);

  // AuthData is synced across tabs. This makes logout occur in all tabs
  const [authData] = useAtom(authDataAtom);

  return authData && authTokenValidated ? (
    <AuthSuccess authData={authData} href={location.pathname}>
      {props.children}
    </AuthSuccess>
  ) : (
    <AuthPending authData={authData} href={location.pathname}>
      {props.children}
    </AuthPending>
  );
}

interface AuthProps {
  href: string;
  authData: AuthData | null;
  children: React.ReactNode;
}

function AuthPending({ href, authData }: AuthProps) {
  const jwtPayload = useAtomValue(jwtDataAtom);
  const location = useLocation();
  const [, setTokenValidated] = useAtom(authTokenValidatedAtom);
  const { mutate: refreshToken } = useRefreshToken();

  // Prevents re-renders from triggering re-validation
  const validating = React.useRef(false);

  const shouldRefresh = Boolean(
    authData?.token.refreshToken && jwtPayload && authData.shouldRemember && !jwtPayload.hasExpired
  );

  const impersonationMode = jwtPayload?.impersonatedById;

  React.useEffect(() => {
    if (!validating.current) {
      validating.current = true;

      if (shouldRefresh && authData?.token.refreshToken) {
        refreshToken({
          token: {
            accessToken: authData.token.accessToken,
            refreshToken: authData.token.refreshToken,
          },
        });
      }
    }

    if (impersonationMode) {
      void setTokenValidated(true);
    }
  }, [shouldRefresh, authData, href, jwtPayload, refreshToken]);

  return shouldRefresh || impersonationMode ? null : <Navigate to={`/login?redirect=${location.pathname}`} />;
}

function AuthSuccess({ authData, children }: AuthProps) {
  const { mutate: refreshToken } = useRefreshToken();
  // const ldClient = useLDClient();

  useMyAccount({
    onCompleted: async (query) => {
      if (process.env.NODE_ENV !== "development") {
        const user = query.myAccount;
        FullStory.identify(user.id, { displayName: `${user.firstName} ${user.lastName}`, email: user.email });
        Sentry.setUser({ id: user.id, username: user.email });
      }
      // if (!ldClient) {
      //   // TODO: Log error
      //   throw new Error("LDClient hasn't been initialized. Did you forget to mount the LDClientProvider?");
      // }

      // const ldContext = getLDContext(user);
      // await ldClient.identify(ldContext);
    },
  });

  React.useEffect(() => {
    if (!authData?.token.refreshToken) {
      return;
    }
    const token = {
      accessToken: authData.token.accessToken,
      refreshToken: authData.token.refreshToken,
    };

    const intervalId = setInterval(() => {
      refreshToken({ token });
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [refreshToken, authData]);

  return <>{children}</>;
}
