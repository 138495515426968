import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { reloadOpportunity } from "@/hooks/use-oopportunity";
import { useCreateBrokerageFolderForOpportunityMutation } from "src/generated/graphql";
import { DropdownMenuItem } from "../components/ui/dropdown-menu";

export const GoogleDriveButton = ({
  opportunityId,
  brokerageFolderId,
}: {
  opportunityId: string;
  brokerageFolderId: string | null | undefined;
}) => (
  <div className="ml-auto">
    {brokerageFolderId ? (
      <DropdownMenuItem asChild>
        <a href={`https://drive.google.com/drive/folders/${brokerageFolderId}`} target="_blank" rel="noreferrer">
          <Icon icon="drive_export" />
          Open GDrive
        </a>
      </DropdownMenuItem>
    ) : (
      <CreateFolderButton opportunityId={opportunityId} />
    )}
  </div>
);

const CreateFolderButton = ({ opportunityId }: { opportunityId: string }) => {
  const { toast } = useToast();
  const [createBrokerageFolder, { loading }] = useCreateBrokerageFolderForOpportunityMutation({
    variables: {
      id: opportunityId,
    },
    ...reloadOpportunity,
    onCompleted: () => {
      toast({ title: "Google Drive folder created" });
    },
    onError: () => {
      toast({ title: "Error", description: "Failed to create Google Drive folder", variant: "destructive" });
    },
  });

  return (
    <DropdownMenuItem onClick={() => createBrokerageFolder()}>
      {loading ? (
        <>
          <Spinner />
          Creating Folder...
        </>
      ) : (
        <>
          <Icon icon="create_new_folder" />
          Create GDrive Folder
        </>
      )}
    </DropdownMenuItem>
  );
};
