import qs from "query-string";
import { LoaderFunctionArgs, Params, useLoaderData, useRouteLoaderData } from "react-router";
import { z, ZodRawShape } from "zod";

export type LoaderFn<TResult = unknown> = (args: LoaderFunctionArgs) => TResult | Promise<TResult>;
export type LoaderFnResult<TLoader extends LoaderFn> = Exclude<Awaited<ReturnType<TLoader>>, Response>;
export const useTypedLoaderData = <TLoader extends LoaderFn>() => useLoaderData() as LoaderFnResult<TLoader>;
export const useTypedRouteLoaderData = <TLoader extends LoaderFn>(routeId: string) =>
  useRouteLoaderData(routeId) as LoaderFnResult<TLoader>;

export const createQueryString = <T extends object>(val: T) => qs.stringify(val, { arrayFormat: "bracket" });

export const getValidSearchParams = <TSchema extends z.ZodObject<ZodRawShape>>(
  request: Request,
  schema: TSchema
): z.infer<TSchema> | null => createSearchParamsValidator(schema)(request);

/**
 * Useful way to construct a param validation function at the root of the file
 * @code ```
 * const getUrlParams = createUrlParamsValidator(z.object({
 *  someObjectId: z.string()
 * }))
 *
 * export const loader = ({ params }: LoaderFunctionArgs) => {
 *  const { someObjectId } = getUrlParams(params);
 *  ...
 * }
 *
 * export const action = ({ params }: ActionFunctionArgs) => {
 *  const { someObjectId } = getUrlParams(params)
 *  ...
 * }
 *
 * ```
 *
 * @param schema
 * @returns A validator function that uses the passed in schema
 */
export const createUrlParamsValidator =
  <TSchema extends z.ZodObject<ZodRawShape>>(schema: TSchema) =>
  (params: Params): z.infer<TSchema> => {
    const result = schema.safeParse(params);
    if (result.success) {
      return result.data;
    }
    console.error(result.error);
    throw new Response("Not found", { status: 404 });
  };

export const createSearchParamsValidator =
  <TSchema extends z.ZodObject<ZodRawShape>>(schema: TSchema) =>
  (request: Request): z.infer<TSchema> | null => {
    const url = new URL(request.url);
    const params = qs.parse(url.search, { arrayFormat: "bracket" });

    for (const key in params) {
      if (params[key] === "null") {
        params[key] = null;
      }
    }

    const paramData = schema.safeParse(params);
    if (paramData.success) {
      return paramData.data;
    } else {
      const error = paramData.error.flatten();
      console.error(error);
    }
    return null;
  };
