import { LoaderFunctionArgs, Outlet, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";
import { SidebarProvider } from "@/components/sidebar";
import { InsuredDocument, InsuredQuery, InsuredQueryVariables } from "src/generated/graphql";
import { apolloClient } from "src/graphql/client";
import { getAuthedUser } from "src/utils/get-authed-user";
import { useTypedLoaderData } from "src/utils/router";
import { InsuredSidebar } from "./sidebar/insured-sidebar";

export const InsuredRoot = () => {
  const navigate = useNavigate();
  const insured = useTypedLoaderData<typeof insuredLoader>();

  if (!insured) {
    return null;
  }

  if (insured.mergedIntoId) {
    navigate(`/insured/${insured.mergedIntoId}`);
  }

  useDocumentTitle(`${insured.name}: QuoteWell`);

  return (
    <SidebarProvider>
      <InsuredSidebar />
      <ScrollPane>
        <Outlet />
      </ScrollPane>
    </SidebarProvider>
  );
};

export const insuredLoader = async ({ params }: LoaderFunctionArgs) => {
  const { user } = (await getAuthedUser()) ?? {};

  if (!user) {
    return null;
  }

  return await apolloClient
    .query<InsuredQuery, InsuredQueryVariables>({
      query: InsuredDocument,
      variables: {
        id: params.insuredId ?? "",
      },
    })
    .then((v) => ({ ...v.data.insured }));
};
