import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export const useUpsertSearchParams = (replace: boolean = true) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const upsertSearchParams = useCallback(
    function upsertSearchParams(params: Record<string, string>) {
      setSearchParams(
        (searchParams) => {
          const { page, ...rest } = Object.fromEntries(searchParams);
          return { ...rest, ...params };
        },
        { replace }
      );
    },
    [setSearchParams]
  );

  const removeSearchParams = useCallback(
    function removeSearchParams(paramsToDelete: string[]) {
      const params = new URLSearchParams(searchParams);
      for (const param of paramsToDelete) {
        params.delete(param);
      }
      setSearchParams(params);
    },
    [searchParams, setSearchParams]
  );

  return [searchParams, upsertSearchParams, setSearchParams, removeSearchParams] as const;
};
