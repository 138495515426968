import { format, formatDistance, parse, parseISO } from "date-fns";

/**
 *
 * @param date
 * @returns date strings in the format "LL-dd-yyyy"
 */
export const formatDate = (date: Date | number, formatString = "LL-dd-yyyy") => format(date, formatString);

export const formatDateString = (date: string | null, formatString?: string) =>
  date ? formatDate(parseDate(date), formatString) : "";

export const formatDateStringDistance = (date: string) => formatDistance(parseDate(date), new Date());

/**
 * Formats a date, taking into account that we actually want to preserve the timezone information.
 * This should be used when the date is known to be computer generated, vs. human generated.
 */
export const formatTimezoneDateString = (date: string | null, formatString?: string) =>
  date ? formatDate(parseISO(date), formatString) : "";

/**
 * Formats a date string distance, taking into account that we actually want to preserve the timezone information.
 * This should be used when the date is known to be computer generated, vs. human generated.
 */
export const formatTimezoneDateStringDistance = (date: string | null, addSuffix = false) =>
  date ? formatDistance(parseISO(date), new Date(), addSuffix ? { addSuffix } : undefined) : "";

/**
 * Given a GraphQL Date Time in ISO8601 format, ie:
 *
 * 2007-12-03T10:15:30Z
 *
 * Drop the time from the response and return just the
 * date at 00:00:00.
 *
 * This works around legacy/historical issues where we stored
 * dates in the database in the user's timezone, but when
 * round tripping those from the database they would go through
 * one or two conversion processes in different timezones (the
 * servers run in UTC), before finally getting to the client, where
 * it would then do another timezone conversion. To be safe
 * just drop the timestamp marker, makes things easy.
 */
function parseDate(date: string): Date {
  const graphqlTimezoneMarker = date.indexOf("T");
  if (graphqlTimezoneMarker > 0) {
    // parse used here because if you use new Date()
    // it creates the date in the local timezone, which then if you subsequently
    // supply that to format, it formats it in that timezone...so if you're on PST
    // all dates end up being a day before you wish they were.
    return parse(date.slice(0, Math.max(0, graphqlTimezoneMarker)), "yyyy-MM-dd", new Date());
  }
  return new Date(date);
}
