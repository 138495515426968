import { useNodes } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import {
  CreatePipelineVersionInput,
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
  useFileProcessorQuery,
} from "src/generated/graphql";

import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  findParentNodeByChildId,
} from "../../file-processing-pipeline.helpers";

export interface EditProcessorFormValues {
  id: string;
  previousId: string;
  type: FileProcessorCategory;
  name: string;
}

export interface EditProcessorFormProps {
  processorId: string;
}

const validationSchema = z.object({
  id: z.string().min(1, { message: "Please enter the processor ID" }),
  previousId: z.string().min(1, { message: "Previous ID is required" }),
  type: z.string().optional(),
  name: z.string().optional(),
});

export const EditProcessorFormContent: FC<EditProcessorFormProps> = () => {
  const { register } = useFormContext();
  const { closeModal } = useModal();

  return (
    <FormGroup className="gap-6">
      <DialogHeader>
        <DialogTitle>Edit processor</DialogTitle>
        <DialogDescription>Update the file processor details.</DialogDescription>
      </DialogHeader>

      <FormGroup>
        <FieldInput name="id" label="File processor ID" placeholder="Enter processor ID" />
        <FieldInput name="type" label="Type" placeholder="Type unavailable" inputProps={{ readOnly: true }} />
        <FieldInput name="name" label="Name" placeholder="Name unavailable" inputProps={{ readOnly: true }} />
        <input type="hidden" {...register("previousId")} />
      </FormGroup>

      <ButtonGroup className="justify-end">
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Save processor</FormSubmit>
      </ButtonGroup>
    </FormGroup>
  );
};

export const EditProcessorForm: FC<EditProcessorFormProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const [createPipelineVersion] = useCreateFilePipelineVersionMutation();
  const { data: pipelineData, loading: pipelineDataLoading } = useFileProcessingPipelineQuery();
  const { data: fileProcessorData, loading: fileProcessorDataLoading } = useFileProcessorQuery({
    variables: { id: props.processorId },
  });

  const defaultValues = {
    id: props.processorId,
    previousId: props.processorId,
    name: fileProcessorData?.fileProcessor?.name || "",
    type: (fileProcessorData?.fileProcessor?.category || "") as FileProcessorCategory,
  };

  const handleSubmit = async (values: EditProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getEditProcessorInput(values, pipelineData.fileProcessingPipeline);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline", "FileProcessor"],
    });

    navigate(`/file-processing-pipeline/file-processor/${values.id}`);
    toast({ title: `${values.name} processor updated` });
    closeModal();
  };

  if (!pipelineData && pipelineDataLoading && !fileProcessorData && fileProcessorDataLoading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <EditProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseEditProcessorFormModalOptions {
  processorId?: string;
}

export const useEditProcessorFormModal = ({ processorId }: UseEditProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const processor = findNodeById(processorId, nodes);

  return {
    openEditProcessorForm: async () => {
      if (!processorId || !processor) {
        return;
      }

      await openModal(() => <EditProcessorForm processorId={processorId} />);
    },
  };
};

function getEditProcessorInput(
  values: EditProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"]
): CreatePipelineVersionInput | null {
  const pipelineDataCopy = cloneDeep(pipelineData);
  // QUESTION: Can we just use the `useNodes` and `useEdges` hooks here?
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipelineDataCopy);

  const currentNode = findNodeById(values.previousId, nodes);

  if (!currentNode) {
    return null;
  }

  const parentNode = findParentNodeByChildId(currentNode.id, nodes, edges);

  if (!parentNode) {
    return null;
  }

  const existingLabel = pipelineDataCopy.pipeline.transitions.find(
    (transition) => transition.label === parentNode.data.name && transition.sourceNodeName === parentNode.data.category
  );

  const existingProcessor = existingLabel?.destinationNodes.find((node) => node.id === values.previousId);

  if (!existingProcessor) {
    return null;
  }

  existingProcessor.id = values.id;

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineDataCopy.pipeline.initial,
      transitions: pipelineDataCopy.pipeline.transitions,
    },
  };
}
