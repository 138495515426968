import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** An arbitrary precision decimal value. Often used for dollar amounts */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: string | boolean | number | Record<string, any> | string[] | boolean[] | number[];
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: string & { __scalar: "UUID" };
};

export type AuthTokenInput = {
  /** JWT access token */
  accessToken: Scalars["String"];
  /** JWT refresh token */
  refreshToken: Scalars["String"];
};

export enum KnownIdentityProviders {
  Google = "Google",
}

export enum Roles {
  Admin = "Admin",
  Agent = "Agent",
  BindingAuthority = "BindingAuthority",
  Broker = "Broker",
  Brokerage = "Brokerage",
  Dj = "DJ",
  PreferredBroker = "PreferredBroker",
  UnderwritingAssistant = "UnderwritingAssistant",
}

export type AuthTokenFragment = { __typename?: "AuthToken"; accessToken: string; refreshToken: string };

export type ForgotPasswordMutationVariables = Exact<{
  authClientId: Scalars["String"];
  email: Scalars["String"];
}>;

export type ForgotPasswordMutation = { __typename?: "Mutation"; forgotPassword: boolean };

export type ImpersonateUserMutationVariables = Exact<{
  authClientId: Scalars["String"];
  userIdToImpersonate: Scalars["String"];
}>;

export type ImpersonateUserMutation = {
  __typename?: "Mutation";
  impersonateUser: {
    __typename?: "ImpersonationAuth";
    accessToken: string;
    user: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      agency: { __typename?: "Agency"; id: string; name: string };
    };
  };
};

export type LoginEmailPasswordMutationVariables = Exact<{
  authClientId: Scalars["String"];
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LoginEmailPasswordMutation = {
  __typename?: "Mutation";
  loginEmailPassword: {
    __typename?: "Auth";
    token: { __typename?: "AuthToken"; accessToken: string; refreshToken: string };
    user: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      agency: { __typename?: "Agency"; id: string; name: string };
    };
  };
};

export type LoginIdentityProviderMutationVariables = Exact<{
  authClientId: Scalars["String"];
  provider: KnownIdentityProviders;
  providerJwt: Scalars["String"];
}>;

export type LoginIdentityProviderMutation = {
  __typename?: "Mutation";
  loginIdentityProvider: {
    __typename?: "Auth";
    token: { __typename?: "AuthToken"; accessToken: string; refreshToken: string };
    user: {
      __typename?: "UserAccount";
      id: string & { __scalar: "UUID" };
      firstName: string;
      lastName: string;
      email: string;
      roles: Array<Roles>;
      agency: { __typename?: "Agency"; id: string; name: string };
    };
  };
};

export type LogoutMutationVariables = Exact<{
  refreshToken: Scalars["String"];
}>;

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean };

export type PasswordResetMutationVariables = Exact<{
  changePasswordId: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type PasswordResetMutation = { __typename?: "Mutation"; passwordReset: boolean };

export type RefreshTokenMutationVariables = Exact<{
  token: AuthTokenInput;
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken: { __typename?: "AuthToken"; accessToken: string; refreshToken: string };
};

export type MyAccountQueryVariables = Exact<{ [key: string]: never }>;

export type MyAccountQuery = {
  __typename?: "Query";
  myAccount: {
    __typename: "UserAccount";
    id: string & { __scalar: "UUID" };
    firstName: string;
    lastName: string;
    email: string;
    roles: Array<Roles>;
    internal: boolean;
    agencyId: string & { __scalar: "UUID" };
    agency: {
      __typename?: "Agency";
      id: string;
      name: string;
      isTest: boolean;
      owner?: {
        __typename?: "UserAccount";
        id: string & { __scalar: "UUID" };
        firstName: string;
        lastName: string;
        email: string;
      } | null;
    };
  };
};

export const AuthTokenFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "AuthToken" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AuthToken" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accessToken" } },
          { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthTokenFragment, unknown>;
export const ForgotPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ForgotPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "forgotPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "authClientId" },
                value: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ImpersonateUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ImpersonateUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userIdToImpersonate" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "impersonateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "authClientId" },
                value: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userIdToImpersonate" },
                value: { kind: "Variable", name: { kind: "Name", value: "userIdToImpersonate" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "roles" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImpersonateUserMutation, ImpersonateUserMutationVariables>;
export const LoginEmailPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginEmailPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "password" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginEmailPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "authClientId" },
                value: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: { kind: "Variable", name: { kind: "Name", value: "password" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AuthToken" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "roles" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AuthTokenFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LoginEmailPasswordMutation, LoginEmailPasswordMutationVariables>;
export const LoginIdentityProviderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginIdentityProvider" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "provider" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "KnownIdentityProviders" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "providerJwt" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginIdentityProvider" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "authClientId" },
                value: { kind: "Variable", name: { kind: "Name", value: "authClientId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "provider" },
                value: { kind: "Variable", name: { kind: "Name", value: "provider" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "providerJwt" },
                value: { kind: "Variable", name: { kind: "Name", value: "providerJwt" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AuthToken" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "roles" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "agency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AuthTokenFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<LoginIdentityProviderMutation, LoginIdentityProviderMutationVariables>;
export const LogoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Logout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "refreshToken" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "logout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "refreshToken" },
                value: { kind: "Variable", name: { kind: "Name", value: "refreshToken" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const PasswordResetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PasswordReset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "changePasswordId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "newPassword" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "passwordReset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "changePasswordId" },
                value: { kind: "Variable", name: { kind: "Name", value: "changePasswordId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "newPassword" },
                value: { kind: "Variable", name: { kind: "Name", value: "newPassword" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>;
export const RefreshTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RefreshToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "AuthTokenInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: { kind: "Variable", name: { kind: "Name", value: "token" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "AuthToken" } }],
            },
          },
        ],
      },
    },
    ...AuthTokenFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const MyAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MyAccount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "roles" } },
                { kind: "Field", name: { kind: "Name", value: "internal" } },
                { kind: "Field", name: { kind: "Name", value: "agencyId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "agency" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "isTest" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "owner" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                            { kind: "Field", name: { kind: "Name", value: "email" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyAccountQuery, MyAccountQueryVariables>;
