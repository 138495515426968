// Make sure Material Symbols and Rounded are selected and then snake case the
// names of the icons here:
// https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Symbols

import { cn } from "src/utils";

export type IconString =
  | "account_circle"
  | "add"
  | "add_box"
  | "add_circle"
  | "add_task"
  | "adjust"
  | "alternate_email"
  | "arrow_back"
  | "arrow_downward"
  | "arrow_forward"
  | "arrow_split"
  | "arrow_upward"
  | "attachment"
  | "attach_money"
  | "attach_file"
  | "assignment"
  | "assignment_returned"
  | "badge"
  | "bedtime"
  | "block"
  | "bookmark"
  | "bookmark_remove"
  | "build_circle"
  | "business_center"
  | "cached"
  | "call"
  | "campaign"
  | "cancel"
  | "check"
  | "check_circle"
  | "chevron_right"
  | "chevron_left"
  | "circle"
  | "close"
  | "cloud_upload"
  | "comment"
  | "content_copy"
  | "content_paste_search"
  | "copy_all"
  | "create_new_folder"
  | "folder"
  | "folder_data"
  | "forward_to_inbox"
  | "cycle"
  | "dark_mode"
  | "database"
  | "delete"
  | "description"
  | "diamond"
  | "display_settings"
  | "download"
  | "drive_export"
  | "edit"
  | "edit_note"
  | "eject"
  | "electric_bolt"
  | "emergency"
  | "error"
  | "event"
  | "export_notes"
  | "face"
  | "feature_search"
  | "fiber_manual_record"
  | "filter_list"
  | "flag"
  | "folder_open"
  | "front_hand"
  | "headphones"
  | "help"
  | "history"
  | "home"
  | "hourglass_empty"
  | "inbox"
  | "info"
  | "keyboard_arrow_down"
  | "keyboard_arrow_up"
  | "keyboard_double_arrow_left"
  | "keyboard_double_arrow_right"
  | "label"
  | "left_panel_close"
  | "left_panel_open"
  | "light_mode"
  | "link"
  | "list"
  | "location_city"
  | "location_on"
  | "lock"
  | "logout"
  | "lunch_dining"
  | "mail"
  | "menu"
  | "menu_book"
  | "merge"
  | "mode_heat"
  | "more_horiz"
  | "more_vert"
  | "notifications_paused"
  | "open_in_new"
  | "outgoing_mail"
  | "paid"
  | "pending"
  | "percent"
  | "person"
  | "person_search"
  | "personal_places"
  | "priority_high"
  | "psychology_alt"
  | "receipt_long"
  | "refresh"
  | "remove"
  | "replay"
  | "request_quote"
  | "right_panel_close"
  | "right_panel_open"
  | "rocket_launch"
  | "screen_search_desktop"
  | "search"
  | "send"
  | "sentiment_very_satisfied"
  | "settings_ethernet"
  | "shield"
  | "smart_toy"
  | "star"
  | "storefront"
  | "style"
  | "subdirectory_arrow_right"
  | "tab_duplicate"
  | "tag"
  | "text_fields"
  | "timeline"
  | "thumb_down"
  | "thumb_up"
  | "token"
  | "undo"
  | "unfold_less"
  | "unfold_more"
  | "unknown_document"
  | "upload"
  | "verified"
  | "view_kanban"
  | "visibility"
  | "visibility_off"
  | "warning";

interface IconProps {
  icon: IconString;
  className?: string;
}

export const Icon = ({ icon, className }: IconProps) => (
  <span className={cn("icon aspect-square flex-none", className)} data-icon={icon}>
    {icon}
  </span>
);
