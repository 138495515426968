import { isEqual } from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SidebarLinkClassName } from "@/components/sidebar";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { UUID } from "@cp/toolkit";
import {
  useCreateOpportunityMutation,
  useInsuredOpportunitiesQuery,
  useInsuredQuery,
  useVerticalByCglQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";

export const CreateMarketingPlan = () => {
  const { insuredId } = useParams();
  const [open, setOpen] = useState(false);

  const { data: { insured } = {}, loading } = useInsuredQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
  });

  const { data: { insuredOpportunities } = { insuredOpportunities: [] } } = useInsuredOpportunitiesQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
  });

  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: insured?.isoCglCodes[0] || "" } },
    skip: !insured?.isoCglCodes[0],
  });
  const { marketingPlanTemplates } = verticalByCGL || {};

  const validMarketingPlanTemplates =
    marketingPlanTemplates?.filter(
      (template) =>
        !insuredOpportunities.some((opportunity) => isEqual(opportunity.selectedLinesOfBusiness, template.lobs))
    ) || [];

  if (loading || !insured) {
    return null;
  }

  return (
    <>
      {validMarketingPlanTemplates[0] ? (
        <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
          <DialogTrigger asChild>
            <div className={cn(SidebarLinkClassName, LinkClassName)}>
              Create Marketing Plan
              <Icon icon="add_circle" className="filled" />
            </div>
          </DialogTrigger>
          <DialogContent className="gap-0 overflow-hidden p-0 rounded-b-lg">
            <DialogHeader className="bg-accent border-b p-6">
              <DialogTitle>Create Marketing Plan</DialogTitle>
            </DialogHeader>
            <div className="bg-border space-y-px" onClick={() => setOpen(false)}>
              <CreateMarketingPlanButton className={dialogClassName}>Custom Marketing Plan</CreateMarketingPlanButton>
              {validMarketingPlanTemplates.map((template) => (
                <CreateMarketingPlanButton
                  key={template.id}
                  selectedLinesOfBusiness={template.lobs}
                  className={dialogClassName}
                >
                  {template.lobs.join(", ")}
                </CreateMarketingPlanButton>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <CreateMarketingPlanButton>Create Marketing Plan</CreateMarketingPlanButton>
      )}
    </>
  );
};

const LinkClassName = "cursor-pointer font-semibold text-primary";
const dialogClassName = "bg-background border-none font-normal h-12 px-6 text-foreground text-sm";

const CreateMarketingPlanButton = ({
  children,
  className,
  selectedLinesOfBusiness,
}: {
  children: React.ReactNode;
  className?: string;
  selectedLinesOfBusiness?: string[];
}) => {
  const navigate = useNavigate();
  const { insuredId } = useParams();

  const [createOpportunity] = useCreateOpportunityMutation({
    onCompleted: (data) => navigate(`/insured/${insuredId}/plans/${data.createOpportunity.id}`),
  });

  return (
    <div
      className={cn(SidebarLinkClassName, LinkClassName, className)}
      onClick={() => {
        void createOpportunity({
          variables: {
            input: {
              insuredId: insuredId as UUID,
              desiredEffectiveDate: new Date(),
              selectedLinesOfBusiness,
            },
          },
          onCompleted: (data) => {
            navigate(`/insured/${insuredId}/plans/${data.createOpportunity.id}`);
          },
          refetchQueries: ["InsuredOpportunities"],
        });
      }}
    >
      {children}
      <Icon icon="add_circle" className="filled" />
    </div>
  );
};
