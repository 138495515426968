import { forwardRef } from "react";

import { Icon, type IconString } from "@/components/ui/icon";
import { Input, type InputProps } from "@/components/ui/input";
import { cn } from "src/utils";

export const Field = ({
  className,
  children,
  icon,
  iconClassName,
}: {
  className?: string;
  children: React.ReactNode;
  icon?: IconString;
  iconClassName?: string;
}) => (
  <label className={cn("bg-background flex gap-3 min-h-[3rem] items-center pl-6 pr-3", className)}>
    {icon && <Icon icon={icon} className={cn("text-muted-foreground", iconClassName)} />}
    {children}
  </label>
);

export const FieldInput = forwardRef<HTMLInputElement, InputProps & { icon: IconString }>(
  ({ icon, ...inputProps }, ref) => (
    <Field icon={icon}>
      <Input className={fieldInputClassName} ref={ref} {...inputProps} />
    </Field>
  )
);

FieldInput.displayName = Input.displayName;

export const fieldInputClassName =
  "border-none focus:ring-transparent focus:ring-offset-transparent focus-visible:ring-transparent focus-visible:ring-offset-transparent";
