import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { SidebarNavLink, SidebarSection } from "@/components/sidebar";
import { Spinner } from "@/components/ui/loading";
import { StateIndicator } from "@/metadata";
import { QuoteFragment, QuoteState, useQuotesQuery } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";

export const sentToAgent = (quote: QuoteFragment) =>
  quote.state === QuoteState.Delivered || quote.state === QuoteState.BindRequestReceived;

export const SidebarQuotes = () => {
  const { insuredId } = useParams();

  const { data: { quotes } = { quotes: [] }, loading } = useQuotesQuery({
    variables: {
      input: {
        insuredId: insuredId,
      },
    },
    skip: !insuredId,
  });

  if (loading) {
    return <Spinner />;
  }

  const drafts = quotes.filter((quote) => quote.state === QuoteState.Pending || quote.state === QuoteState.Processed);
  const proposals = quotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      <HasInternalRole>
        {drafts[0] && (
          <SidebarSection>
            <h6 className="text-muted-foreground">Quote Drafts</h6>
            <div className="space-y-4">
              {drafts.map((quote) => {
                const { id: quoteId, submission, premium, state } = quote;
                const { appetiteProduct } = submission;
                const { carrierName, carrierProductName } = appetiteProduct;
                return (
                  <SidebarNavLink key={quoteId} to={`quotes/${quoteId}`} className="block space-y-1">
                    <div className="flex gap-4 items-center justify-between">
                      <span className="truncate">
                        {carrierName}: {carrierProductName}
                      </span>
                      <StateIndicator state={state} displayLabel={false} />
                    </div>
                    <div className="text-2xs text-muted-foreground">{premium && formatDollars(premium)}</div>
                  </SidebarNavLink>
                );
              })}
            </div>
          </SidebarSection>
        )}
      </HasInternalRole>
      {proposals[0] && (
        <SidebarSection>
          <h6 className="text-muted-foreground">Quotes</h6>
          <div className="space-y-4">
            {proposals.map((quote) => {
              const { id: quoteId, submission, premium, state } = quote;
              const { appetiteProduct } = submission;
              const { carrierName, carrierProductName } = appetiteProduct;
              return (
                <SidebarNavLink key={quoteId} to={`quotes/${quoteId}`} className="block space-y-1">
                  <div className="flex gap-4 items-center justify-between">
                    <span className="truncate">
                      {carrierName}: {carrierProductName}
                    </span>
                    <StateIndicator state={state} displayLabel={false} />
                  </div>
                  <div className="text-2xs text-muted-foreground">{premium && formatDollars(premium)}</div>
                </SidebarNavLink>
              );
            })}
          </div>
        </SidebarSection>
      )}
    </>
  );
};
