import { Controller, UseFormReturn } from "react-hook-form";

import { DatePicker } from "@/components/ui/date-picker";

interface Props {
  formMethods: UseFormReturn<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  dateFormat?: string;
  onSelect?: (date: Date | undefined) => void;
}

export const DatePickerWithError = ({ formMethods, fieldName, dateFormat, onSelect }: Props) => (
  <Controller
    control={formMethods.control}
    name={fieldName}
    render={({ field: { onChange }, fieldState: { error } }) => (
      <>
        {error?.message && <span className="text-destructive text-xs ml-2">{error.message.toString()}</span>}
        <DatePicker
          dateFormat={dateFormat}
          placeholder="Select a Date"
          selected={formMethods.watch(fieldName)}
          onSelect={(e) => {
            onChange(e?.toISOString() ?? "");
            onSelect && onSelect(e);
          }}
          inputClassName="border"
          className="absolute bottom top right m-auto"
        />
      </>
    )}
  />
);
