import { Outlet } from "react-router";

import { NavigateAgency } from "@/components/navigate/navigate-agency";
import { ScrollPane } from "@/components/scroll-pane";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";

export const Broker = () => (
  <ScrollPane>
    <Bar>
      <BarLinks>
        <BarLink to="." end>
          Pinned Insureds
        </BarLink>
        <BarLink to="active">Active Marketing Plans</BarLink>
      </BarLinks>
      <NavigateAgency path="/insureds" buttonProps={{ variant: "outline", size: "sm" }} />
    </Bar>
    <Outlet />
  </ScrollPane>
);
