import { PopoverTrigger } from "@radix-ui/react-popover";
import { SelectTrigger } from "@radix-ui/react-select";
import { cn } from "src/utils";

import { Icon } from "./ui/icon";

export const Container = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section
    className={cn("@container/opp flex flex-auto flex-col min-w-0 p-3 pt-0 landscape:pl-0 space-y-3", className)}
    {...rest}
  >
    {children}
  </section>
);

export const ContainerHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <header className={cn("bg-accent min-h-16 overflow-hidden rounded-b-lg shadow-xs", className)} {...rest}>
    {children}
  </header>
);

export const ContainerHeaderTrigger = ({
  children,
  popover = false,
}: {
  children: React.ReactNode;
  popover?: boolean;
}) => {
  const Component = popover ? PopoverTrigger : SelectTrigger;

  return (
    <Component className="group bg-background flex items-center justify-between outline-none rounded shadow-contrast">
      {children}
      <Icon
        icon="keyboard_arrow_down"
        className="hidden @2xl/opp:inline mr-3 opacity-50 group-hover:opacity-100 transition-opacity"
      />
    </Component>
  );
};

export const ContainerHeaderCell = ({
  className,
  title,
  subtitle,
}: {
  className?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => (
  <div className={cn("flex flex-col gap-0.5 overflow-hidden px-3 py-1.5 relative rounded text-left", className)}>
    <h5 className="text-2xs @lg/opp:text-xs truncate">
      <span className="truncate">{title}</span>
    </h5>
    <h6 className="text-muted-foreground truncate">{subtitle}</h6>
  </div>
);
