import { useParams } from "react-router";

import { SidebarNavLink, SidebarSection } from "@/components/sidebar";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { StateIndicator } from "@/metadata";
import { MarketingPlanState, useInsuredOpportunitiesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

export const SidebarMarketingPlans = () => {
  const { insuredId } = useParams();

  const { data: { insuredOpportunities } = { insuredOpportunities: [] }, loading: loading } =
    useInsuredOpportunitiesQuery({
      variables: {
        id: insuredId ?? "",
      },
      skip: !insuredId,
    });

  if (loading) {
    return <Spinner />;
  }

  const activePlans = insuredOpportunities.filter((opp) => opp.state !== MarketingPlanState.Complete);

  if (!activePlans[0]) {
    return null;
  }

  return (
    <SidebarSection>
      <h6 className="text-muted-foreground">Active Marketing Plans</h6>
      <div className="space-y-4">
        {activePlans.map((opp) => (
          <SidebarNavLink key={opp.id} to={`plans/${opp.id}`} className="block space-y-1">
            <div className="flex gap-4 items-center justify-between">
              <span className="mr-auto truncate">
                {opp.selectedLinesOfBusiness[0] ? opp.selectedLinesOfBusiness.join(", ") : "No Coverage Selected"}
              </span>
              {opp.renewalOf && <Icon icon="cycle" />}
              <StateIndicator state={opp.state} displayLabel={false} />
            </div>
            <div className="text-2xs text-muted-foreground">
              {formatTimezoneDateString(opp.desiredEffectiveDate, "MMM d, yyyy")}
            </div>
          </SidebarNavLink>
        ))}
      </div>
    </SidebarSection>
  );
};
