import { MyAccountQuery, useMyAccountQuery } from "src/generated/graphql";

interface useMyAccountOptions {
  fetchPolicy?: "cache-first" | "cache-and-network" | "network-only";
  onCompleted: (data: MyAccountQuery) => void;
}

export const useMyAccount = (options?: useMyAccountOptions) => {
  const result = useMyAccountQuery({
    fetchPolicy: options?.fetchPolicy ?? "cache-first",
    onCompleted: options?.onCompleted,
  }).data?.myAccount;
  return {
    data: result,
  };
};
