import { useAtom } from "jotai";

import { pinnedInsuredsAtom } from "@/atoms";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { cn } from "src/utils";

export const InsuredPin = ({ id, className }: { id: string; className?: string }) => {
  const [pinnedIds, setPinnedIds] = useAtom(pinnedInsuredsAtom);

  const addPin = () => {
    const pins = {
      [id]: id,
      ...pinnedIds,
    };
    void setPinnedIds(pins);
  };

  const removePin = () => {
    const { [id]: deleted, ...rest } = pinnedIds;
    void setPinnedIds(rest);
  };

  const pinned = id in pinnedIds;

  return (
    <Button
      variant="ghost"
      size="icon-sm"
      className={cn(
        "!bg-transparent",
        pinned ? "text-amber-500 hover:text-amber-600" : "text-muted-foreground/20 hover:text-amber-500",
        className
      )}
      onClick={(e) => {
        e.preventDefault();
        pinned ? removePin() : addPin();
      }}
    >
      <Icon icon="star" className="filled" />
    </Button>
  );
};
