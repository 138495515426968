import { groupBy, orderBy } from "lodash";
import { useParams } from "react-router";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { ExpiredPolicyState, StateIndicator } from "@/metadata";
import { PolicyState, usePoliciesQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";

const now = Date.now();

export const Policies = () => {
  const { insuredId } = useParams();

  const { data: { policies } = { policies: [] }, loading } = usePoliciesQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const sortedPolicies = orderBy(policies, (policy) => Date.parse(policy.effectiveDate));
  const groupedPolicies = groupBy(sortedPolicies, (policy) => formatTimezoneDateString(policy.effectiveDate, "yyyy"));

  return (
    <>
      {policies[0] ? (
        Object.keys(groupedPolicies).map((date) => (
          <div key={date}>
            <SectionHeader>
              <SectionTitle>
                <h2>{date}</h2>
              </SectionTitle>
            </SectionHeader>
            <Grid className="grid-cols-5">
              <GridRowHeader>
                <GridCell>Carrier</GridCell>
                <GridCell>Product</GridCell>
                <GridCell>Status</GridCell>
                <GridCell>Eff. Date</GridCell>
                <GridCell>Exp. Date</GridCell>
              </GridRowHeader>
              {groupedPolicies[date].map((policy) => {
                const { id, effectiveDate, expirationDate, state, appetiteProduct } = policy;
                const expired = Date.parse(expirationDate) < now;
                return (
                  <GridRowLink key={id} to={id}>
                    <GridCell>{appetiteProduct.carrierName}</GridCell>
                    <GridCell>{appetiteProduct.carrierProductName}</GridCell>
                    <StateIndicator
                      state={expired && state == PolicyState.Issued ? ExpiredPolicyState.Expired : state}
                    />
                    <GridCell>{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</GridCell>
                    <GridCell>{formatTimezoneDateString(expirationDate, "MMM d, yyyy")}</GridCell>
                  </GridRowLink>
                );
              })}
            </Grid>
          </div>
        ))
      ) : (
        <EmptyState loading={loading} title={<h2>No policies found.</h2>} />
      )}
    </>
  );
};
