import { UUID } from "@cp/toolkit";
import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { Separator } from "@/components/ui/separator";
import { QuoteState, useQuoteQuery } from "src/generated/graphql";
import { QuoteActions } from "./actions/quote-actions";
import { QuoteFiles } from "./quote-files";
import { QuoteInfo } from "./quote-info";
import { QuoteProposalForm } from "./quote-proposal-form";

export const Quote = () => {
  const { quoteId } = useParams<"quoteId">();

  const { data: { quote } = {} } = useQuoteQuery({
    variables: {
      id: quoteId ?? quoteId!,
    },
    skip: !quoteId,
    fetchPolicy: "cache-and-network",
  });

  if (!quote) {
    return null;
  }

  return (
    <>
      {quote.state === QuoteState.Pending ? <QuoteProposalForm quote={quote} /> : <QuoteInfo quote={quote} />}
      <Separator />
      <AgentMessage state={quote.state} wrapperClassName="mx-6 mt-6" />
      <ScrollPaneColumns className="flex-col-reverse grid-cols-[1fr_24em]">
        <Activity opportunityId={quote.submission.opportunityId} />
        <div className="space-y-6">
          <HasInternalRole>
            <Notes opportunityId={quote.submission.opportunityId as UUID} />
          </HasInternalRole>
          <QuoteFiles quote={quote} />
          <HasInternalRole>
            <QuoteActions quote={quote} />
          </HasInternalRole>
        </div>
      </ScrollPaneColumns>
    </>
  );
};
