import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { MoreMenu } from "@/components/more-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Autocomplete } from "@/components/ui/autocomplete";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { toast } from "@/components/ui/use-toast";
import { reloadOpportunity } from "@/hooks/use-oopportunity";
import {
  useDeleteInsuredMutation,
  useInsuredQuery,
  useInsuredsQuery,
  useMergeInsuredMutation,
} from "src/generated/graphql";
import { formatDate } from "src/utils/date";

export const InsuredDetailsMenu = () => {
  const { insuredId } = useParams<"insuredId">();
  const [dialogVariant, setDialogVariant] = useState<"delete" | "merge" | null>(null);

  if (!insuredId) {
    return null;
  }

  return (
    <HasInternalRole>
      <AlertDialog>
        <MoreMenu>
          <AlertDialogTrigger>
            <DropdownMenuItem onClick={() => setDialogVariant("merge")}>
              <Icon icon="merge" /> Merge Insured
            </DropdownMenuItem>
            <DropdownMenuItem className="text-destructive" onClick={() => setDialogVariant("delete")}>
              <Icon icon="delete" /> Delete Insured
            </DropdownMenuItem>
          </AlertDialogTrigger>
        </MoreMenu>
        {dialogVariant === "merge" && <MergeDialog />}
        {dialogVariant === "delete" && <DeleteDialog />}
      </AlertDialog>
    </HasInternalRole>
  );
};

const MergeDialog = () => {
  const { insuredId } = useParams<"insuredId">();
  const navigate = useNavigate();
  const { data: { insured } = {} } = useInsuredQuery({ variables: { id: insuredId || "" } });
  const { data: { insureds = [] } = {} } = useInsuredsQuery({
    variables: { input: { agencyId: insured?.agency?.id as UUID } },
  });
  const [mergeInsureds] = useMergeInsuredMutation();
  const [destinationInsuredId, setDestinationInsuredId] = useState<string | null>(null);

  const otherInsureds = insureds.filter((i) => i.id !== insuredId);

  const handleMerge = async (survivorId: string) => {
    await mergeInsureds({
      variables: {
        input: {
          deprecatedId: insuredId as UUID,
          survivorId: survivorId as UUID,
        },
      },
      onCompleted: (data) => {
        toast({ title: "Insured Merged" });
        navigate(`/insured/${data.mergeInsured.id}`);
      },
      onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
    });
  };

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Merge Insured</AlertDialogTitle>
        <AlertDialogDescription>
          Select an Insured to merge into. The current Insured will be deleted, and its resources will be transferred to
          the selected Insured.
          <Autocomplete
            options={otherInsureds}
            selected={otherInsureds?.find((i) => i.id === destinationInsuredId)}
            onSelect={(option) => setDestinationInsuredId(option.id)}
            toValue={(option) => option.name}
            toLabel={(option) =>
              `${option.name} (created ${formatDate(new Date(option.createdAt), "MM/dd/yy h:mm a")})`
            }
            placeholder="Search Insureds"
          />
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction
          disabled={!destinationInsuredId}
          onClick={() => {
            if (destinationInsuredId) {
              void handleMerge(destinationInsuredId);
            }
          }}
          variant="destructive"
        >
          Continue
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};

const DeleteDialog = () => {
  const { insuredId } = useParams<"insuredId">();
  const navigate = useNavigate();
  const [deleteInsured] = useDeleteInsuredMutation();

  if (!insuredId) {
    return null;
  }

  const handleDelete = async () => {
    await deleteInsured({
      variables: { id: insuredId },
      onCompleted: () => {
        toast({ title: "Insured Deleted" });
        navigate(`/insureds`);
      },
      onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
      refetchQueries: [...reloadOpportunity.refetchQueries, "InsuredOpportunities"],
    });
  };
  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Delete Insured</AlertDialogTitle>
        <AlertDialogDescription>Are you sure?</AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={handleDelete} variant="destructive">
          Continue
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
