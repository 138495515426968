import { Link, useNavigate, useParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { MoreMenu } from "@/components/more-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useToast } from "@/components/ui/use-toast";
import { useMyAccount } from "@/hooks/use-my-account";
import { reloadOpportunity, useOpportunity } from "@/hooks/use-oopportunity";
import { StatePill } from "@/metadata";
import {
  OpportunityDetailsFragment,
  Roles,
  useDeleteOpportunityMutation,
  UserAccountFragment,
  useReassignOpportunityMutation,
} from "src/generated/graphql";
import { GoogleDriveButton } from "../files/google-drive-button";
import { CreateEmail } from "./create-email";
import { SetBroker } from "./set-broker";
import { TestAlby } from "./test-alby";

export const MarketingPlanMenu = () => {
  const { opportunity } = useOpportunity();

  if (!opportunity) {
    return null;
  }

  const { id, broker, state } = opportunity;

  return (
    <>
      <TestAlby />
      <SetBroker id={id} broker={broker as UserAccountFragment} />
      <StatePill state={state} />
      <MarketingPlanDropdown opportunity={opportunity} />
    </>
  );
};

const MarketingPlanDropdown = ({ opportunity }: { opportunity: OpportunityDetailsFragment }) => {
  const { data: user } = useMyAccount();
  const { opportunityId } = useParams();

  const navigate = useNavigate();
  const { toast } = useToast();
  const [deleteOpportunity] = useDeleteOpportunityMutation();

  const { id, broker, insured, insuredId, submissions } = opportunity;

  const allowReassignment = user?.roles.includes(Roles.Dj) || user?.roles.includes(Roles.Admin) || broker === null;

  const [reassignOpportunity] = useReassignOpportunityMutation({
    onCompleted: () => {
      toast({ title: "Success" });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  const handleDelete = async () => {
    await deleteOpportunity({
      variables: {
        id: id,
      },
      onCompleted: () => {
        toast({ title: "Marketing Plan Deleted" });
        navigate(`/insured/${insuredId}`);
      },
      onError: () => {
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
      refetchQueries: [...reloadOpportunity.refetchQueries, "InsuredOpportunities"],
    });
  };

  const hasQuotes = submissions.some((sub) => sub.quotes.length > 0);

  return (
    <AlertDialog>
      <MoreMenu>
        <DropdownMenuItem
          onClick={async () => {
            const link = `${window.location.origin}/insured/${insuredId}/${id}`;
            await navigator.clipboard.writeText(link);
            toast({ title: "Copied Link to Clipboard", description: link });
          }}
        >
          <Icon icon="link" /> Copy Link to Clipboard
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => window.open(window.location.href, "_blank")}>
          <Icon icon="tab_duplicate" /> Open in New Tab
        </DropdownMenuItem>
        <HasInternalRole>
          <DropdownMenuSeparator />
          <GoogleDriveButton opportunityId={id} brokerageFolderId={insured.brokerageFolderId} />
          <CreateEmail opportunityId={id} />
          <DropdownMenuSeparator />
          <Link to={`plans/${opportunityId}/escape-hatch`}>
            <DropdownMenuItem>
              <Icon icon="eject" />
              Binding Authority Escape Hatch
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem
            disabled={!allowReassignment}
            onClick={() => {
              void reassignOpportunity({
                variables: {
                  id: id,
                },
              });
            }}
          >
            <Icon icon="account_circle" /> Reassign Marketing Plan
          </DropdownMenuItem>
          {!hasQuotes && (
            <AlertDialogTrigger asChild>
              <DropdownMenuItem className="text-destructive">
                <Icon icon="delete" /> Delete Marketing Plan
              </DropdownMenuItem>
            </AlertDialogTrigger>
          )}
        </HasInternalRole>
      </MoreMenu>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Marketing Plan</AlertDialogTitle>
          <AlertDialogDescription>Are you sure?</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} variant="destructive">
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
