import { Link } from "react-router-dom";

import { NoActions } from "@/components/no-actions";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PolicyFragment, PolicyState } from "src/generated/graphql";
import { AwaitingFromCarrier } from "./awaiting-from-carrier";
import { Issued } from "./issued";
import { ReceivedFromCarrier } from "./recieved-from-carrier";

export const PolicyActions = ({ policy }: { policy: PolicyFragment }) => {
  const renderPolicyState = () => {
    switch (policy.state) {
      case PolicyState.AwaitingFromCarrier:
        return <AwaitingFromCarrier policy={policy} />;
      case PolicyState.ReceivedFromCarrier:
        return <ReceivedFromCarrier policy={policy} />;
      case PolicyState.Issued:
        return <Issued policy={policy} />;
      default:
        return <NoActions />;
    }
  };

  const { bindHqPolicyId, insuredId, opportunityId, quoteId } = policy;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Actions</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`https://quotewell.bindhq.com/secure/policies/${bindHqPolicyId}`} target="_blank">
            View Policy in BindHQ
          </Link>
        </Button>
        {renderPolicyState()}
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`/insured/${insuredId}/quotes/${quoteId}`}>Go to Quote</Link>
        </Button>
        <Button variant="outline" size="sm" display="flex" asChild>
          <Link to={`/insured/${insuredId}/plans/${opportunityId}`}>Go to Marketing Plan</Link>
        </Button>
      </CardContent>
    </Card>
  );
};
