import { UUID } from "@cp/toolkit";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useBrokerAccountsQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export function SelectBroker({ brokerId, onSelect }: { brokerId?: UUID; onSelect: (ownerId: UUID) => void }) {
  const { data: brokersData } = useBrokerAccountsQuery();
  const selectedBroker = brokersData?.userAccounts.find((broker) => broker.id === brokerId);

  return (
    <Select value={selectedBroker?.id || "-"} onValueChange={onSelect}>
      <SelectTrigger className={cn({ "text-muted-foreground/50": !brokerId })}>
        <SelectValue placeholder="Agency Owner" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="-">Unassigned</SelectItem>
        {brokersData?.userAccounts.map((broker) => (
          <SelectItem key={broker.id} value={broker.id}>{`${broker.firstName} ${broker.lastName}`}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
