import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { Roles } from "src/generated/graphql";
import { MarketsListMetricDisplayType } from "./marketing-plans/select-markets/product";

export const bankanTabAtom = atomWithStorage("bankanTab", Roles.Broker);
export const eventsDescAtom = atomWithStorage("desc", true);
export const eventsOpportunityAtom = atomWithStorage("opportunity", true);
export const eventsSubmissionAtom = atomWithStorage("submission", true);
export const stateTransitionAtom = atomWithStorage("transitions", true);
export const eventsCommentAtom = atomWithStorage("comment", true);
export const eventsEmailAtom = atomWithStorage("email", true);
export const eventsBotAtom = atomWithStorage("bot", true);
export const eventsUploadAtom = atomWithStorage("upload", true);
export const pinnedInsuredsAtom = atomWithStorage<Record<string, string>>("pinnedInsuredIds", {});
export const selectTagsAtom = atom<string>("");
export const foldersVisibleAtom = atom<boolean>(true);
export const actionsBrokerAtom = atomWithStorage("actionsBroker", "");
export const fileLabelsAtom = atomWithStorage("fileLabels", true);
export const deletedFilesAtom = atomWithStorage("deletedFiles", false);
export const correspondenceAtom = atomWithStorage("correspondence", true);
export const MarketsListMetricDisplayTypeAtom = atomWithStorage(
  "MarketsListMetricDisplayType",
  MarketsListMetricDisplayType.PreviouslyQuotedCount
);
