import { KnownClientData } from "@qw/qw-common";

import { PdfInput, PdfNumber } from "@/forms/pdf";

export const GeneralInformation = () => (
  <table>
    <thead>
      <tr>
        <td colSpan={4} className="big">
          General Information
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <PdfInput colSpan={3} label="First Named Insured" name={KnownClientData.FirstNamedInsured} />
        <PdfInput label="Business Entity Type" name={KnownClientData.BusinessEntityType} />
      </tr>
      <tr>
        <PdfInput colSpan={3} label="DBA" name={KnownClientData.DBA} />
        <PdfNumber label="Year Established" name={KnownClientData.YearEstablished} />
      </tr>
      <tr>
        <PdfInput colSpan={4} label="States Operating In" name={KnownClientData.StatesOfOperation} />
      </tr>
    </tbody>
  </table>
);
