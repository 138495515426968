import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

import { HasInternalRole } from "@/components/has-role";
import { SectionContent, SectionDescription, SectionFooter, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { Reform } from "@/forms/reform";
import { useCreateBindingAuthorityExclusionMutation } from "src/generated/graphql";

const FormSchema = z.object({
  tiv: z.string().optional(),
  revenue: z.string().optional(),
  limit: z.string().optional(),
  state: z.string().optional(),
  operations: z.string().optional(),
  other: z.string().optional(),
});

export const EscapeHatch = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams<"opportunityId">();

  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const { toast } = useToast();

  const [update] = useCreateBindingAuthorityExclusionMutation();

  const errors = formMethods.formState.errors;

  return (
    <HasInternalRole>
      <SectionHeader>
        <SectionTitle>
          <h2>Binding Authority Escape Hatch</h2>
        </SectionTitle>
        <SectionDescription>
          Please provide details on why this opportunity is not a fit for binding authority. All fields are optional but
          please be as descriptive as possible.
        </SectionDescription>
      </SectionHeader>
      <Reform
        schema={FormSchema}
        methods={formMethods}
        id="ESCAPE_HATCH_FORM"
        onSubmit={(_, values) => {
          void update({
            variables: {
              input: {
                ...values,
                opportunityId: opportunityId ?? "",
              },
            },
            onError: (e: any) => {
              toast({ title: e.message });
            },
            onCompleted: () => {
              toast({ title: `Opportunity reassigned with details` });
              navigate("..");
            },
          });
        }}
        defaultValues={{}}
      >
        <SectionContent className="gap-4 grid grid-cols-2">
          <div className="space-y-2">
            <h5>TIV threshold</h5>
            <Input {...formMethods.register("tiv")} />
            <div className="text-destructive">{errors?.tiv?.message}</div>
          </div>
          <div className="space-y-2">
            <h5>Revenue threshold</h5>
            <Input {...formMethods.register("revenue")} />
            <div className="text-destructive">{errors?.revenue?.message}</div>
          </div>
          <div className="space-y-2">
            <h5>Limit reason</h5>
            <Input {...formMethods.register("limit")} />
            <div className="text-destructive">{errors?.limit?.message}</div>
          </div>
          <div className="space-y-2">
            <h5>State</h5>
            <Input {...formMethods.register("state")} />
            <div className="text-destructive">{errors?.state?.message}</div>
          </div>
          <div className="space-y-2">
            <h5>Operations</h5>
            <Input {...formMethods.register("operations")} />
            <div className="text-destructive">{errors?.operations?.message}</div>
          </div>
          <div className="space-y-2">
            <h5>Other</h5>
            <Input type="text" {...formMethods.register("other")} />
            <div className="text-destructive">{errors?.other?.message}</div>
          </div>
        </SectionContent>
        <SectionFooter>
          <Button type="submit">Save</Button>
        </SectionFooter>
      </Reform>
    </HasInternalRole>
  );
};
