import {
  Collapsible,
  CollapsibleContent,
  CollapsibleContentProps,
  CollapsibleProps,
  CollapsibleTrigger,
  CollapsibleTriggerProps,
} from "@radix-ui/react-collapsible";

import { cn } from "src/utils";
import { Icon } from "./icon";

export const CollapsibleList: React.FC<CollapsibleProps> = ({ children, className, ...props }) => (
  <Collapsible {...props} className={className}>
    {children}
  </Collapsible>
);

export const CollapsibleListTrigger: React.FC<CollapsibleTriggerProps & { count?: number }> = ({
  children,
  className,
  count,
}) => (
  <CollapsibleTrigger
    className={cn(
      "group bg-accent border-b cursor-pointer flex gap-3 h-10 items-center px-6 sticky top-0 text-muted-foreground text-left text-xs w-full z-1",
      className
    )}
  >
    <span className="flex-auto truncate">{children}</span>
    {count !== undefined && <span className="text-2xs">{count}</span>}
    <Icon icon="unfold_more" className="ml-auto group-data-[state=open]:hidden" />
    <Icon icon="unfold_less" className="ml-auto group-data-[state=closed]:hidden" />
  </CollapsibleTrigger>
);

export const CollapsibleListContent: React.FC<CollapsibleContentProps> = ({ children, className }) => (
  <CollapsibleContent className={cn("bg-border border-b space-y-px", className)}>{children}</CollapsibleContent>
);
