import { createContext, useState } from "react";
import { useParams } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Dialog, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { InternalDropzone } from "@/files/internal-dropzone";
import {
  File_Audience,
  MarketingPlanState,
  OpportunityRequirementsQuery,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { RequirementsActions } from "./actions/requirements-actions";
import { AddRequirements } from "./add-requirements";
import { RequirementsList } from "./requirements-list";

export interface RequirementsContextData {
  opportunity: OpportunityRequirementsQuery["opportunity"];
  refetch: () => void;
}

export const RequirementsContext = createContext<RequirementsContextData>({
  opportunity: null,
  refetch: () => {},
});

export const Requirements = () => {
  const { opportunityId } = useParams();
  const [addRequirementsOpen, setAddRequirementsOpen] = useState(false);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: { id: opportunityId ?? "" },
    pollInterval: 2000,
  });

  if (
    !opportunity ||
    opportunity.state === MarketingPlanState.WaitingForCoverages ||
    opportunity.state === MarketingPlanState.Complete
  ) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <RequirementsContext.Provider value={{ opportunity, refetch }}>
      <Card>
        <InternalDropzone audience={File_Audience.External} onDrop={() => refetch()}>
          <SectionHeader>
            <SectionTitle>
              <h3>Required Information</h3>
              <HasInternalRole>
                <div className="flex gap-2 items-center" onClick={(e) => e.stopPropagation()}>
                  <RequirementsActions opportunity={opportunity} refetch={refetch} />
                  <Dialog open={addRequirementsOpen} onOpenChange={setAddRequirementsOpen}>
                    <DialogTrigger asChild>
                      <Button variant="outline" size="sm" onClick={() => setAddRequirementsOpen(true)}>
                        Add Requirement
                      </Button>
                    </DialogTrigger>
                    <DialogHeader className="hidden">
                      <DialogTitle>Add Requirement</DialogTitle>
                    </DialogHeader>
                    <AddRequirements opportunityId={opportunity.id} onAdded={() => setAddRequirementsOpen(false)} />
                  </Dialog>
                </div>
              </HasInternalRole>
            </SectionTitle>
            <SectionDescription>Drag and drop files here, or click here to start an upload.</SectionDescription>
          </SectionHeader>
          <RequirementsList />
        </InternalDropzone>
      </Card>
    </RequirementsContext.Provider>
  );
};
