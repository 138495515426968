import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Icon, IconString } from "@/components/ui/icon";

export const MoreMenu = ({ children, icon = "more_vert" }: { children: React.ReactNode; icon?: IconString }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" size="icon-sm" className="!bg-transparent rounded-full hover:text-foreground">
        <Icon icon={icon} className="font-bold" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">{children}</DropdownMenuContent>
  </DropdownMenu>
);
