import { ReactNode } from "react";
import { Button, ButtonProps } from "./ui/button";
import { Icon, IconString } from "./ui/icon";
import { toast } from "./ui/use-toast";

export const Clippy = ({
  content,
  icon = "copy_all",
  label,
  title = "Copied to clipboard",
  className,
  ...props
}: ButtonProps & {
  content: string;
  icon?: IconString;
  label?: ReactNode;
  title?: string;
}) => (
  <Button
    variant="outline"
    size="sm"
    className={className}
    onClick={async (e) => {
      e.preventDefault();
      await navigator.clipboard.writeText(content);
      toast({ title, description: content });
    }}
    {...props}
  >
    {label}
    <Icon icon={icon} />
  </Button>
);
