import { UUID } from "@cp/toolkit";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Input } from "@/components/ui/input";
import { Spinner } from "@/components/ui/loading";
import { RelativeDate } from "@/components/ui/relative-date";
import { toast } from "@/components/ui/use-toast";
import { useCreateOpportunityNoteMutation, useOpportunityDetailsQuery } from "src/generated/graphql";

export const Notes = ({ opportunityId }: { opportunityId: UUID }) => {
  const [text, setText] = useState("");
  const [saving, setSaving] = useState(false);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityDetailsQuery({
    variables: {
      id: opportunityId ?? "",
    },
    fetchPolicy: "cache-and-network",
    skip: !opportunityId,
  });

  const [createNote] = useCreateOpportunityNoteMutation();

  if (!opportunity) {
    return null;
  }

  const onSubmit = async () => {
    setSaving(true);
    await createNote({
      variables: {
        input: {
          opportunityId: opportunity.id,
          text,
        },
      },
      onCompleted() {
        setSaving(false);
        setText("");
        return refetch();
      },
      onError: () => {
        setSaving(false);
        toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Kate&apos;s Notes by Kate</CardTitle>
        <CardDescription>A Kate Mazza Production</CardDescription>
      </CardHeader>
      {loading && <Spinner className="m-6 mt-0" />}
      {opportunity.notes[0] && (
        <CardContent className="space-y-6">
          {opportunity.notes.map((note) => (
            <div key={note.id} className="space-y-1.5">
              <div className="text-xs">{note.text}</div>
              <footer className="flex gap-1 items-center text-2xs text-muted-foreground">
                <strong>
                  {note.creator.firstName} {note.creator.lastName}
                </strong>
                <RelativeDate date={note.createdAt} />
              </footer>
            </div>
          ))}
        </CardContent>
      )}
      <footer className="p-3 pt-0 relative">
        <form
          className="relative"
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
        >
          <Input
            name="note"
            value={text}
            placeholder="Would you like to add a note, Kate?"
            onChange={({ target }) => setText(target.value)}
            className="bg-accent border-none flex-auto pl-3 pr-14 py-5 focus-visible:ring-0 focus-visible:ring-offset-0 rounded-md shadow-inner text-xs"
          />
          <Button
            type="submit"
            variant="ghost"
            size="icon-sm"
            className="absolute h-6 inset-0 left-auto mr-3 my-auto !text-primary w-6"
          >
            {saving ? <Spinner /> : <Icon icon="send" />}
          </Button>
        </form>
      </footer>
    </Card>
  );
};
