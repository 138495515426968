import { MutationHookOptions, QueryHookOptions } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router";

import {
  Exact,
  OnOpportunityStateChangedDocument,
  OnOpportunityStateChangedSubscription,
  OnOpportunityStateChangedSubscriptionVariables,
  OpportunityDetailsQuery,
  OpportunityUpdateInput,
  UpdateOpportunityMutation,
  useOpportunityDetailsQuery,
  useUpdateOpportunityMutation,
} from "src/generated/graphql";

export const useOpportunity = (options?: QueryHookOptions<OpportunityDetailsQuery, Exact<{ id: string }>>) => {
  const { opportunityId } = useParams<"opportunityId">();
  const { data, loading, error, refetch, subscribeToMore } = useOpportunityDetailsQuery({
    variables: { id: opportunityId! },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
    ...options,
  });

  useEffect(() => {
    if (!opportunityId) {
      return;
    }
    return subscribeToMore<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>({
      document: OnOpportunityStateChangedDocument,
      variables: { id: opportunityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const opp = Object.assign({}, prev.opportunity, { state: subscriptionData.data.opportunityStateChanged.state });

        return { opportunity: opp };
      },
      onError: (error) => {
        console.error("Subscription error:", error);
      },
    });
  }, [opportunityId]);

  return {
    opportunity: data?.opportunity,
    loading,
    error,
    refetch,
  };
};

export const useUpdateOpportunity = (
  options?: MutationHookOptions<
    UpdateOpportunityMutation,
    Exact<{
      input: OpportunityUpdateInput;
    }>
  >
) => useUpdateOpportunityMutation({ refetchQueries: ["OpportunityDetails"], ...options });

export const reloadOpportunity = { refetchQueries: ["OpportunityDetails"] };
