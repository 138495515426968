import * as Sentry from "@sentry/react";
import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { authDataAtom } from "../atoms/atoms";
import { useAuthContext } from "../context/AuthContext";
import { LogoutDocument, LogoutMutationVariables } from "../generated/operations";

export default function useLogout() {
  const { fetcher } = useAuthContext();
  const [authData, setAuthData] = useAtom(authDataAtom);

  return useMutation(
    (args: LogoutMutationVariables) =>
      fetcher(LogoutDocument, args, {
        Authorization: `Bearer ${authData?.token.accessToken}`,
      }).then((response) => response.logout),
    {
      onSettled: () => {
        Sentry.configureScope((scope) => scope.setUser(null));
        setAuthData(null);
      },
    }
  );
}
