import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { BrokerFace } from "@/components/ui/brokerface";

export const ErrorMessage = () => {
  const error = useRouteError() as Error;
  useDocumentTitle("Error: QuoteWell");
  console.error(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <>
      <h1 className="text-3xl">We&apos;re sorry, an error occured</h1>
      <p className="my-4">However, we can still help!</p>
      <BrokerFace header="Click here to email us." className="inline-flex" />
    </>
  );
};
