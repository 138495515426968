export const abbreviateLobs = (lobs: string[]): string => {
  let abbreviation = "";

  lobs.forEach((lob, i) => {
    abbreviation += i > 0 ? `, ${abbreviateLob(lob)}` : `${abbreviateLob(lob)}`;
  });

  return abbreviation.trimEnd();
};

export const abbreviateLob = (line: string): string => {
  if (line === "D&O") {
    return "D&O";
  } else if (line.includes("HNOA")) {
    return "HNOA";
  } else if (line.includes("BOP")) {
    return "BOP";
  } else if (line.includes("EPLI")) {
    return "EPLI";
    // eslint-disable-next-line unicorn/prefer-switch
  } else if (line === "E&O/Professional Liability") {
    return "E&O/PL";
  } else if (line === "Tech E&O") {
    return "Tech.E&O";
  } else if (line === "Garage Liability") {
    return "GARAGE";
  } else if (line === "Cyber") {
    return "CYB";
  } else if (line === "SML") {
    return "SML";
  } else if (line.startsWith("Excess Liability")) {
    return "XS";
  } else if (line === "Contractors Pollution") {
    return "CPL";
  } else if (line === "Property") {
    return "PROP";
  } else {
    return getAbbreviation(line);
  }
};

const getAbbreviation = (name: string) =>
  name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
