import { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonProps } from "@/components/ui/button";

export interface FormResetProps extends ButtonProps {}

export const FormReset: FC<FormResetProps> = ({
  children = "Cancel",
  variant = "outline",
  onClick,
  disabled,
  ...props
}) => {
  const { formState, reset } = useFormContext();

  const isDisabled = useMemo(() => {
    if (typeof disabled === "boolean") {
      return disabled;
    }

    return formState.isSubmitting;
  }, [disabled, formState.isSubmitting]);

  const handleClick: FormResetProps["onClick"] = (event) => {
    reset();
    onClick?.(event);
  };

  return (
    <Button {...props} type="button" variant={variant} disabled={isDisabled} onClick={handleClick}>
      {children}
    </Button>
  );
};
