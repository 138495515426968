import { useNodes, type Node } from "@xyflow/react";
import { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { useToast } from "@/components/ui/use-toast";
import { ButtonGroup } from "@/forms-v2/button-group";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { Form } from "@/forms-v2/form";
import { FormGroup } from "@/forms-v2/form-group";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import {
  CreatePipelineVersionInput,
  FileProcessingNodeType,
  FileProcessingPipelineQuery,
  FileProcessorCategory,
  useCreateFilePipelineVersionMutation,
  useFileProcessingPipelineQuery,
} from "src/generated/graphql";
import { cn } from "src/utils";

import { processorCategoryToIconMap } from "../../file-processing-pipeline.constants";
import {
  convertPipelineDataToNodesAndEdges,
  findNodeById,
  getNodeIcon,
  getNodeLabel,
} from "../../file-processing-pipeline.helpers";

export interface AddProcessorFormValues {
  id: string;
  type: FileProcessorCategory;
  name: string;
}

export interface AddProcessorFormProps {
  sourceNode: Node;
  targetNode?: Node;
}

const defaultValues = {
  id: "",
  type: FileProcessorCategory.Classifier,
  name: "",
};

export const AddProcessorFormContent: FC<AddProcessorFormProps> = ({ sourceNode, targetNode }) => {
  const formContext = useFormContext();
  const { closeModal } = useModal();

  const type = formContext.watch("type");
  const name = formContext.watch("name");
  const icon = processorCategoryToIconMap[type as FileProcessorCategory];

  const isValid = formContext.formState.isValid;
  const isError = Object.keys(formContext.formState.errors)?.length;

  return (
    <FormGroup className="gap-6 max-w-full">
      <DialogHeader>
        <DialogTitle>Add a processor</DialogTitle>
        <DialogDescription>
          Add a processor{` `}
          {targetNode ? (
            <>
              between <strong>{getNodeLabel(sourceNode)}</strong> and <strong>{getNodeLabel(targetNode)}</strong>
            </>
          ) : (
            <>
              after <strong>{getNodeLabel(sourceNode)}</strong>
            </>
          )}
          .
        </DialogDescription>
      </DialogHeader>

      <div className="flex flex-col items-center text-sm bg-accent p-6 rounded-md border">
        <div className="z-50 -mt-6 -mb-1.5 relative flex items-center h-8 w-[1px] border-l border-heavy border-dashed after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:h-3 after:w-3 after:bg-heavy after:rounded-full after:border-accent after:border-[3px]" />

        <div className="opacity-60 h-[36px] max-w-96 grow-0 flex gap-2 items-center bg-background text-muted-foreground shadow pl-2 pr-3 py-2 rounded">
          <Icon
            icon={getNodeIcon(sourceNode)}
            className="shrink-0 text-muted-foreground h-6 w-6 flex items-center justify-center border rounded-sm"
          />
          <div className="truncate">{getNodeLabel(sourceNode)}</div>
        </div>

        <div className="flex items-end justify-center z-50 -my-1.5 relative h-12 w-[1px] border-l border-heavy border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:h-3 before:w-3 before:bg-heavy before:rounded-full before:border-accent before:border-[3px] after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:h-3 after:w-3 after:bg-heavy after:rounded-full after:border-accent after:border-[3px]">
          <Icon
            icon={isValid ? "check_circle" : isError ? "cancel" : "add_box"}
            className={cn("relative z-50 bg-accent p-2.5 text-heavy -mb-1", {
              "text-success filled": isValid,
              "text-destructive filled": isError,
            })}
          />
        </div>

        <div className="rounded-md border border-dashed border-heavy bg-accent p-2.5">
          <div
            className={cn(
              "relative z-50 max-w-96 h-10 flex gap-2 items-center bg-background border-none shadow pl-2 pr-3 py-2 rounded before:contents-[''] before:absolute before:-inset-[1px] before:rounded before:border before:border-heavy"
            )}
          >
            <Icon
              icon={icon || "database"}
              className={cn(
                "relative h-6 w-6 flex shrink-0 items-center justify-center border rounded-sm bg-primary text-primary-foreground filled"
              )}
            />
            <div className="truncate">{name.trim() || <span className="text-muted-foreground">Enter a name</span>}</div>
          </div>
        </div>

        {targetNode && (
          <>
            <div className="z-50 -mb-1.5 -mt-1.5 relative flex items-center h-12 w-[1px] border-l border-heavy border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:h-3.5 before:w-3.5 before:bg-heavy before:rounded-full before:border-accent before:border-[4px] after:content-[''] after:absolute after:left-1/2 after:bottom-0 after:-translate-x-1/2 after:h-3 after:w-3 after:bg-heavy after:rounded-full after:border-accent after:border-[3px]" />

            <div className="opacity-60 h-[36px] max-w-96 grow-0 flex gap-2 items-center bg-background  text-muted-foreground shadow pl-2 pr-3 py-2 rounded">
              <Icon
                icon={getNodeIcon(targetNode)}
                className="shrink-0 text-muted-foreground h-6 w-6 flex items-center justify-center border rounded-sm"
              />
              <div className="truncate">{getNodeLabel(targetNode)}</div>
            </div>

            <div className="z-50 -mt-1.5 -mb-6 relative flex items-center h-8 w-[1px] border-l border-heavy border-dashed before:content-[''] before:absolute before:left-1/2 before:top-0 before:-translate-x-1/2 before:h-3 before:w-3 before:bg-heavy before:rounded-full before:border-accent before:border-[3px]" />
          </>
        )}
      </div>

      <FormGroup>
        <FieldSelect
          name="type"
          label="Type"
          options={[
            FileProcessorCategory.Classifier,
            FileProcessorCategory.Splitter,
            FileProcessorCategory.Extractor,
            FileProcessorCategory.PromptExtractor,
          ].map((type) => ({ label: type, value: type }))}
        />

        <FieldInput name="name" label="Name" placeholder="Enter a name" inputProps={{ autoComplete: "off" }} />
        <FieldInput
          name="id"
          label="File processor ID"
          placeholder="Enter the processor id"
          inputProps={{ autoComplete: "off" }}
        />
      </FormGroup>

      <ButtonGroup className="justify-end">
        <FormReset onClick={closeModal}>Cancel</FormReset>
        <FormSubmit>Add processor</FormSubmit>
      </ButtonGroup>
    </FormGroup>
  );
};

export const AddProcessorForm: FC<AddProcessorFormProps> = (props) => {
  const { closeModal } = useModal();
  const { toast } = useToast();
  const { data, loading } = useFileProcessingPipelineQuery();
  const [createPipelineVersion] = useCreateFilePipelineVersionMutation();

  const pipelineData = data?.fileProcessingPipeline;

  const { nodes } = useMemo(
    () => (pipelineData ? convertPipelineDataToNodesAndEdges(pipelineData) : { nodes: [] }),
    [pipelineData]
  );

  const validationSchema = z.object({
    id: z.string().min(1, { message: "Please enter the processor ID." }),
    type: z.string().min(1, { message: "Please select a type." }),
    name: z
      .string()
      .min(1, { message: "Please enter a name for the processor" })
      .refine((value) => /^[\w- ]+$/.test(value ?? ""), {
        message: "You may only enter alphanumeric characters, underscores, dashes, and spaces.",
      })
      .refine(
        (value) => {
          const existingNode = nodes.find(
            (node) => node.type === FileProcessingNodeType.FileProcessor && node.data.name === value
          );

          return !existingNode;
        },
        {
          message: "There is already a processor with that name. Please enter a unique name.",
        }
      ),
  });

  const handleSubmit = async (values: AddProcessorFormValues) => {
    if (!pipelineData) {
      return;
    }

    const input = getAddProcessorInput(values, pipelineData, props);

    if (!input) {
      return;
    }

    await createPipelineVersion({
      variables: { input },
      refetchQueries: ["FileProcessingPipeline"],
    });

    toast({ title: `${values.name} processor added` });
    closeModal();
  };

  if (!pipelineData && loading) {
    return <Loading />;
  }

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <AddProcessorFormContent {...props} />
    </Form>
  );
};

export interface UseAddProcessorFormModalOptions {
  sourceNodeId?: string;
  targetNodeId?: string;
}

export const useAddProcessorFormModal = ({ sourceNodeId, targetNodeId }: UseAddProcessorFormModalOptions) => {
  const { openModal } = useModal();
  const nodes = useNodes();

  const sourceNode = findNodeById(sourceNodeId, nodes);
  const targetNode = findNodeById(targetNodeId, nodes);

  return {
    openAddProcessorForm: async () => {
      if (!sourceNodeId || !sourceNode) {
        return;
      }

      await openModal(() => <AddProcessorForm sourceNode={sourceNode} targetNode={targetNode} />);
    },
  };
};

function getAddProcessorInput(
  values: AddProcessorFormValues,
  pipelineData: FileProcessingPipelineQuery["fileProcessingPipeline"],
  props: AddProcessorFormProps
): CreatePipelineVersionInput {
  const existingLabel = pipelineData.pipeline.transitions.find(
    (transition) =>
      transition.label === props.sourceNode.data.name && transition.sourceNodeName === props.sourceNode.data.category
  );
  const transitions = existingLabel
    ? pipelineData.pipeline.transitions.map((transition) => {
        if (
          transition.label === props.sourceNode.data.name &&
          transition.sourceNodeName === props.sourceNode.data.category
        ) {
          return {
            ...transition,
            destinationNodes: [
              ...transition.destinationNodes,
              {
                id: values.id,
                category: values.type,
                name: values.name,
              },
            ],
          };
        }

        return transition;
      })
    : [
        ...pipelineData.pipeline.transitions,
        {
          sourceNodeName: props.sourceNode.data.category as string,
          label: props.sourceNode.data.name as string,
          destinationNodes: [
            {
              id: values.id,
              category: values.type,
              name: values.name,
            },
          ],
        },
      ];

  return {
    name: "FileUploadPipeline",
    pipeline: {
      initial: pipelineData.pipeline.initial,
      transitions,
    },
  };
}
