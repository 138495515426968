export const Signature = () => (
  <table className="signature">
    <tr>
      <td colSpan={8} className="line">
        Signature
      </td>
      <td>&nbsp;</td>
      <td colSpan={4} width="192" className="line">
        Date
      </td>
    </tr>
  </table>
);
