import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { useFormContext } from "react-hook-form";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { Checkbox as StyledCheckbox } from "@/components/ui/checkbox";
import { Input as StyledInput } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea as StyledTextarea } from "@/components/ui/textarea";
import { cn } from "src/utils";
import { getFieldId } from "./create-registered-field";
import { useFieldError } from "./use-field-error";

export const Field = ({
  children,
  className,
  label,
  name,
}: React.HTMLAttributes<HTMLDivElement> & { name: string; label?: React.ReactNode }) => {
  const error = useFieldError(name);

  return (
    <div className={cn("flex flex-col gap-1", className)}>
      {label && (
        <Label htmlFor={name} className="text-sm text-muted-foreground">
          {label}
        </Label>
      )}
      {children}
      {error?.message && <p className="text-destructive text-xs mt-1">{error.message.toString()}</p>}
    </div>
  );
};

export const RadioFieldSet = ({
  className,
  children,
  legend,
}: React.HTMLAttributes<HTMLDivElement> & { legend: React.ReactNode }) => (
  <div className={className}>
    <legend className="mb-2">{legend}</legend>
    <fieldset className="flex gap-3">{children}</fieldset>
  </div>
);

export const RadioButton: React.FC<{
  label: React.ReactNode;
  value: string;
  name: string;
  className?: string;
}> = ({ label, value, name, className }) => {
  const { register } = useFormContext();
  const id = `${name}:${value}`;
  return (
    <div className={cn("flex items-center gap-1", className)}>
      <Label htmlFor={id}>{label}</Label>
      <input id={id} type="radio" {...register(name)} value={value} />
    </div>
  );
};

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & { name: string };

export const Input: React.FC<React.InputHTMLAttributes<HTMLInputElement> & { name: string }> = ({
  name,
  required,
  min,
  max,
  maxLength,
  minLength,
  value,
  onChange,
  onBlur,
  disabled,
  pattern,
  className,
  ...props
}) => {
  const { register } = useFormContext();
  const error = useFieldError(name);

  return (
    <StyledInput
      {...register(name, {
        required,
        min,
        max,
        maxLength,
        minLength,
        value,
        onChange,
        onBlur,
        disabled,
        pattern: pattern ? new RegExp(pattern) : undefined,
      })}
      key={name}
      id={getFieldId(name)}
      className={cn(className, "transition-colors", {
        "border-red-500": Boolean(error),
      })}
      {...props}
    />
  );
};

type NumberInputProps = Omit<NumberFormatProps<InputProps>, "customInput"> & { name: string };
export const NumberInput: React.FC<NumberInputProps> = (props) => <NumberFormat {...props} />;

export const PercentInput: React.FC<NumberInputProps> = (props) => (
  <NumberFormat suffix="%" placeholder="%" {...props} />
);

export const TextArea: React.FC<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & { name: string }
> = ({ name, required, maxLength, minLength, value, onChange, onBlur, disabled, className, ref, ...props }) => {
  const { register } = useFormContext();
  const error = useFieldError(name);

  return (
    <StyledTextarea
      {...register(name, {
        required,
        maxLength,
        minLength,
        value,
        onChange,
        onBlur,
        disabled,
      })}
      id={getFieldId(name)}
      className={cn(className, "transition-colors", {
        "border-red-500": Boolean(error),
      })}
      {...props}
    />
  );
};

export const Checkbox: React.FC<React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & { name: string }> = ({
  name,
  required,
  checked,
  value,
  onCheckedChange,
  onChange,
  onBlur,
  disabled,
  className,
  ...props
}) => {
  const { register, setValue, watch } = useFormContext();
  const [checkedState] = watch([name]);
  const error = useFieldError(name);

  return (
    <StyledCheckbox
      {...register(name, {
        required,
        value: checked?.toString(),
        onChange: onCheckedChange,
        onBlur,
        disabled,
      })}
      key={name}
      id={getFieldId(name)}
      onCheckedChange={(checked) => {
        setValue(name, checked.toString());
      }}
      className={cn(className, "transition-colors", {
        "border-red-500": Boolean(error),
      })}
      checked={checkedState === "true"}
      {...props}
    />
  );
};

// components
// – ui (basic unbound shad/tailwind components)
// – bound (easier exporting of tailwind for forms)
// – controlled? (easier exporting of tailwind for controlled forms)
// – print (inputs for pdf generation)
