import { Link, useOutletContext } from "react-router-dom";

import { EmptyState } from "@/components/empty-state";
import { Grid } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredsHeader, InsuredsRow } from "./insureds-row";

export const InsuredsAll = () => {
  const [agencyId] = useOutletContext<string>();

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans } } = {
      groupedMarketingPlans: { groupedMarketingPlans: [] },
    },
    loading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: { agencyId },
    },
    skip: !agencyId,
    fetchPolicy: "cache-first",
  });

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>All Named Insureds</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button variant="outline" size="sm" asChild>
              <Link to="/insured/new">Create Insured</Link>
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      {groupedMarketingPlans[0] ? (
        <Grid className="grid-cols-[1.5rem_2fr_1fr_1fr_1fr_1fr]">
          <InsuredsHeader />
          {groupedMarketingPlans.map((group) => (
            <InsuredsRow key={group.insuredId} group={group} />
          ))}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No insureds found.</h2>} />
      )}
    </>
  );
};
