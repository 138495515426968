import { Card, CardHeader, CardTitle } from "./ui/card";

export const OrEmailDisplay = ({ className }: { className?: string }) => (
  <Card className={className}>
    <CardHeader>
      <CardTitle>Email Your Submission</CardTitle>
      <p>
        You can always send us submissions to{" "}
        <a href="mailto:submissions@quotewell.com" className="text-primary hover:underline">
          submissions@quotewell.com
        </a>
      </p>
    </CardHeader>
  </Card>
);
