import { useMemo, type FC } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonProps } from "@/components/ui/button";

export interface FormSubmitProps extends ButtonProps {}

export const FormSubmit: FC<FormSubmitProps> = ({ children = "Submit", disabled, ...props }) => {
  const { formState, watch } = useFormContext();

  const values = watch();

  const isDisabled = useMemo(() => {
    const hasErrors = Object.keys(formState.errors).length > 0;

    if (formState.isSubmitting) {
      return true;
    }

    if (typeof disabled === "boolean") {
      return disabled;
    }

    if (hasErrors) {
      return true;
    }

    return !formState.isValid;
  }, [disabled, formState, formState.isValid, formState.isSubmitting, formState.errors, values]);

  return (
    <Button {...props} type="submit" disabled={isDisabled}>
      {children}
    </Button>
  );
};
