import { assertNever } from "@cp/toolkit";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { z } from "zod";

import { Autocomplete } from "@/components/ui/autocomplete";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Reform } from "@/forms/reform";
import { reloadOpportunity } from "@/hooks/use-oopportunity";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  InsuredFragment,
  useSearchBusinessClassesLazyQuery,
  useUpdateInsuredMutation,
} from "src/generated/graphql";
import { AiClassCodeGuesser } from "./ai-class-code-guesser";

const FORM_ID = "update-classification-form";

interface Props {
  insured: InsuredFragment;
}

const InsuredFormSchema = z.object({
  naicsCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 6, { message: "NAICS code must be 5 digits" })
    .nullable()
    .optional(),
  cglCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 5, { message: "CGL code must be 5 digits" })
    .nullable()
    .optional(),
});
type InsuredForm = z.infer<typeof InsuredFormSchema>;

export const Classification: React.FC<Props> = ({ insured }) => {
  const [editing, setEditing] = useState(false);
  const location = useLocation();
  const newSubmission = location.pathname.split("/")[1] === "new";

  return (
    <Card>
      {editing ? (
        <>
          <CardHeader>
            <CardTitle>Edit Business Classification</CardTitle>
          </CardHeader>
          <UpdateInsuredForm insured={insured} setEditing={setEditing} />
          <CardFooter className="flex items-center justify-between">
            <Button variant="outline" size="sm" onClick={() => setEditing(false)}>
              Cancel
            </Button>
            <Button type="submit" size="sm" form={FORM_ID}>
              Save
            </Button>
          </CardFooter>
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>Business Classification</CardTitle>
            {newSubmission && (
              <CardDescription>
                We attempted to classify this business based on the information you provided. Classification can be
                changed by clicking <strong>Edit Classification</strong> below.
              </CardDescription>
            )}
          </CardHeader>
          <ClassCodes insured={insured} />
          <CardFooter>
            <Button variant="secondary" size="sm" display="flex" onClick={() => setEditing(true)}>
              Edit Classification
            </Button>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

const UpdateInsuredForm = ({
  insured,
  setEditing,
}: {
  insured: InsuredFragment;
  setEditing: (bool: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [load] = useSearchBusinessClassesLazyQuery();

  const [selectedNaics, setSelectedNaics] = useState<BusinessClassFragment | undefined>(undefined);
  const [selectedCgl, setSelectedCgl] = useState<BusinessClassFragment | undefined>(undefined);

  // Load the insured's existing NAICS/CGL codes on mount
  useEffect(() => {
    if (insured.naicsCodes[0]) {
      void load({
        variables: { input: { term: insured.naicsCodes[0], classSystems: [BusinessClassSystem.Naics] } },
      }).then((res) => setSelectedNaics(res.data?.searchBusinessClasses[0]));
    }
    if (insured.isoCglCodes[0]) {
      void load({
        variables: { input: { term: insured.isoCglCodes[0], classSystems: [BusinessClassSystem.IsoGl] } },
      }).then((res) => setSelectedCgl(res.data?.searchBusinessClasses[0]));
    }
  }, []);

  const formMethods = useForm<z.infer<typeof InsuredFormSchema>>({
    resolver: zodResolver(InsuredFormSchema),
    defaultValues: {
      ...insured,
      // temporarily handles only one NAICS code for simplicity
      naicsCode: insured.naicsCodes[0] ?? undefined,
      cglCode: insured.isoCglCodes[0] ?? undefined,
    },
  });
  const [updateInsured] = useUpdateInsuredMutation();

  const selectNaics = (option: BusinessClassFragment) => {
    setSelectedNaics(option);
    formMethods.setValue("naicsCode", option.code);
  };
  const selectCgl = (option: BusinessClassFragment) => {
    setSelectedCgl(option);
    formMethods.setValue("cglCode", option.code);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>, { naicsCode, cglCode }: InsuredForm) => {
    // necessary to assign this to a variable (naicsCode) satisfy TS type checker in updateClient()
    setEditing(false);
    await updateInsured({
      variables: {
        input: {
          id: insured.id,
          // temporarily handles only one NAICS code for simplicity
          naicsCodes: naicsCode ? [naicsCode] : [],
          isoCglCodes: cglCode ? [cglCode] : [],
        },
      },
      refetchQueries: [...reloadOpportunity.refetchQueries, "SearchAppetiteForOpportunity"],
    });
  };

  const errors = formMethods.formState.errors;

  return (
    <CardContent className="space-y-6">
      {open ? (
        <AiClassCodeGuesser
          onResultClick={(result) =>
            result.system === BusinessClassSystem.Naics ? selectNaics(result) : selectCgl(result)
          }
          onClose={() => setOpen(false)}
        />
      ) : (
        <Button variant="secondary" size="sm" display="flex" onClick={() => setOpen(true)}>
          Search class codes with AI
        </Button>
      )}
      <Reform
        schema={InsuredFormSchema}
        id={FORM_ID}
        onSubmit={handleSubmit}
        methods={formMethods}
        className="space-y-3"
      >
        <div>
          <h6>Naics</h6>
          <Autocomplete
            options={(text) => {
              return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.Naics] } } }).then(
                (res) => [
                  { id: null, description: "None", system: BusinessClassSystem.Naics, code: "" },
                  ...(res.data?.searchBusinessClasses ?? []),
                ]
              );
            }}
            selected={selectedNaics}
            onSelect={(option) => selectNaics(option)}
            toValue={(option) => businessClassToLabel(option)}
            toLabel={(option) => businessClassToLabel(option)}
            placeholder="Search NAICS Classifications"
          />
          <div className="text-destructive">{errors?.naicsCode?.message}</div>
        </div>
        <div>
          <h6>CGL</h6>
          <Autocomplete
            options={(text) => {
              return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then(
                (res) => [
                  { id: null, description: "None", system: BusinessClassSystem.IsoGl, code: "" },
                  ...(res.data?.searchBusinessClasses ?? []),
                ]
              );
            }}
            selected={selectedCgl}
            onSelect={(option) => selectCgl(option)}
            toValue={(option) => businessClassToLabel(option)}
            toLabel={(option) => businessClassToLabel(option)}
            placeholder="Search CGL Classifications"
          />
          <div className="text-destructive">{errors?.naicsCode?.message}</div>
        </div>
      </Reform>
    </CardContent>
  );
};

const ClassCodes: React.FC<Props> = ({ insured: client }) => {
  // Reverse the order in which we display the systems to match how we display them in editing mode.
  const businessClasses = [...client.businessClasses].reverse();
  return (
    <CardContent className="space-y-3">
      {businessClasses.map((bc) => (
        <BusinessClassPill businessClass={bc} key={`${bc.system}:${bc.code}`} />
      ))}
    </CardContent>
  );
};

const BusinessClassPill: React.FC<{ businessClass: BusinessClassFragment }> = ({ businessClass }) => {
  const [, upsertSearchParams] = useUpsertSearchParams();
  return (
    <div>
      <Badge className="cursor-pointer text-2xs" onClick={() => upsertSearchParams({ term: businessClass.code })}>
        {`${systemToFriendly(businessClass.system)}: ${businessClass.code}`}
      </Badge>
      <p className="text-xs italic pt-1">{businessClass.description}</p>
    </div>
  );
};

export const systemToFriendly = (system: BusinessClassSystem) => {
  switch (system) {
    case BusinessClassSystem.IsoGl:
      return "CGL";
    case BusinessClassSystem.Naics:
      return "NAICS";
    default:
      assertNever(system);
      return system;
  }
};

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;
