import { useParams } from "react-router";

import { SidebarLinkClassName } from "@/components/sidebar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { useInsuredWithDuplicateFlagQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { formatDate } from "src/utils/date";

export const DuplicateInsured = () => {
  const { insuredId } = useParams();

  const { data: { insured } = {}, loading } = useInsuredWithDuplicateFlagQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
  });

  if (loading || !insured) {
    return null;
  }

  return (
    <>
      {insured.possibleDuplicates.length > 0 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className={cn(SidebarLinkClassName, "cursor-pointer filled font-semibold text-destructive")}>
              Possible Duplicates
              <Icon icon="warning" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-72">
            {insured.possibleDuplicates.map((dup) => (
              <DropdownMenuItem
                key={dup.id}
                onClick={() => window.open(`/insured/${dup.id}`, "_blank", "noopener,noreferrer")}
              >
                <div className="truncate mr-auto">{dup.name}</div>
                <div className="flex-none text-2xs tabular-nums text-muted-foreground">
                  {formatDate(new Date(dup.createdAt), "MM/dd/yy h:mm a")}
                </div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};
