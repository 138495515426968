import { useParams } from "react-router-dom";

import { HasInternalRole } from "@/components/has-role";
import { InsuredPin } from "@/components/insured-pin";
import { Sidebar, SidebarContent, SidebarHeader, SidebarNavLink, SidebarSection } from "@/components/sidebar";
import { Icon } from "@/components/ui/icon";
import { useTypedRouteLoaderData } from "src/utils/router";
import { insuredLoader } from "../insured-root";
import { CreateMarketingPlan } from "./create-marketing-plan";
import { DuplicateInsured } from "./duplicate-insured";
import { SidebarAgency } from "./sidebar-agency";
import { SidebarMarketingPlans } from "./sidebar-marketing-plans";
import { SidebarPolicies } from "./sidebar-policies";
import { SidebarQuotes } from "./sidebar-quotes";

export const InsuredSidebar = () => {
  const { insuredId } = useParams();
  const insured = useTypedRouteLoaderData<typeof insuredLoader>("insuredRoot");

  if (!insuredId || !insured) {
    return null;
  }

  return (
    <Sidebar side="left">
      <SidebarHeader>
        <SidebarNavLink to=".">{insured.name}</SidebarNavLink>
        <InsuredPin id={insuredId} className="-mr-1.5" />
      </SidebarHeader>
      <SidebarContent>
        <SidebarSection>
          <HasInternalRole>
            <DuplicateInsured />
          </HasInternalRole>
          <SidebarNavLink to="plans">
            Marketing Plans
            <Icon icon="campaign" />
          </SidebarNavLink>
          <SidebarNavLink to="quotes">
            Quotes
            <Icon icon="request_quote" />
          </SidebarNavLink>
          <SidebarNavLink to="policies">
            Policies
            <Icon icon="shield" />
          </SidebarNavLink>
          <SidebarNavLink to="files">
            Files
            <Icon icon="folder_open" />
          </SidebarNavLink>
          <CreateMarketingPlan />
        </SidebarSection>
        <SidebarMarketingPlans />
        <SidebarQuotes />
        <SidebarPolicies />
      </SidebarContent>
      <SidebarAgency />
    </Sidebar>
  );
};
