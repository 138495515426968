import { useState } from "react";
import { z } from "zod";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { TextArea } from "@/forms/default";
import { Reform } from "@/forms/reform";
import { BusinessClassFragment, useAiGuessBusinessClassesLazyQuery } from "src/generated/graphql";
import { systemToFriendly } from "./classification";

const FormSchema = z.object({ description: z.string().min(1, "Description cannot be empty") });

interface Props {
  onResultClick?: (code: BusinessClassFragment) => void;
  onClose?: () => void;
}

export const AiClassCodeGuesser: React.FC<Props> = ({ onResultClick, onClose }) => {
  const [load] = useAiGuessBusinessClassesLazyQuery();
  const [results, setResults] = useState<BusinessClassFragment[]>([]);

  return (
    <Reform
      schema={FormSchema}
      onSubmit={async (e, values) => {
        const codes = await load({ variables: { description: values.description } });
        setResults(codes.data?.aiGuessBusinessClasses ?? []);
      }}
      className="space-y-3"
    >
      <TextArea name="description" placeholder="Business description" />
      <div className="flex gap-2 justify-between">
        {onClose && (
          <Button variant="outline" size="sm" onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button variant="secondary" size="sm" type="submit">
          Search
        </Button>
      </div>
      {results && results.length > 0 && (
        <>
          <h6>Click on a result below to select it</h6>
          {results.map((code) => (
            <Pill code={code} onClick={onResultClick} key={code.code} />
          ))}
        </>
      )}
    </Reform>
  );
};

interface PillProps {
  code: BusinessClassFragment;
  onClick?: (code: BusinessClassFragment) => void;
}

const Pill: React.FC<PillProps> = ({ code, onClick }) => {
  return (
    <Badge
      onClick={() => {
        onClick && onClick(code);
      }}
      variant="outline"
      className="overflow-auto bg-slate-300"
    >
      {`${systemToFriendly(code.system)}: ${code.code} - ${code.description}`}
    </Badge>
  );
};
