import { compareAsc } from "date-fns";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthData, AuthDataSchema, AuthToken } from "../types/types";

export const authDataAtom = atomWithStorage<AuthData | null>("authData", null);

export const setAuthData = (data: AuthData) => {
  localStorage.setItem("authData", JSON.stringify(data));
};

export function getAuthData(require: true): AuthData;
export function getAuthData(require?: boolean): AuthData | undefined;
export function getAuthData(require = false): AuthData | undefined {
  const data = localStorage.getItem("authData");
  if (data) {
    const parsedData = AuthDataSchema.safeParse(JSON.parse(data));
    if (parsedData.success) {
      return parsedData.data;
    }
  }

  if (require) {
    throw new Error("Token contained in authentication data was unexpectedly null.");
  }
  return undefined;
}

export function getAuthToken(require: true): AuthToken;
export function getAuthToken(require?: boolean): AuthToken | undefined;
export function getAuthToken(require?: boolean) {
  return getAuthData(require)?.token;
}

export function getAccessToken(require: true): string;
export function getAccessToken(require?: boolean): string | undefined;
export function getAccessToken() {
  return getAuthToken()?.accessToken;
}

interface Jwt extends JwtPayload {
  exp: number;
  iat: number;
  sub: string;
  impersonatedById: string | null;
}

const getJwtData = (accessToken: string) => {
  const decoded = jwtDecode<Jwt>(accessToken);
  // Convert seconds to MS for date constructor
  const issuedAt = new Date(decoded.iat * 1000);
  const expiresAt = new Date(decoded.exp * 1000);
  const now = new Date();

  return {
    ...decoded,
    issuedAt,
    expiresAt,
    hasExpired: compareAsc(now, expiresAt) >= 0,
  };
};

export const getIsUserAuthenticated = (): boolean => {
  const token = getAuthToken();
  if (!token) {
    return false;
  }
  return !getJwtData(token.accessToken).hasExpired;
};

export const jwtDataAtom = atom((get) => {
  const authData = get(authDataAtom);
  return authData ? getJwtData(authData.token.accessToken) : null;
});

export const authTokenValidatedAtom = atom<boolean>(false);

export const getAuthRequestHeaders = () => {
  const accessToken = getAccessToken(true);

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
