export function removeSpaces(str: string) {
  return str.replaceAll(/\s/g, "");
}

const dollars = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

const dollarsAndCents = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export const formatDollars = (premium: number) => dollars.format(premium);
export const formatDollarsAndCents = (premium: number) => dollarsAndCents.format(premium);
