import { useOutletContext } from "react-router";

import { EmptyState } from "@/components/empty-state";
import { Grid } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Spinner } from "@/components/ui/loading";
import { MarketingPlanState, useGroupedMarketingPlansQuery } from "src/generated/graphql";
import { InsuredsHeader, InsuredsRow } from "./insureds-row";

export const InsuredsActive = () => {
  const [agencyId] = useOutletContext<string>();

  const {
    data: { groupedMarketingPlans: { groupedMarketingPlans } } = {
      groupedMarketingPlans: { groupedMarketingPlans: [] },
    },
    loading,
  } = useGroupedMarketingPlansQuery({
    variables: {
      input: {
        agencyId,
        states: [
          MarketingPlanState.WaitingForCoverages,
          MarketingPlanState.InformationGathering,
          MarketingPlanState.InformationReview,
          MarketingPlanState.Marketing,
        ],
      },
    },
    skip: !agencyId,
    fetchPolicy: "cache-first",
  });

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Active Marketing Plans</h1>
          {loading && <Spinner />}
        </SectionTitle>
      </SectionHeader>
      {groupedMarketingPlans[0] ? (
        <Grid className="grid-cols-[1.5rem_1fr_1fr_1fr_1fr_1fr]">
          <InsuredsHeader />
          {groupedMarketingPlans.map((group) => (
            <InsuredsRow key={group.insuredId} group={group} />
          ))}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No active marketing plans found.</h2>} />
      )}
    </>
  );
};
