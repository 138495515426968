import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { ComponentProps, forwardRef, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "src/utils";

export interface DatePickerProps extends Omit<ComponentProps<typeof Button>, "value" | "onChange"> {
  value?: Date;
  placeholder?: string;
  dateFormat?: string;
  onChange?: (value?: Date) => void;
}

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ value, placeholder = "Pick a date", dateFormat = "MMMM d, yyyy", onChange, onBlur, className, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [date, setDate] = useState<Date | undefined>(value);

    const handleSelect = (date?: Date) => {
      setDate(date);
      onChange?.(date);
    };

    const handleBlur = () => {
      // onBlur?.(event);
    };

    useEffect(() => {
      setDate(value);
    }, [value]);

    return (
      <Popover open={isOpen} onOpenChange={(open) => !props.disabled && setIsOpen(open)}>
        <PopoverTrigger onBlur={handleBlur} asChild>
          <Button
            ref={ref}
            size="lg"
            variant="outline"
            {...props}
            className={cn(
              "min-w-[180px] justify-start text-left font-normal",
              !date && "text-muted-foreground",
              className
            )}
          >
            <CalendarIcon className="h-4 w-4" />
            {date ? format(date, dateFormat) : <span>{placeholder}</span>}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-auto p-0">
          <Calendar mode="single" selected={date} onSelect={handleSelect} initialFocus />
        </PopoverContent>
      </Popover>
    );
  }
);
DatePicker.displayName = "DatePicker";
