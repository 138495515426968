import { STATE_METADATA } from "@/metadata";
import { BankanQuoteColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import { formatDollars } from "src/utils/strings";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";

type Group = BankanQuoteColumnQuery["bankanQuoteColumn"]["groupedQuotes"][number];

export const QuotesCard = ({ group }: { group: Group }) => {
  const { insuredId, insured, quotes } = group;
  return (
    <BankanCard insured={insured}>
      {quotes.map((quote) => {
        const { id, effectiveDate, premium, state, appetiteProduct, lastTransitionAt } = quote;
        const { cardClassName } = STATE_METADATA[state];
        return (
          <div key={id} className="group">
            <BankanLink to={`/insured/${insuredId}/quotes/${id}`} className={cardClassName}>
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="mr-auto">{formatTimezoneDateString(effectiveDate, "MMM d, yyyy")}</div>
                <div className="whitespace-nowrap">{formatDollars(premium ?? 0)}</div>
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
