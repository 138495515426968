import { Background, Controls, Edge, Node, ReactFlow, useEdgesState, useNodesState, Viewport } from "@xyflow/react";
import { stratify, tree } from "d3-hierarchy";
import { FC, useMemo } from "react";
import { useSessionStorage } from "usehooks-ts";

import { Loading } from "@/components/ui/loading";
import {
  FilePipelineVersionFragment,
  FileProcessingPipelineQuery,
  useFileProcessingPipelineQuery,
} from "src/generated/graphql";

import { convertPipelineDataToNodesAndEdges } from "../file-processing-pipeline.helpers";
import { DocumentLabelNode } from "./nodes/document-label-node";
import { FileProcessorNode } from "./nodes/file-processor-node";

import "@xyflow/react/dist/style.css";

const nodeTypes = {
  FileProcessor: FileProcessorNode,
  DocumentLabel: DocumentLabelNode,
};

// NOTE: Disabling edgeTypes for now as we don't need any actions on the edges at the moment.
// const edgeTypes = {
//   default: DefaultEdge,
// };

export interface FileProcessingPipelineGraphProps {
  data: FileProcessingPipelineQuery["fileProcessingPipeline"];
}

const initialViewport: Viewport = {
  x: 64,
  y: 360,
  zoom: 0.62,
};

export const FileProcessingPipelineGraph: FC<FileProcessingPipelineGraphProps> = ({ data }) => {
  const { nodes: initialNodes, edges: initialEdges } = useMemo(() => initializeLayout(data), [data]);

  const [nodes, , handleNodesChange] = useNodesState(initialNodes);
  const [edges, , handleEdgesChange] = useEdgesState(initialEdges);

  const [defaultViewport, setDefaultViewport] = useSessionStorage<Viewport>(
    "fileProcessingPipelineDefaultViewport",
    initialViewport
  );

  const handleViewportChange = (viewport: Viewport) => setDefaultViewport(viewport);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      // NOTE: Disabling edgeTypes for now as we don't need any actions on the edges at the moment.
      // edgeTypes={edgeTypes}
      onNodesChange={handleNodesChange}
      onEdgesChange={handleEdgesChange}
      nodesDraggable={false}
      minZoom={0.25}
      panOnScroll
      panOnScrollSpeed={1}
      onViewportChange={handleViewportChange}
      defaultViewport={defaultViewport}
      onlyRenderVisibleElements
      className="[&_.react-flow\_\_edge-path]:!stroke-heavy [&_.react-flow\_\_edge.selected_.react-flow\_\_edge-path]:!stroke-muted-foreground [&_.react-flow\_\_attribution]:hidden [&_.react-flow\_\_attribution]:bg-accent [&_.react-flow\_\_attribution_a]:bg-accent [&_.react-flow\_\_attribution_a]:text-muted-foreground [&_.react-flow\_\_attribution_a]:border-none"
    >
      <Controls
        showInteractive={false}
        className="bg-background p-1 relative !shadow-none rounded-sm [&_button]:relative [&_button]:z-10 [&_button]:text-muted-foreground [&_button]:bg-background [&_button]:border-none [&_button]:overflow-hidden [&_button]:rounded-sm [&_button:hover]:bg-accent [&_button:hover]:text-accent-foreground [&_button:hover]:transition-colors before:absolute before:inset-0 before:z-1 before:rounded-sm before:shadow-md before:bg-background"
      />
      <Background />
    </ReactFlow>
  );
};

export const FileProcessingPipelineGraphContainer = () => {
  const { data, loading } = useFileProcessingPipelineQuery();

  if (loading || !data) {
    return <Loading label="Loading pipeline data..." />;
  }

  if (!data || !data.fileProcessingPipeline) {
    return <p>The file processor pipeline data is not available.</p>;
  }

  return <FileProcessingPipelineGraph data={data.fileProcessingPipeline} />;
};

function initializeLayout(pipeline: FilePipelineVersionFragment) {
  const { nodes, edges } = convertPipelineDataToNodesAndEdges(pipeline);

  return {
    nodes: prepareNodes(nodes, edges),
    edges: prepareEdges(edges),
  };
}

function prepareNodes(nodes: Node[], edges: Edge[]): Node[] {
  // TODO: Update this so we get the width and height from the DOM element?
  // See example here: https://reactflow.dev/learn/layouting/layouting#d3-hierarchy
  const nodeWidth = 288;
  const nodeHeight = 72;
  const g = tree();

  const hierarchy = stratify()
    .id((node: any) => node.id)
    .parentId((node: any) => edges.find((edge) => edge.target === node.id)?.source);

  const root = hierarchy(nodes);
  const layout = g.nodeSize([nodeHeight * 2, nodeWidth * 2])(root);

  return layout.descendants().map((node) => {
    const nodeData: Node = {
      ...(node.data as Node),
      data: {
        ...(node.data as Node)?.data,
        hasChildren: !!node.children?.length,
        isRoot: !edges.some((edge) => edge.target === node.id),
      },
    };

    return {
      ...nodeData,
      deletable: false,
      draggable: false,
      position: {
        y: node.x || 0,
        x: node.y || 0,
      },
    };
  });
}

function prepareEdges(edges: Edge[]): Edge[] {
  return edges.map((edge) => ({
    ...edge,
    deletable: false,
    draggable: false,
    selectable: false,
  }));
}

// TODO: Extract our layouting function to a custom hook. Keeping this example here for now as a reminder.
// SEE: https://reactflow.dev/api-reference/hooks/use-nodes-initialized
// export default function useLayout() {
//   const { getNodes } = useReactFlow();
//   const nodesInitialized = useNodesInitialized(options);
//   const [layoutedNodes, setLayoutedNodes] = useState(getNodes());

//   useEffect(() => {
//     if (nodesInitialized) {
//       setLayoutedNodes(yourLayoutingFunction(getNodes()));
//     }
//   }, [nodesInitialized]);

//   return layoutedNodes;
// }
