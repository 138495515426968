import type { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { Input } from "@/components/ui/input";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export type FieldInputProps = FieldBaseProps & {
  placeholder?: string;
  type?: ComponentProps<typeof Input>["type"];
  onChange?: ComponentProps<typeof Input>["onChange"];
  onBlur?: ComponentProps<typeof Input>["onBlur"];
  inputProps?: ComponentProps<typeof Input>;
};

export const FieldInput: FC<FieldInputProps> = ({ placeholder, type, onChange, onBlur, inputProps, ...props }) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  const handleChange: FieldInputProps["onChange"] = (event) => {
    field.onChange(event);
    onChange?.(event);
  };

  const handleBlur: FieldInputProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Input
        {...field}
        {...inputProps}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cn({ "border-destructive": !!error }, inputProps?.className)}
      />
    </FieldBase>
  );
};
