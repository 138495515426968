import { useAtom } from "jotai";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { authDataAtom, authTokenValidatedAtom } from "../atoms/atoms";
import { useAuthContext } from "../context/AuthContext";
import { LoginEmailPasswordDocument, LoginEmailPasswordMutation } from "../generated/operations";
import { userAccountQueryKeys } from "../utils/queryKeys";
import { GraphQLAuthError, GraphQLErrorResponse } from "./authError";

interface LoginArgs {
  email: string;
  password: string;
  rememberMe: boolean;
}

type TData = LoginEmailPasswordMutation["loginEmailPassword"];

export default function useLoginEmailPassword(
  options: UseMutationOptions<TData, GraphQLErrorResponse<GraphQLAuthError>, LoginArgs> = {}
) {
  const queryClient = useQueryClient();
  const [, setAuthData] = useAtom(authDataAtom);
  const [, setTokenValidated] = useAtom(authTokenValidatedAtom);
  const { authClientId, fetcher } = useAuthContext();

  return useMutation<TData, GraphQLErrorResponse<GraphQLAuthError>, LoginArgs>(
    (args: LoginArgs) => {
      return fetcher(LoginEmailPasswordDocument, {
        authClientId,
        ...args,
      }).then((data) => data.loginEmailPassword);
    },
    {
      onSuccess: (data, vars, context) => {
        options.onSuccess?.(data, vars, context);
        queryClient.setQueryData(userAccountQueryKeys.detail("myAccount"), data.user);
        setAuthData({
          token: data.token,
          shouldRemember: vars.rememberMe,
        });
        setTokenValidated(true);
      },
    }
  );
}
