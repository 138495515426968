/**
 * Wrapper around `toLocaleString` to format a number as a currency, but with sane defaults.
 */
export function formatMoney(amount: number | string | null | undefined, options?: Intl.NumberFormatOptions): string {
  if (amount == null) {
    return "";
  }

  const asNumber = typeof amount === "string" ? Number.parseFloat(amount) : amount;
  if (Number.isNaN(asNumber)) {
    return amount.toString();
  }

  return asNumber.toLocaleString("en-US", options || { style: "currency", currency: "USD", minimumFractionDigits: 0 });
}
