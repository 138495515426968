import { startCase } from "lodash";
import { Control } from "react-hook-form";

import { FormField, FormItem } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface Props<T> {
  control: Control<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  enumType: T;
  placeholder?: string;
  onChange?: (value: keyof T) => void;
}

export const SelectEnum = <T extends Record<string, string>>({
  control,
  fieldName,
  enumType,
  placeholder,
  onChange,
}: Props<T>) => {
  return (
    <FormField
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="flex-auto">
          <Select
            key={field.value}
            onValueChange={(value) => {
              field.onChange(value as unknown as typeof enumType);
              onChange && onChange(value as keyof T);
            }}
            value={field.value}
          >
            <SelectTrigger>
              <SelectValue placeholder={placeholder ?? "Select an Option"} />
            </SelectTrigger>
            <SelectContent>
              {Object.values(enumType).map((type) => (
                <SelectItem key={type} value={type}>
                  {startCase(type)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  );
};
