import { useMatches } from "react-router";

import { cn } from "src/utils";
import { Breadcrumbs, Handle } from "./breadcrumbs";
import { SidebarTrigger } from "./sidebar";
import { Bar, BarMenu } from "./ui/bar";

export const ScrollPane = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  const matches = useMatches();
  const displayBar = matches.some((match) =>
    Boolean(match.handle && ((match.handle as Handle).crumb || (match.handle as Handle).menu))
  );

  return (
    <main
      className={cn(
        "@container/opp bg-accent flex flex-auto flex-col max-h-dvh max-w-full overflow-auto relative shadow-md z-1",
        "landscape:max-h-landscape landscape:max-w-landscape landscape:rounded-md",
        "portrait:max-h-portrait",
        className
      )}
      style={{ contain: "layout" }}
      {...rest}
    >
      {displayBar && (
        <Bar>
          <SidebarTrigger />
          <Breadcrumbs />
          <BarMenu />
        </Bar>
      )}
      {children}
    </main>
  );
};

export const ScrollPaneColumns = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col gap-6 @4xl/opp:grid @4xl/opp:items-start p-6", className)} {...rest}>
    {children}
  </div>
);
