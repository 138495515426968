import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const waitMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export interface VerboseError extends Error {
  message: string;
  graphQLErrors?: ReadonlyArray<{
    message: string;
    extensions: {
      code?: string;
      response?: {
        statusCode: number;
        message: string[];
        error: string;
      };
    };
  }>;
}

export function parseError(error: VerboseError | string) {
  if (typeof error === "string") {
    return error;
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const { extensions, message } = error.graphQLErrors[0];
    if (!extensions.response) {
      return message;
    }
    const messages = Array.isArray(extensions.response.message)
      ? extensions.response.message
      : [extensions.response.message];
    return `${extensions.response.error || "Error"}: ${messages.join(", ")}`;
  }
  return error.message;
}
