import { PopoverTrigger } from "@radix-ui/react-popover";
import { isValid } from "date-fns";
import { useEffect, useState } from "react";
import { cn } from "src/utils";

import { Matcher } from "react-day-picker";
import { formatTimezoneDateString } from "src/utils/date";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Icon } from "./icon";
import { Input } from "./input";
import { Popover, PopoverContent } from "./popover";

interface Props {
  selected: string | undefined;
  onSelect: (e: Date | undefined) => void;
  placeholder?: string;
  dateFormat?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  disabledMatch?: Matcher | Matcher[] | undefined;
}

export const DatePicker: React.FC<Props> = ({
  selected,
  onSelect,
  placeholder = "Pick a date",
  dateFormat = "MMMM d, yyyy",
  className = "",
  inputClassName = "",
  disabled,
  disabledMatch,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(selected ? formatTimezoneDateString(selected, dateFormat) : "");
  const [invalidInput, setInvalidInput] = useState(false);

  useEffect(() => {
    if (selected) {
      setValue(formatTimezoneDateString(selected, dateFormat));
      isValid(new Date(selected)) && setInvalidInput(false);
    }
  }, [selected]);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;

    if (isValid(new Date(input))) {
      // Default to the current year if not provided
      const hasYear = /\d{4}/.test(input);
      if (input && !hasYear) {
        input = `${input}, ${new Date().getFullYear()}`;
      }

      const date = new Date(input);
      onSelect(date);
    } else {
      onSelect(undefined);
      setInvalidInput(true);
    }
  };

  return (
    <Popover open={open} onOpenChange={(e) => !disabled && setOpen(e)}>
      <div className="flex flex-row justify-between relative w-full">
        <Input
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          onFocus={() => setOpen(true)}
          placeholder={placeholder}
          value={value}
          className={cn(
            "border-0 focus-visible:ring-0 focus-visible:ring-offset-0",
            invalidInput && "border-red-500 text-red-500",
            inputClassName
          )}
        />
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className={cn(
              "rounded bg-transparent hover:bg-transparent border-0 px-4 font-normal focus:ring-0 focus:ring-offset-0",
              // Below turns off black borders on focus
              // "focus-visible:ring-0 focus-visible:ring-offset-0",
              className,
              disabled && "opacity-50",
              !selected && "text-muted-foreground",
              selected && "text-foreground"
            )}
          >
            <Icon icon="event" />
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-auto p-0" onOpenAutoFocus={(e) => console.log(e)}>
        <Calendar
          mode="single"
          defaultMonth={selected ? new Date(selected) : undefined}
          selected={selected ? new Date(selected) : undefined}
          onSelect={(e) => {
            onSelect(e);
            setOpen(false);
          }}
          disabled={disabledMatch}
        />
      </PopoverContent>
    </Popover>
  );
};
