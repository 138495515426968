import { type ComponentProps, type FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export interface FieldSelectOption {
  type?: "label" | "item";
  label: string;
  value?: string;
}

export type FieldSelectProps = FieldBaseProps & {
  options: FieldSelectOption[];
  placeholder?: string;
  onChange?: ComponentProps<typeof Select>["onValueChange"];
  onBlur?: ComponentProps<typeof SelectTrigger>["onBlur"];
  inputProps?: ComponentProps<typeof SelectTrigger>;
};

export const FieldSelect: FC<FieldSelectProps> = ({ options, placeholder, onChange, onBlur, inputProps, ...props }) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  const handleChange: FieldSelectProps["onChange"] = (value) => {
    field.onChange(value);
    onChange?.(value);
  };

  const handleBlur: FieldSelectProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <Select onValueChange={handleChange} name={field.name} value={field.value}>
        <SelectTrigger
          {...inputProps}
          onBlur={handleBlur}
          className={cn("min-w-[180px]", { "border-destructive": !!error })}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>

        {options.length > 0 && (
          <SelectContent>
            <SelectGroup>
              {options.map((option) => {
                if (option.type === "label") {
                  return <SelectLabel key={option.label}>{option.label}</SelectLabel>;
                }

                if (!option.value) {
                  return null;
                }

                return (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        )}
      </Select>
    </FieldBase>
  );
};
