import { usePasswordReset } from "@cp/auth";
import { FormProvider, useForm } from "react-hook-form";
import { LoaderFunctionArgs } from "react-router";
import { Link } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { Input } from "@/forms/default";
import { isGraphQLErrorResponse, StatusCodes } from "src/utils/errors";
import { createUrlParamsValidator, useTypedLoaderData } from "src/utils/router";
import { AuthCard } from "./auth-card";

interface LoginFormData {
  password: string;
  confirmPassword: string;
}

const getUrlParams = createUrlParamsValidator(
  z.object({
    changePasswordId: z.string(),
  })
);

export const activateAccountLoader = async ({ params }: LoaderFunctionArgs) => {
  const { changePasswordId } = getUrlParams(params);
  return {
    changePasswordId,
  };
};

export function ActivateAccount() {
  const { changePasswordId } = useTypedLoaderData<typeof activateAccountLoader>();
  const methods = useForm<LoginFormData>();

  const { mutate, isSuccess, error, isLoading } = usePasswordReset();

  const { handleSubmit, setError, clearErrors } = methods;

  const onSubmit = ({ password, confirmPassword }: LoginFormData) => {
    if (password !== confirmPassword) {
      setError("password", { message: "Passwords don't match" });
    } else if (password.length < 8) {
      setError("password", { message: "Password must be at least 8 characters" });
    } else {
      clearErrors();
      mutate({ newPassword: password, changePasswordId });
    }
  };

  const requestLinkExpired =
    isGraphQLErrorResponse(error) &&
    error?.response.errors &&
    error.response.errors.some((error) => error.extensions.authError?.statusCode === StatusCodes.NotFound);

  // we may need to request a new link if the existing changePasswordId has expired
  if (requestLinkExpired) {
    // prompt to a password
    return (
      <AuthCard header="Create New Password">
        <CardContent>
          The link is no longer valid. Your account may already be set up. If you forgot your password, you can
          <Link to="/forgot-password">reset it.</Link>
        </CardContent>
      </AuthCard>
    );
  }

  // successfully reset password
  if (isSuccess) {
    return (
      <AuthCard header="Create New Password">
        <CardContent>
          Password successfully reset! Please{" "}
          <Link to="/login" className="font-semibold text-primary">
            Sign In
          </Link>
          .
        </CardContent>
      </AuthCard>
    );
  }

  // prompt to reset password
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthCard header="Create New Password" subHeader="Please enter your new password below." error={error}>
          <CardContent className="space-y-2">
            <Input type="password" name="password" placeholder="Password" disabled={isLoading} />
            <Input type="password" name="confirmPassword" placeholder="Confirm Password" disabled={isLoading} />
          </CardContent>
          <CardFooter>
            <Button type="submit" disabled={isLoading}>
              Set Password
            </Button>
          </CardFooter>
        </AuthCard>
      </form>
    </FormProvider>
  );
}
