import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { coerceBoolean } from "@cp/toolkit";
import { useFormContext } from "react-hook-form";
import { z } from "zod";
import {
  QuoteFragment,
  QuoteStateDeliveredActions,
  useTransitionQuoteMutation,
  useUpdateQuoteMetadataMutation,
} from "../../../../generated/graphql";
import { useModal } from "../../../components/modal-provider";
import { Button } from "../../../components/ui/button";
import { FormField, FormItem } from "../../../components/ui/form";
import { TextArea } from "../../../forms/default";
import { QUOTE_STATE_METADATA } from "../../../metadata";

export const BindRequestReceivedButton = ({ quote }: { quote: QuoteFragment }) => {
  const { openConfirmation, openForm } = useModal();
  const [transition] = useTransitionQuoteMutation();
  const [createMetadata] = useUpdateQuoteMetadataMutation();

  const handleTransition = async (action: QuoteStateDeliveredActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${QUOTE_STATE_METADATA[quote?.state].actionDisplayNames?.[action] ?? action}`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          quoteId: quote.id,
          action,
        },
      },
    });
  };

  const createQuoteMetadata = async () => {
    const fd = await openForm(
      z.object({
        triaAccepted: z.string().min(1, { message: "TRIA selection is required" }),
        outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
        carrierContactInformation: z.string().optional(),
      }),
      <QuoteMetadataForm />,
      { defaultValues: { triaAccepted: "", outstandingSubjectivities: "", carrierContactInformation: "" } }
    );

    if (!fd) {
      return;
    }

    await createMetadata({
      variables: {
        input: {
          id: quote.id,
          triaAccepted: coerceBoolean(fd.triaAccepted),
          outstandingSubjectivities: fd.outstandingSubjectivities,
          carrierContactInformation: fd.carrierContactInformation,
        },
      },
      onCompleted: () => {
        void handleTransition(QuoteStateDeliveredActions.ReceiveBindRequest);
      },
    });
  };

  return (
    <Button onClick={createQuoteMetadata} variant="outline" size="sm" display="flex">
      Bind Request Received
    </Button>
  );
};

function QuoteMetadataForm() {
  const { setValue, watch, trigger, formState, control } = useFormContext();
  const errors = formState.errors;
  return (
    <div className="space-y-6">
      <h3>Bind Request</h3>
      <div className="space-y-2">
        <FormField
          control={control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-auto">
              <Select
                key={field.value}
                required={true}
                name="triaAccepted"
                value={watch("triaAccepted")}
                onValueChange={(v) => {
                  setValue("triaAccepted", v);
                  void trigger("triaAccepted");
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Is TRIA accepted or rejected" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="yes">Accepted</SelectItem>
                  <SelectItem value="no">Rejected</SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <div className="text-xs text-destructive">{errors?.triaAccepted?.message?.toString()}</div>
      </div>
      <div className="space-y-2">
        <TextArea
          required={true}
          name="outstandingSubjectivities"
          placeholder="Outstanding subjectivities (enter N/A if none apply)"
          className="px-4"
        />
        <div className="text-xs text-destructive">{errors?.outstandingSubjectivities?.message?.toString()}</div>
      </div>
      <TextArea name="carrierContactInformation" placeholder="Carrier contact (if brokerage)" className="px-4" />
      <Button
        display="flex"
        onClick={async () => {
          await trigger();
        }}
      >
        Save
      </Button>
    </div>
  );
}
