import { partition } from "lodash";

import { Loading } from "@/components/ui/loading";
import { BankanQuoteColumn, useBankanQuoteColumnQuery } from "src/generated/graphql";
import { BankanColumn } from "./bankan-column";
import { QuotesCard } from "./cards/quotes-card";

export const QuoteColumn = ({
  column,
  agencyId,
  brokerId,
  pinnedInsureds,
}: {
  column: BankanQuoteColumn;
  agencyId?: string | null;
  brokerId?: string | null;
  pinnedInsureds: string[];
}) => {
  const { data: { bankanQuoteColumn: { groupedQuotes } } = { bankanQuoteColumn: { groupedQuotes: [] } }, loading } =
    useBankanQuoteColumnQuery({
      variables: {
        input: {
          agencyId,
          brokerId,
          column,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  const [pinned, unpinned] = partition(groupedQuotes, ({ insured }) => pinnedInsureds.includes(insured.id));

  return (
    <BankanColumn id={column} count={groupedQuotes.length}>
      {loading ? (
        <Loading />
      ) : (
        [...pinned, ...unpinned].map((group) => <QuotesCard key={group.insuredId} group={group} />)
      )}
    </BankanColumn>
  );
};
