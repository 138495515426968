import { US_STATES } from "@cp/toolkit";
import { Control } from "react-hook-form";

import { FormField, FormItem } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface Props {
  control: Control<any, any>; // TODO: change 'any' type to the actual form type
  fieldName: string;
  placeholder?: string;
  required?: boolean;
}

export const SelectUSState = ({ control, fieldName, placeholder, required = false }: Props) => (
  <FormField
    control={control}
    name={fieldName}
    render={({ field }) => (
      <FormItem className="flex-auto">
        <Select key={field.value} onValueChange={field.onChange} value={field.value} required={required}>
          <SelectTrigger>
            <SelectValue placeholder={placeholder ?? "State *"} />
          </SelectTrigger>
          <SelectContent>
            {US_STATES.map((state) => (
              <SelectItem key={state} value={state}>
                {state}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormItem>
    )}
  />
);
