import { ClientDataField } from "@/client-data/create-client-data-field";

interface Props {
  colSpan?: number;
  label?: string;
  name: string;
  index?: number;
  rowSpan?: number;
}

export const PdfInput: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <ClientDataField.Input type={type} name={name} {...props} />
  </PdfLabel>
);

export const PdfDate: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <ClientDataField.Date type={type} name={name} {...props} />
  </PdfLabel>
);

export const PdfNumber: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <ClientDataField.Number type={type} name={name} {...props} />
  </PdfLabel>
);

export const PdfMoney: React.FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  type = "text",
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <ClientDataField.Money type={type} name={name} {...props} />
  </PdfLabel>
);

export const PdfTextarea: React.FC<Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  colSpan = 1,
  label,
  name,
  rowSpan = 1,
  ...props
}) => (
  <PdfLabel colSpan={colSpan} label={label} rowSpan={rowSpan} name={name}>
    <ClientDataField.TextArea name={name} rows={3} {...props} />
  </PdfLabel>
);

const PdfLabel = ({
  children,
  colSpan,
  label,
  rowSpan,
  name,
}: {
  children: React.ReactNode;
  colSpan: number;
  label?: string;
  rowSpan: number;
  name: string;
}) => (
  <td colSpan={colSpan} rowSpan={rowSpan} style={{ padding: 0 }}>
    <ClientDataField.Label style={{ padding: label ? "5pt 8pt" : "0pt 8pt" }} name={name}>
      {label ?? null}
      {children}
    </ClientDataField.Label>
  </td>
);

export const PdfYesNo = ({ colSpan = 1, label, name, rowSpan = 1 }: Props) => (
  <td colSpan={colSpan} rowSpan={rowSpan}>
    <h4 style={{ fontWeight: 400, marginBottom: "8pt" }}>{label}</h4>
    <ClientDataField.RadioBoolean name={name} />
  </td>
);
