import { useImpersonateUser } from "@cp/auth";
import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useDocumentTitle } from "usehooks-ts";
import { UserAccountFragment, useUserAccountsQuery } from "../../../generated/graphql";
import { SectionHeader, SectionTitle } from "../../components/section";
import { Autocomplete } from "../../components/ui/autocomplete";
import { Button } from "../../components/ui/button";

export function ImpersonateUser() {
  useDocumentTitle("Admin: Impersonate User");
  const { data: { userAccounts } = {} } = useUserAccountsQuery({ variables: { input: {} } });
  const { mutate: impersonateUser } = useImpersonateUser();
  const [selectedUser, setSelectedUser] = useState<UserAccountFragment | null>(null);

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Impersonate User</h1>
        </SectionTitle>
      </SectionHeader>
      <div>
        <Autocomplete
          options={userAccounts || []}
          onSelect={(user) => setSelectedUser(user)}
          toLabel={(user) => `${user.firstName} ${user.lastName} (${user.email})`}
          toValue={(user) => `${user.firstName} ${user.lastName} (${user.email})`}
          placeholder="Search Users"
        />
      </div>
      <span>
        Name: {selectedUser?.firstName} {selectedUser?.lastName}{" "}
      </span>
      <span>Email: {selectedUser?.email}</span>
      <span>Agency: {selectedUser?.agency?.name}</span>
      <Button
        onClick={() => {
          impersonateUser(
            { userIdToImpersonate: selectedUser?.id as UUID },
            { onSuccess: () => window.location.replace(window.location.origin) }
          );
        }}
        disabled={!selectedUser}
      >
        Impersonate
      </Button>
    </>
  );
}
