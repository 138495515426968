import { Edge, Node } from "@xyflow/react";

import type { BadgeProps } from "@/components/ui/badge";
import { IconString } from "@/components/ui/icon";
import { FilePipelineVersionFragment, FileProcessingNodeType, FileProcessorCategory } from "src/generated/graphql";

import { processorCategoryToIconMap } from "./file-processing-pipeline.constants";

export function getDocumentLabelStatus(totalProcessedFiles: number): { label: string; variant: BadgeProps["variant"] } {
  if (totalProcessedFiles >= 100) {
    return {
      variant: "green",
      label: "over ready",
    };
  }

  if (totalProcessedFiles >= 50) {
    return {
      variant: "lime",
      label: "ready",
    };
  }

  if (totalProcessedFiles >= 20) {
    return {
      variant: "amber",
      label: "semi ready",
    };
  }

  return {
    variant: "orange",
    label: "not ready",
  };
}

export function getNodeIcon(node: Node): IconString {
  if (node.type === FileProcessingNodeType.DocumentLabel) {
    return "folder";
  }

  return processorCategoryToIconMap[node.data.category as FileProcessorCategory];
}

export function getNodeLabel(node?: Node): string {
  return (node?.data as any).name;
}

export function findNodeById(id?: string, nodes?: Node[]): Node | undefined {
  if (!id || !nodes) {
    return undefined;
  }

  return nodes.find((node) => node.id === id || node.data.id === id);
}

export function findParentNodeByChildId(id: string, nodes: Node[], edges: Edge[]): Node | undefined {
  const childNode = findNodeById(id, nodes);

  if (!childNode) {
    return undefined;
  }

  return findNodeById(edges.find((edge) => edge.target === childNode?.id)?.source as string, nodes);
}

export function findChildNodesByParentId(id: string, nodes: Node[], edges: Edge[]): Node[] {
  return edges.filter((edge) => edge.source === id).map((edge) => findNodeById(edge.target, nodes)!);
}

export function convertPipelineDataToNodesAndEdges({ pipeline }: FilePipelineVersionFragment): {
  nodes: Node[];
  edges: Edge[];
} {
  const nodes = [
    {
      id: pipeline.initial.name,
      type: FileProcessingNodeType.FileProcessor,
      data: pipeline.initial,
      position: { x: 0, y: 0 },
    },
    ...pipeline.transitions.flatMap((edge) => [
      ...edge.destinationNodes.map((destinationNode) => ({
        id: destinationNode.name,
        type: FileProcessingNodeType.FileProcessor,
        position: { x: 0, y: 0 },
        data: destinationNode,
      })),
      {
        id: `${edge.sourceNodeName}::${edge.label}`,
        type: FileProcessingNodeType.DocumentLabel,
        position: { x: 0, y: 0 },
        data: {
          id: `${edge.sourceNodeName}::${edge.label}`,
          category: edge.sourceNodeName,
          name: edge.label,
        },
      },
    ]),
  ];

  const edges = pipeline.transitions.flatMap((edge) => [
    {
      id: `edge-${edge.sourceNodeName}-${edge.label}`,
      source: edge.sourceNodeName,
      target: `${edge.sourceNodeName}::${edge.label}`,
    },
    ...edge.destinationNodes.map((destinationNode) => ({
      id: `edge-${edge.label}:${destinationNode.name}`,
      source: `${edge.sourceNodeName}::${edge.label}`,
      target: destinationNode.name,
    })),
  ]);

  return { nodes, edges };
}
