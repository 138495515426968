import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { POLICY_STATE_METADATA } from "@/metadata";
import { PolicyFragment, PolicyStateIssuedActions, useTransitionPolicyMutation } from "src/generated/graphql";

export const Issued = ({ policy }: { policy: PolicyFragment }) => {
  const { openConfirmation } = useModal();

  const [transition] = useTransitionPolicyMutation();

  const handleTransition = async (action: PolicyStateIssuedActions) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${POLICY_STATE_METADATA[policy?.state].actionDisplayNames?.[action] ?? action}`,
      description: `${policy.appetiteProduct.carrierName} - ${policy.appetiteProduct.carrierProductName}`,
    });
    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          policyId: policy.id,
          action,
        },
      },
    });
  };

  return (
    <Button
      onClick={() => handleTransition(PolicyStateIssuedActions.Cancel)}
      variant="outline"
      size="sm"
      display="flex"
    >
      Cancel Policy
    </Button>
  );
};
