import { Button } from "@/components/ui/button";
import { Input, TextArea } from "@/forms/default";

export const UpdateAppetiteForm = () => (
  <div className="flex flex-col gap-4">
    <h3>Current</h3>
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col">
        <h4>State</h4>
        <Input name="state" />
      </div>
      <div className="flex flex-col">
        <h4>Naics</h4>
        <Input name="naicsCode" />
      </div>
      <div className="flex flex-col col-span-2">
        <h4>Reason</h4>
        <TextArea name="approvedReason" className="w-full" />
      </div>
    </div>
    <div className="flex justify-between">
      <Button type="reset" variant="destructive">
        Cancel
      </Button>
      <Button type="submit">Submit</Button>
    </div>
  </div>
);
