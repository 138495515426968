import { abbreviateLob } from "@cp/toolkit";

import { cn } from "src/utils";

interface MinimalProduct {
  coreLines: string[];
}

interface Props {
  requestedLines: string[];
  product: MinimalProduct;
  className?: string;
}

export const ProductSupportedLines: React.FC<Props> = ({ product, requestedLines, className }) => {
  return (
    <ul className={cn("flex gap-2", className)}>
      {requestedLines.map((line) => (
        <li key={line}>
          <LobAbrreviation
            line={line}
            className={cn("font-semibold text-xs no-underline", {
              "text-muted-foreground/50": !product.coreLines.includes(line),
            })}
          />
        </li>
      ))}
    </ul>
  );
};

const LobAbrreviation: React.FC<{ line: string; className?: string }> = ({ line, className }) => {
  const abbreviation = abbreviateLob(line);

  return (
    <abbr title={line} className={className}>
      {abbreviation}
    </abbr>
  );
};
