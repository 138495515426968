import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateStringDistance } from "src/utils/date";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText } from "./bankan-cards";

type Group = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const ExpiringPoliciesCard = ({ group }: { group: Group }) => {
  const { insuredId, insured, policies } = group;
  return (
    <BankanCard insured={insured}>
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, expirationDate } = policy;
        return (
          <div key={id} className="group relative">
            <BankanLink to={`/insured/${insuredId}/policies/${id}`} className="border-destructive">
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div>#{policyNumber}</div>
                <div>Expiring {formatTimezoneDateStringDistance(expirationDate, true)}</div>
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
