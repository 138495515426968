import { orderBy } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { ClassCodeFragment, useDeleteVerticalMutation, useVerticalsQuery } from "src/generated/graphql";
import { cn, parseError } from "src/utils";

type CglDisplayType = "short" | "long";

export function Verticals() {
  useDocumentTitle("Admin: Verticals");
  const [cglDisplayType, setCglDisplayType] = useState<CglDisplayType>("short");
  const { data: { verticals = [] } = {}, loading } = useVerticalsQuery();

  const displayCgl = (cgl: ClassCodeFragment) => {
    switch (cglDisplayType) {
      case "long":
        return `${cgl.classCode} ${cgl.description}`;
      default:
        return cgl.classCode;
    }
  };

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Verticals</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button type="button" variant="outline" size="sm" asChild>
              <Link to="create">Create Vertical</Link>
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      {verticals[0] ? (
        <Grid className="grid-cols-[0.75rem_1fr_2fr_1.5rem_1.5rem_1.5rem]">
          <GridRowHeader>
            <GridCell />
            <GridCell>Vertical</GridCell>
            <GridCell>CGL(s)</GridCell>
            <GridCell />
            <GridCell />
            <GridCell />
          </GridRowHeader>
          {orderBy(verticals, [(vertical) => !vertical.enabled, (vertical) => vertical.name.toLowerCase()]).map(
            (vertical) => (
              <GridRow key={vertical.id}>
                <Icon icon="circle" className={cn(vertical.enabled ? "filled text-success" : "opacity-25")} />
                <GridCell>{vertical.name}</GridCell>
                <Pills
                  items={vertical.isoCglCodes.map((cgl) => displayCgl(cgl))}
                  onClick={() => {
                    cglDisplayType === "short" ? setCglDisplayType("long") : setCglDisplayType("short");
                  }}
                />
                <Button variant="ghost" size="icon-sm" asChild>
                  <Link to={vertical.id}>
                    <Icon icon="visibility" />
                  </Link>
                </Button>
                <Button variant="ghost" size="icon-sm" asChild>
                  <Link to={`${vertical.id}/edit`}>
                    <Icon icon="edit" />
                  </Link>
                </Button>
                <DeleteVertical id={vertical.id} />
              </GridRow>
            )
          )}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No verticals found.</h2>} />
      )}
    </>
  );
}

export const DeleteVertical: React.FC<{ id: string }> = ({ id }) => {
  const [deleteVertical] = useDeleteVerticalMutation({
    onError: (error) =>
      toast({ title: "Error deleting vertical", description: parseError(error), variant: "destructive" }),
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon-sm">
          <Icon icon="delete" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
        </DialogHeader>
        <Button
          variant="destructive"
          onClick={() => deleteVertical({ variables: { id }, refetchQueries: ["Verticals"] })}
        >
          Delete
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const Pill: React.FC<{
  text: string;
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}> = ({ text, onClick, onDelete }) => {
  return (
    <Badge className="bg-muted tabular-nums text-foreground" onClick={onClick}>
      <div className="flex justify-between gap-1">
        {text}
        {onDelete && (
          <div
            className="flex"
            onClick={(e) => {
              e.preventDefault();
              onDelete(text);
            }}
          >
            <Icon icon="close" />
          </div>
        )}
      </div>
    </Badge>
  );
};

export const Pills: React.FC<{
  items: string[];
  onClick?: React.MouseEventHandler;
  onDelete?: (item: string) => void;
}> = ({ items, onClick, onDelete }) => {
  return (
    <div className="flex flex-row flex-wrap gap-1 w-full h-auto">
      {items.map((item) => (
        <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
      ))}
    </div>
  );
};
