import { STATE_METADATA } from "@/metadata";
import { BankanPolicyColumnQuery } from "src/generated/graphql";
import { BankanCard, BankanLink, BankanLinkFooter, BankanLinkText, DaysSince } from "./bankan-cards";

type Group = BankanPolicyColumnQuery["bankanPolicyColumn"]["groupedPolicies"][number];

export const PoliciesCard = ({ group }: { group: Group }) => {
  const { insuredId, insured, policies } = group;
  return (
    <BankanCard insured={insured}>
      {policies.map((policy) => {
        const { id, appetiteProduct, policyNumber, state, lastTransitionAt } = policy;
        const { cardClassName } = STATE_METADATA[state];
        return (
          <div key={id} className="group">
            <BankanLink to={`/insured/${insuredId}/policies/${id}`} className={cardClassName}>
              <BankanLinkText>
                <strong>{appetiteProduct.carrierName}: </strong>
                {appetiteProduct.carrierProductName}
              </BankanLinkText>
              <BankanLinkFooter>
                <div>#{policyNumber}</div>
                <DaysSince date={lastTransitionAt} />
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
