import { GridCell } from "@/components/grid";
import {
  AppetiteGuidesForOpportunityQuery,
  QuoteSubmissionFragment,
  SubmissionFragment,
  SubmissionState,
} from "src/generated/graphql";
import { MarketBlocked } from "./market-blocked";
import { Pending } from "./pending";
import { Quoted } from "./quoted";
import { Referred } from "./referred";
import { RuledOut } from "./ruled-out";
import { Submitted } from "./submitted";

export const SubmissionActions = ({
  submission,
  details,
  refetch,
}: {
  submission: SubmissionFragment;
  details?: string | null;
  refetch: () => void;
}) => {
  const { state } = submission;

  const renderSubmissionState = () => {
    switch (state) {
      case SubmissionState.Pending:
        return <Pending submission={submission} />;
      case SubmissionState.Submitted:
        return <Submitted submission={submission} refetch={refetch} />;
      case SubmissionState.RuledOut:
        return <RuledOut submission={submission} />;
      case SubmissionState.Referred:
        return <Referred submission={submission} />;
      case SubmissionState.MarketBlocked:
        return <MarketBlocked submission={submission} />;
      case SubmissionState.Quoted:
        return <Quoted submission={submission} refetch={refetch} />;
      default:
        return null;
    }
  };

  return (
    <GridCell className="col-span-3 flex gap-2 items-center justify-end">
      {details && <div className="text-muted-foreground text-2xs truncate">{details}</div>}
      {renderSubmissionState()}
    </GridCell>
  );
};

export type MarketingEffortSubmission = SubmissionFragment &
  QuoteSubmissionFragment & {
    appetiteGuide?: AppetiteGuidesForOpportunityQuery["appetiteGuidesForOpportunity"][number];
  };
