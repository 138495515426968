import { AuthContextProvider, TypedGraphQLFetcher } from "@cp/auth";
import * as FullStory from "@fullstory/browser";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import request from "graphql-request";
import ReactDOM from "react-dom/client";
import "regenerator-runtime/runtime";
import { App } from "./brokerage/app";
import { getAuthClientId } from "./brokerage/hooks/authClientId";
import { BACKEND_API_PATH } from "./utils/constants";

if (process.env.NODE_ENV !== "development") {
  // TODO: Make this an env var
  FullStory.init({ orgId: "o-19XRGJ-na1" });
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.APP_ENV,
      integrations: [new CaptureConsole({ levels: ["error"] })],
      beforeSend: (event) => {
        // Don't send "Unauthorized" errors if a user is already logged in.
        // In this case, this is an expected failure that kicks off re-connecting our GraphQL subscriptions.
        // Reporting these to Sentry just adds noise.
        if (event.user?.id && event.message?.match(/Unauthorized/)) {
          return null;
        }
        return event;
      },
    });
  }
}

const fetcher: TypedGraphQLFetcher = (document, variables, headers) =>
  request<any>({
    url: BACKEND_API_PATH,
    requestHeaders: headers,
    variables,
    document,
  });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById("root")!;
ReactDOM.createRoot(root).render(
  <AuthContextProvider authClientId={getAuthClientId()} fetcher={fetcher}>
    <App />
  </AuthContextProvider>
);
