import { Button, ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";

export const ContinueButton = ({
  submitting,
  disabled = false,
  ...rest
}: ButtonProps & {
  submitting: boolean;
}) => (
  <Button type="submit" disabled={disabled || submitting} {...rest}>
    Continue
    {submitting ? <Spinner /> : <Icon icon="arrow_forward" />}
  </Button>
);
