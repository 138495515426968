import { useEdges, useNodes } from "@xyflow/react";
import { useMemo } from "react";
import { findChildNodesByParentId } from "./file-processing-pipeline.helpers";

export const useHasDownStreamProcessors = (processorId?: string) => {
  const nodes = useNodes();
  const edges = useEdges();

  return useMemo(() => {
    if (!processorId) {
      return false;
    }

    return findChildNodesByParentId(processorId, nodes, edges).some((child) => child.data.hasChildren);
  }, [processorId, nodes, edges]);
};
