import { AgencyAffiliations } from "@/components/agency-affiliations";
import { HasInternalRole } from "@/components/has-role";
import { SidebarFooter } from "@/components/sidebar";
import { useTypedRouteLoaderData } from "src/utils/router";
import { insuredLoader } from "../insured-root";

export const SidebarAgency = () => {
  const insured = useTypedRouteLoaderData<typeof insuredLoader>("insuredRoot");

  if (!insured?.agent || !insured.agency) {
    return null;
  }

  return (
    <SidebarFooter>
      <div className="space-y-1">
        <div className="font-semibold text-xs truncate">
          {insured.agent.firstName} {insured.agent.lastName}
        </div>
        <div className="text-2xs truncate">{insured.agency.name}</div>
      </div>
      <HasInternalRole>
        <AgencyAffiliations id={insured.agency.id} />
      </HasInternalRole>
    </SidebarFooter>
  );
};
