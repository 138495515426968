import { useAtom } from "jotai";

import { deletedFilesAtom, fileLabelsAtom } from "@/atoms";
import { MoreMenu } from "@/components/more-menu";
import { DropdownMenuCheckboxItem, DropdownMenuLabel } from "@/components/ui/dropdown-menu";

export const FilesOptions = () => {
  const [deletedFiles, setDeletedFiles] = useAtom(deletedFilesAtom);
  const [fileLabels, setFileLabels] = useAtom(fileLabelsAtom);

  return (
    <MoreMenu>
      <DropdownMenuLabel>Options</DropdownMenuLabel>
      <DropdownMenuCheckboxItem checked={fileLabels} onCheckedChange={(val) => setFileLabels(val)}>
        Display Labels
      </DropdownMenuCheckboxItem>
      <DropdownMenuCheckboxItem checked={deletedFiles} onCheckedChange={(val) => setDeletedFiles(val)}>
        Display Deleted Files
      </DropdownMenuCheckboxItem>
    </MoreMenu>
  );
};
