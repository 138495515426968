import { FC, useMemo } from "react";
import { useParams } from "react-router";

import {
  SidePanel,
  SidePanelAction,
  SidePanelDetails,
  SidePanelHeader,
  SidePanelLoading,
  SidePanelSection,
} from "@/components/side-panel";
import { Badge } from "@/components/ui/badge";
import { FileProcessorQuery, useFileProcessorQuery } from "src/generated/graphql";

import { DetailsFilesList, FileUpload } from "../components/details-files-list";
import { DetailsLinksList } from "../components/details-links-list";
import { useAddProcessorFormModal } from "../components/forms/add-processor-form";
import { getDocumentLabelStatus } from "../file-processing-pipeline.helpers";

export interface FileProcessorDetailsProps {
  data: FileProcessorQuery["fileProcessor"];
}

export const DocumentLabelDetails: FC<FileProcessorDetailsProps> = ({ data }) => {
  const status = useMemo(() => getDocumentLabelStatus(data?.totalProcessedFiles || 0), [data?.totalProcessedFiles]);

  return (
    <>
      <SidePanelSection>
        <SidePanelDetails label="Total Files in Bucket">
          <div className="flex justify-between items-center gap-6">
            <span>{data?.totalProcessedFiles || 0}</span>
            <Badge variant={status.variant}>{status.label}</Badge>
          </div>
        </SidePanelDetails>
      </SidePanelSection>

      <SidePanelSection>
        <SidePanelDetails label="Links">
          <DetailsLinksList
            links={[
              {
                label: "Google Cloud Storage Bucket",
                url: `https://console.cloud.google.com/storage/browser/qw-document-ai-training/training/${data?.name}`,
              },
            ]}
          />
        </SidePanelDetails>
      </SidePanelSection>

      {!!data?.files.length && (
        <SidePanelSection>
          <SidePanelDetails label="Recent Files">
            <DetailsFilesList files={data?.files as unknown as FileUpload[]} />
          </SidePanelDetails>
        </SidePanelSection>
      )}
    </>
  );
};

export const DocumentLabelDetailsSidePanel = () => {
  const { documentLabelId } = useParams();
  const { data, loading } = useFileProcessorQuery({ variables: { id: documentLabelId! } });
  const { openAddProcessorForm } = useAddProcessorFormModal({ sourceNodeId: data?.fileProcessor?.id });

  const isLoading = loading && !data?.fileProcessor;
  const isEmpty = !loading && !data?.fileProcessor;

  const actions: SidePanelAction[] = [
    {
      id: "addProcessor",
      icon: "add_box",
      label: "Add Processor",
      onClick: openAddProcessorForm,
    },
  ];

  return (
    <SidePanel>
      <SidePanelHeader
        icon="folder"
        label="Document Label"
        title={data?.fileProcessor?.name || ""}
        actions={actions}
        onCloseNavigatePath="/file-processing-pipeline"
        isLoading={isLoading}
        isEmpty={isEmpty}
      />

      {isLoading && <SidePanelLoading />}

      {!isLoading && isEmpty && <SidePanelSection>No matching document label found.</SidePanelSection>}

      {!isLoading && !isEmpty && <DocumentLabelDetails data={data?.fileProcessor} />}
    </SidePanel>
  );
};
