import { removeSpaces } from "./strings";

const BROKERAGE_EMAIL = "brokerage@quotewell.com";

export const getRealGmailRelayEmails = ({
  to,
  from,
  initiatorEmail,
  recipientEmail,
}: {
  to: string;
  from: string;
  initiatorEmail: string;
  recipientEmail: string;
}) => {
  const sanitizedTo = removeSpaces(to);
  const sanitizedFrom = removeSpaces(from);

  if (recipientEmail === sanitizedTo) {
    return {
      to: recipientEmail,
      from: initiatorEmail,
    };
  } else if (initiatorEmail === sanitizedTo) {
    return {
      to: initiatorEmail,
      from: recipientEmail,
    };
  } else if (recipientEmail === sanitizedFrom) {
    return {
      to: initiatorEmail,
      from: recipientEmail,
    };
  } else if (initiatorEmail === sanitizedFrom) {
    return {
      to: recipientEmail,
      from: initiatorEmail,
    };
  } else if (sanitizedTo === BROKERAGE_EMAIL) {
    return {
      to: initiatorEmail,
      from: recipientEmail,
    };
  } else if (sanitizedFrom === BROKERAGE_EMAIL) {
    return {
      to: recipientEmail,
      from: initiatorEmail,
    };
  } else {
    return {
      to: "unknown",
      from: "unknown",
    };
  }
};
