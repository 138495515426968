import { z } from "zod";

import { HasInternalRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Input } from "@/forms/default";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import {
  SubmissionFragment,
  SubmissionStateReferredActions,
  useTransitionSubmissionMutation,
} from "src/generated/graphql";

export const Referred = ({ submission }: { submission: SubmissionFragment }) => {
  const [transition] = useTransitionSubmissionMutation();
  const { openConfirmation, openForm } = useModal();

  const handleTransition = async (action: SubmissionStateReferredActions, includeDetails: boolean = false) => {
    const isConfirmed = await (includeDetails
      ? openForm(
          z.object({ details: z.string() }),
          <>
            <h3>
              Confirm{" "}
              {
                SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[
                  SubmissionStateReferredActions.RuleOut
                ]
              }
            </h3>
            <p>
              {submission.appetiteProduct.carrierName} - {submission.appetiteProduct.carrierProductName}
            </p>
            <div className="my-2">
              <Input name="details" placeholder="Reason" />
            </div>
            <div className=" mt-2">
              <Button size="sm">Rule Out</Button>
            </div>
          </>
        )
      : openConfirmation({
          title: `Confirm ${SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[action] ?? action}`,
          description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
        }));
    if (!isConfirmed) {
      return;
    }
    await transition({
      variables: {
        input: {
          submissionId: submission.id,
          action,
          details: typeof isConfirmed === "object" ? isConfirmed.details : undefined,
        },
      },
    });
  };

  return (
    <HasInternalRole>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => handleTransition(SubmissionStateReferredActions.BlockMarket)}
            variant="ghost"
            size="icon"
          >
            <Icon icon="front_hand" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Blocked</TooltipContent>
      </Tooltip>
      <Button onClick={() => handleTransition(SubmissionStateReferredActions.Decline)} variant="outline" size="sm">
        Declined
      </Button>
      <Button onClick={() => handleTransition(SubmissionStateReferredActions.Quote)} variant="outline" size="sm">
        Quoted
      </Button>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => handleTransition(SubmissionStateReferredActions.RuleOut, true)}
            variant="ghost"
            size="icon"
          >
            <Icon icon="close" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Rule Out</TooltipContent>
      </Tooltip>
    </HasInternalRole>
  );
};
