import { capitalize } from "lodash";
import React from "react";
import { useController } from "react-hook-form";

import { cn } from "src/utils";
import { useFieldError } from "./use-field-error";

export const getFieldId = (name: string) => `form-field-${name}`;

interface FormInputProps {
  name?: string;
  className?: string;
}
type ComponentType<TProps> =
  | string
  | React.ForwardRefExoticComponent<TProps>
  | (((props: TProps, context?: any) => React.ReactElement) & { displayName?: string });

export function createControlledField<TProps extends FormInputProps>(BaseComponent: ComponentType<TProps>) {
  const Component = BaseComponent as any;
  const FormInput: React.FC<TProps & { name: string }> = ({ className, name, ...props }) => {
    const error = useFieldError(name);
    const hasError = Boolean(error);

    const controller = useController({ name, ...props });

    return (
      <Component
        className={cn(
          {
            "border-red-500": hasError,
          },
          className
        )}
        id={getFieldId(name)}
        {...props}
        {...controller.field}
      />
    );
  };
  FormInput.displayName =
    typeof BaseComponent === "string" ? `Registered${capitalize(BaseComponent)}` : BaseComponent.displayName;
  return FormInput;
}
