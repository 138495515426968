import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/components/ui/use-toast";
import { PolicyFragment, useUpdatePolicyMutation } from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadPolicyDocument } from "src/utils/file";

export const AwaitingFromCarrier = ({ policy }: { policy: PolicyFragment }) => {
  const { id: policyId, policyFileId } = policy;
  const [submitting, setSubmitting] = useState(false);
  const [updatePolicy] = useUpdatePolicyMutation();
  const { insuredId } = useParams<"insuredId">();

  const updateFileForProcessing = async (fileId: string) => {
    await updatePolicy({
      variables: {
        input: {
          policyId,
          policyFileId: fileId,
        },
      },
      refetchQueries: ["Policy"],
      onCompleted() {
        toast({ title: "Policy File Saved" });
      },
      onError() {
        toast({ title: "An error occurred." });
      },
    });
  };

  return (
    <>
      {!policyFileId && (
        <Button asChild variant="outline" size="sm" display="flex">
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  if (!policy) {
                    toast({ title: "Error" });
                    setSubmitting(false);
                    return;
                  }
                  if (!insuredId) {
                    toast({ title: "Error" });
                    setSubmitting(false);
                    return;
                  }

                  const { fileId } = await uploadPolicyDocument(file, policyId);
                  await updateFileForProcessing(fileId);
                }
                setSubmitting(false);
              }}
            />
            {submitting ? <Spinner /> : "Upload Policy File"}
          </label>
        </Button>
      )}
    </>
  );
};
