import { useEffect } from "react";
import { useParams } from "react-router";

import { Activity } from "@/components/activity/activity";
import { AgentMessage } from "@/components/agent-message";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Card } from "@/components/ui/card";
import { Requirements } from "@/requirements/requirements";
import { Submissions } from "@/submissions/submissions";
import {
  MarketingPlanState,
  OnOpportunityStateChangedDocument,
  OnOpportunityStateChangedSubscription,
  OnOpportunityStateChangedSubscriptionVariables,
  OpportunityKitchenSinkQueryResult,
  useOpportunityKitchenSinkQuery,
  UserAccountFragment,
} from "src/generated/graphql";
import { MarketingPlanActions } from "./actions/marketing-plan-actions";
import { Coverage } from "./coverage";
import { FollowUps } from "./follow-ups/follow-ups";
import RulesForm from "./rules-form";
import { Team } from "./team";

export type MarketingPlan = NonNullable<NonNullable<OpportunityKitchenSinkQueryResult["data"]>["opportunity"]>;

export const MarketingPlanDetails = () => {
  const { opportunityId } = useParams<"opportunityId">();
  const {
    data: { opportunity } = {},
    subscribeToMore,
    refetch,
  } = useOpportunityKitchenSinkQuery({
    variables: {
      id: opportunityId ?? opportunityId!,
    },
    skip: !opportunityId,
  });

  useEffect(() => {
    if (!opportunityId) {
      return;
    }
    return subscribeToMore<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>({
      document: OnOpportunityStateChangedDocument,
      variables: { id: opportunityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        return { opportunity: subscriptionData.data.opportunityStateChanged };
      },
      onError: (error) => {
        console.error("Subscription error:", error);
      },
    });
  }, [opportunityId]);

  if (!opportunity) {
    return null;
  }

  if (opportunity.state === MarketingPlanState.WaitingForCoverages) {
    return <Coverage skipNavigation />;
  }

  return (
    <div className="p-6 space-y-6">
      <Card>
        <SectionHeader>
          <SectionTitle>
            <h2>Markets</h2>
            <HasInternalRole>
              <MarketingPlanActions marketingPlan={opportunity} />
            </HasInternalRole>
          </SectionTitle>
        </SectionHeader>
        <Submissions opportunity={opportunity} refetch={refetch} />
        <HasInternalRole>
          <RulesForm />
        </HasInternalRole>
      </Card>
      <AgentMessage state={opportunity.state} />
      <Requirements />
      <ScrollPaneColumns className="flex-col-reverse grid-cols-[2fr_1fr] p-0">
        <Activity opportunityId={opportunity.id} />
        <div className="space-y-6">
          <HasInternalRole>
            <Notes opportunityId={opportunity.id} />
            <FollowUps />
          </HasInternalRole>
          <Team broker={opportunity.broker as UserAccountFragment} agent={opportunity.insured.agent} />
        </div>
      </ScrollPaneColumns>
    </div>
  );
};
