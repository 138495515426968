import { isNumberString } from "class-validator";
import { isValid, parse, parseISO } from "date-fns";

/**
 * This function alone makes me sad, but is necessary to handle the fact
 * that Javascript seems to have the oddest date handling capabilities which
 * rival that of Java pre-8.
 *
 * What we want to store is just the ZonedDate, instead, we have to store
 * the ZonedDateTime, which necessarily always includes the time and timestamp.
 * In this particular function, we have some dates that were stored with
 * the `Date.toString()` format, which is peculiar, and we have new dates
 * being stored with `formatISO`. The DatePicker requires dates as input, so we
 * want to supply it with dates which correctly represent the intention of the
 * agent, which is a specific year/month/date, rather than some UTC point in time, which
 * is different depending on which timezone you're in.
 */

const DATE_PARSERS = [
  (date: string) => {
    const timestampIndex = date.indexOf("T");
    if (timestampIndex > 0) {
      date = date.slice(0, Math.max(0, timestampIndex));
    }
    return parseISO(date);
  },
  (date: string) => {
    // if the date was something which was saved with a timezone, ie:
    // what you get with Date.toString(), then we want to push it back to
    // UTC so that the date is actually correct
    // legacy dates come in the form: Thu Oct 13 2022 00:00:00 GMT-0600 (Mountain Daylight Time)
    // we *only* want the first part, so strip off the time and timestamp here because if we
    // tried to pass this into Javascript's new Date(), it would read the timezone information
    const timeRegex = /\d{2}:\d{2}:\d{2}/;
    const timeIndex = date.search(timeRegex);

    let timelessDate = date;
    if (timeIndex > 0) {
      timelessDate = timelessDate.slice(0, Math.max(0, timeIndex));
    }
    return parse(timelessDate, "E LLL d yyyy", new Date());
  },
  (date: string) => {
    return parseISO(date);
  },
];

/**
 * Reformats the given input date (if it's a String),
 * to a new date object, offset to the users time zone.
 * The expectation with this method is that the input date
 * is timezone-less, which causes date-fn's date handling to construct
 * a UTC date.
 *
 * This method is only safe to call from the frontend where
 * the timezone of the user can be correctly offset. This should
 * never be called from the server where the server timezone will
 * differ from the client's timezone.
 */
export function reformatDate(date: Date | string): Date {
  // string comes in off the wire, convert to
  // a ISO date on read, dropping the timestamp and timezone
  if (typeof date === "string") {
    const convertedDate = reformatDateToUTC(date);
    if (convertedDate !== null) {
      const userTimezoneOffset = convertedDate.getTimezoneOffset() * 60_000;
      return new Date(convertedDate.getTime() + userTimezoneOffset);
    }
  }

  return date as Date;
}

/**
 * Given an input string, in either the legacy `Date.toString()` format, or
 * ISO Date only format, convert it into a date zoned to the UTC timezone,
 * set at 00:00:00.
 */
export function reformatDateToUTC(date: string): Date | null {
  for (const parser of DATE_PARSERS) {
    const convertedDate = parser(date);
    if (isValid(convertedDate)) {
      return convertedDate;
    }
  }

  return null;
}

/**
 *
 * Given a string returns whether the string represents a valid date or not.
 */
export function isValidDate(date: string) {
  return !!date && !isNumberString(date) && !isNaN(Date.parse(date));
}

/**
 * Given a date, adds the given number of days, months, and years to the date.
 */
export function incrementDate(input: {
  date: Date | string;
  addDays?: number;
  addMonths?: number;
  addYears?: number;
}): Date {
  const { date, addDays = 0, addMonths = 0, addYears = 0 } = input;

  const originalDate = new Date(date);
  let result = new Date(originalDate.setFullYear(originalDate.getFullYear() + addYears));
  result = new Date(result.setMonth(result.getMonth() + addMonths));
  result = new Date(originalDate.setDate(originalDate.getDate() + addDays));
  return result;
}
