import { useState } from "react";

import { StateIndicator, States } from "@/metadata";
import { cn } from "src/utils";

export const BankanColumn = ({
  children,
  id,
  count,
}: {
  children: React.ReactNode;
  id: States;
  count: number | undefined;
}) => {
  const [expanded, setExpanded] = useState(true);

  if (count === 0) {
    return null;
  }

  return (
    <div
      className={cn(
        "bg-gradient-to-b from-background to-transparent max-md:border-b md:border-r flex flex-col md:flex-none md:w-88 z-0",
        !expanded && "md:w-12"
      )}
    >
      {/* desktop button */}
      <button
        className={cn(
          buttonClassName,
          "max-md:hidden",
          !expanded && "md:flex-col md:justify-start md:py-5 md:h-80 md:w-12"
        )}
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <StateIndicator state={id} displayLabel={expanded} />
        <span className={badgeClassName}>{count}</span>
      </button>
      {/* mobile button */}
      <button className={cn(buttonClassName, "md:hidden")} onClick={() => setExpanded((expanded) => !expanded)}>
        <StateIndicator state={id} />
        <span className={badgeClassName}>{count}</span>
      </button>
      {expanded && <div className="flex-auto overflow-auto p-6 pt-0 space-y-6">{children}</div>}
    </div>
  );
};

const buttonClassName = "flex flex-none gap-4 h-14 items-center justify-between px-6 text-xs";
const badgeClassName = "bg-foreground/5 flex flex-none gap items-center px-[0.5em] py-[0.125em] rounded text-2xs";
