import { zodResolver } from "@hookform/resolvers/zod";
import { sortBy } from "lodash";
import { FormEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Autocomplete } from "@/components/ui/autocomplete";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { Field } from "@/forms/borderless";
import { AgentActionType, useAgentActionTitlesQuery, useBrokerCreateAgentActionMutation } from "src/generated/graphql";
import { parseError } from "src/utils";

const formId = "createAgentActionForm";
interface Props {
  opportunityId: string;
  onAdded?: () => void;
}

const FormSchema = z.object({
  submissionId: z.string().optional(),
  title: z.string().min(2),
  reason: z.string().min(2),
  externalReason: z.string().optional(),
});

export const AddRequirements = ({ opportunityId, onAdded }: Props) => {
  const [createAgentAction] = useBrokerCreateAgentActionMutation();
  const [agentActionType, setAgentActionType] = useState<AgentActionType>(AgentActionType.UploadFile);

  const formMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const isValid = await formMethods.trigger();
    e.preventDefault();

    if (isValid) {
      const data = formMethods.getValues();

      void createAgentAction({
        variables: {
          input: {
            opportunityId,
            submissionId: data.submissionId,
            title: data.title,
            reason: data.reason,
            externalReason: data.externalReason,
          },
        },
        onError: (e) => toast({ title: `Error`, description: parseError(e), variant: "destructive" }),
        onCompleted: () => {
          toast({ title: "Requirement Added" });
          onAdded?.();
          formMethods.reset();
        },
      });
    }
  };

  return (
    <SheetContent className="overflow-y-auto">
      <SheetHeader className="p-4">
        <SheetTitle>Add Requirement</SheetTitle>
      </SheetHeader>
      <Label>Type</Label>
      <Select onValueChange={(value) => setAgentActionType(value as AgentActionType)} value={agentActionType}>
        <SelectTrigger>
          <SelectValue placeholder={"Select an Option"} />
        </SelectTrigger>
        <SelectContent>
          {Object.keys(AgentActionTypeDisplay).map((type) => (
            <SelectItem key={type} value={type}>
              {AgentActionTypeDisplay[type as keyof typeof AgentActionTypeDisplay]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormProvider {...formMethods}>
        <form id={formId} onSubmit={(e) => onSubmit(e)}>
          {agentActionType === AgentActionType.Custom ? (
            <CustomRequirementForm formMethods={formMethods} />
          ) : (
            <SelectRequirement agentActionType={agentActionType} formMethods={formMethods} />
          )}
          <div className="mt-4">
            <Label>Reason (Required)</Label>
            <Textarea
              {...formMethods.register("reason")}
              placeholder="Please describe the reason we need this item. (Example: 'X market requires this item if revenue is above $Y'). This will help our models learn when to request this automatically in the future!"
              rows={5}
            />
          </div>
          <div className="mt-4">
            <Label>External Reason (Optional)</Label>
            <Textarea
              {...formMethods.register("externalReason")}
              placeholder="This is agent facing and will be used to explain why we are requesting this information!"
              rows={5}
            />
          </div>
        </form>
      </FormProvider>
      <Button form={formId} className="mt-4 w-full">
        Add
      </Button>
    </SheetContent>
  );
};

export const SelectRequirement = ({
  agentActionType,
  formMethods,
}: {
  agentActionType: AgentActionType;
  formMethods: ReturnType<typeof useForm<z.infer<typeof FormSchema>>>;
}) => {
  const { data: { agentActionTitles = [] } = {} } = useAgentActionTitlesQuery({
    variables: {
      actionType: agentActionType,
    },
  });

  return (
    <div className="mt-4">
      <Label>Select the item you want to add</Label>
      <Field className="p-0">
        <Autocomplete
          placeholder="Search"
          options={sortBy(agentActionTitles)}
          selected={formMethods.watch("title")}
          onSelect={(title) => formMethods.setValue("title", title)}
          toValue={(a) => a}
          toLabel={(a) => a}
        />
      </Field>
    </div>
  );
};

export const CustomRequirementForm = ({
  formMethods,
}: {
  formMethods: ReturnType<typeof useForm<z.infer<typeof FormSchema>>>;
}) => {
  return (
    <div className="mt-4">
      <Label>Title (how it will display to the agent)</Label>
      <Input required={true} {...formMethods.register("title")} />
    </div>
  );
};

export const AgentActionTypeDisplay: Partial<Record<AgentActionType, string>> = {
  [AgentActionType.UploadFile]: "Document",
  [AgentActionType.ClientData]: "Specific Info",
  [AgentActionType.Custom]: "Custom",
};
