import { AGENT_MESSAGE_METADATA } from "@/metadata";
import { MarketingPlanState, PolicyState, QuoteState, Roles } from "src/generated/graphql";
import { cn } from "src/utils";
import { HasRole } from "./has-role";
import { Card } from "./ui/card";
import { Icon } from "./ui/icon";

export const AgentMessage = ({
  state,
  wrapperClassName,
}: {
  wrapperClassName?: string;
  state: MarketingPlanState | QuoteState | PolicyState;
}) => {
  const { className, cardClassName, icon, agentHeader, agentDescription } = AGENT_MESSAGE_METADATA[state];
  return (
    <HasRole roles={[Roles.Agent, Roles.Admin]}>
      {agentHeader && (
        <div className={cn("@container", wrapperClassName)}>
          <Card className={cn("border p-6 @lg:pl-0 rounded-lg space-y-3", cardClassName, className)}>
            <div className="gap-y-4 flex flex-col @lg:flex-row @lg:items-center">
              <div className="flex flex-none items-center @lg:justify-center text-4xl w-24">
                <Icon icon={icon} />
              </div>
              <h3 className="leading-tight">{agentHeader}</h3>
            </div>
            {agentDescription && <p className="@lg:pl-24 text-muted-foreground">{agentDescription}</p>}
          </Card>
        </div>
      )}
    </HasRole>
  );
};
