import { z } from "zod";

export const AuthTokenSchema = z.object({
  /** JWT access token */
  accessToken: z.string(),
  /** JWT refresh token */
  refreshToken: z.string().optional(),
});

export type AuthToken = z.infer<typeof AuthTokenSchema>;

export const AuthDataSchema = z.object({
  token: AuthTokenSchema,
  shouldRemember: z.boolean(),
});

export type AuthData = z.infer<typeof AuthDataSchema>;
